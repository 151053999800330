<template>
  <div>
    <Loader v-if="isLoading" />
    <template v-else>
      <div class="ui form">
        <h1 style="margin-bottom: 10px">{{ dataFromServer.communityTitle }} Photos &amp; Videos</h1>

        <div class="filters-section">
          <details class="filters-section__details" open>
            <summary class="filters-section__summary">Filters</summary>
            <div class="filters-section__content">
              <div class="filters-section__column">
                <div class="filters-section__section" style="margin-bottom: 40px">
                  <div class="filter-sort-group">
                    <div class="label-wrapper">
                      <label>Group by</label>
                    </div>
                    <div class="ui basic tiny buttons">
                      <button class="ui button" :class="{ primary: groupingType === 'adventure' }" @click="setGroupingType('adventure')">Adventure</button>
                      <button class="ui button" :class="{ primary: groupingType === 'creator' }" @click="setGroupingType('creator')">Creator</button>
                      <button class="ui button" :class="{ primary: groupingType === 'location' }" @click="setGroupingType('location')">Location</button>
                      <button class="ui button" :class="{ primary: groupingType === 'community-upload' }" @click="setGroupingType('community-upload')">Community Upload</button>
                    </div>
                  </div>
                </div>
                <div class="filters-section__section">
                  <div class="filter-sort-group" style="margin-bottom: 0">
                    <div class="label-wrapper">
                      <label>Search by</label>
                      <span>{{ groupTypeText }} name</span>
                    </div>
                    <div class="ui small fluid input">
                      <input type="text" v-model="filterText" @input="searchChanged" @keyup.enter="applyFilters()" placeholder="" />
                    </div>
                    <div style="font-weight: bold; margin-top: 1rem">
                      <span v-if="filterPhotoCount > 0">
                        {{ filterPhotoCount.toLocaleString() }} asset{{ filterPhotoCount === 1 ? "" : "s" }} from {{ groupResultCount.toLocaleString() }} {{ groupTypeTextPluralized }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="filters-section__column filters-section__column--row">
                <div class="filters-section__section" style="margin-right: 40px">
                  <div class="filter-sort-group">
                    <div class="label-wrapper">
                      <label>Content Type</label>
                    </div>
                    <div class="ui form">
                      <div class="grouped fields">
                        <div class="field">
                          <div class="ui checkbox">
                            <input type="checkbox" name="photos" v-model="filterShowPhotos" @change="searchChanged" />
                            <label>Photos</label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui checkbox">
                            <input type="checkbox" name="videos" v-model="filterShowVideos" @change="searchChanged" />
                            <label>Videos</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="filters-section__section">
                  <div class="filter-sort-group">
                    <div class="label-wrapper">
                      <label>Source</label>
                    </div>
                    <div class="ui form">
                      <div class="grouped fields">
                        <div class="field">
                          <div class="ui checkbox">
                            <input v-model="filterShowAdventurePhotos" @change="searchChanged" type="checkbox" />
                            <label>Adventures</label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui checkbox">
                            <input v-model="filterShowCommunityUploads" @change="searchChanged" type="checkbox" />
                            <label
                              >Community Uploads
                              <!--<router-link :to="{name: 'UserUploadCampaigns', params: { pageId: customerId }}"><i class="arrow right grey small icon"></i></router-link>-->
                            </label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui checkbox">
                            <input v-model="filterShowMyUploads" @change="searchChanged" type="checkbox" />
                            <label>
                              My Uploads
                              <!--<router-link :to="{name: 'PhotoGalleryAdmin', params: { pageId: customerId }}"><i class="arrow right grey small icon"></i></router-link>-->
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </details>
        </div>
        <div class="main-label" style="float: right; cursor: pointer" @click="reverseSort()">Sort by {{ sortFieldText }} <i :class="`arrow ${sortDescending ? 'down' : 'up'} grey icon`"></i></div>

        <div>
          <div v-if="photosToShow.length === 0">
            <template v-if="groupedPhotoData.length === 0">
              <b>No photos or videos found for these filters.</b>
              <div v-if="groupingType === 'community-upload'" style="margin: 18px 0">
                <div style="display: inline-block">
                  <NoteWithIcon color="blue">
                    <template #icon><IconEmbedded name="info-simple_4" /></template>
                    <template #text>
                      <div>
                        <h5 class="global-h5">Where are my Community Uploads?</h5>
                        <span>Community Upload content will show here as community members upload it.</span>
                      </div>
                    </template>
                  </NoteWithIcon>
                </div>
              </div>
              <!--<div style="margin: 5px 0">In the meantime you can upload your own to use in a Photo Wall integration!</div>
              <router-link class="ui primary small button" :to="{ name: 'WidgetDirections', params: { pageId: customerId } }">Create a Photo Wall</router-link>-->
            </template>
            <template v-else>No photos or videos found for these filters.</template>
          </div>
          <div v-else class="photos-section" v-for="(group, i) in photosToShow" :key="`photoGroup-${i}`">
            <div class="section-header">
              <h5 v-if="group.linkRoute" class="global-h5" style="display: inline; color: black !important">
                <router-link target="_blank" :to="group.linkRoute" class="main-label" style="display: inline; color: black !important"
                  >{{ group.groupName }} <i class="external alternate small icon"></i
                ></router-link>
              </h5>
              <h5 v-else class="global-h5 main-label" style="display: inline; color: black !important">{{ group.groupName }}</h5>
              <span v-if="group.nonLinkText" class="date-label">{{ group.nonLinkText }}</span>
              <span class="date-label" v-if="group.sortValueAsc">{{ moment(sortDescending ? group.sortValueDesc : group.sortValueAsc).fromNow() }}</span>
              <template v-if="group.additionalInfo">
                <br />
                <div style="margin: 5px 0 13px; color: #5b5b5b; font: 14px/18px sans-serif">
                  <!--<b>User comments:</b>-->
                  <div
                    :class="{
                      'user-comments-text': true,
                      'user-comments-text--clamped': group.additionalInfo?.length > 300 && !isUserCommentsTextExpanded[i],
                    }"
                  >
                    {{ group.additionalInfo }}
                  </div>
                  <LinkWithIcon
                    v-if="group.additionalInfo?.length > 300"
                    style="margin-top: 1px"
                    @click="isUserCommentsTextExpanded[i] = !Boolean(isUserCommentsTextExpanded[i])"
                    color="blue"
                    isDottedUnderline
                  >
                    <template #icon>
                      <IconEmbedded
                        :name="isUserCommentsTextExpanded[i] ? 'caret-top_2' : 'caret-bottom_2'"
                        :size="23"
                        :style="{
                          marginTop: isUserCommentsTextExpanded[i] ? '0' : '1px',
                        }"
                      />
                    </template>
                    <span v-if="isUserCommentsTextExpanded[i]">show less</span>
                    <span v-else>show more</span>
                  </LinkWithIcon>
                </div>
              </template>
            </div>
            <div class="photos-container">
              <div v-for="(image, j) in group.photos" :key="`image-${i}-${j}`" style="margin: 0 3px 2px 0; display: inline-block; vertical-align: middle">
                <template v-if="isVideo(image)">
                  <button @click="downloadFile(image)" class="ui icon button download-btn">
                    <i class="download icon"></i>
                  </button>
                  <VideoRender
                    :videoId="image"
                    :showControls="false"
                    :showViewerOnClick="true"
                    :customStyles="'display: inline-block; max-height:150px; width: auto; height: auto; margin-bottom: -0.4rem; margin-right:3px;'"
                  />
                </template>
                <template v-else>
                  <button @click="downloadFile(image)" class="ui icon button download-btn">
                    <i class="download icon"></i>
                  </button>
                  <a lang="en" hreflang="en" data-fancybox="gallery" :href="generateImageSrc(image, 'full')" style="display: flex">
                    <img :src="generateImageSrc(image, 'thumb-med')" style="display: block; max-height: 150px; width: auto; height: auto" />
                  </a>
                </template>
              </div>
            </div>
            <div class="ui divider"></div>
          </div>
          <div style="text-align: center">
            <button class="ui primary button" v-if="hasMoreImages" @click="showMoreClick">Show More</button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { debounce } from "debounce";
import { defineComponent, inject } from "vue";
import FileDownload from "@logic/FileDownload";
import FileUtils from "@logic/FileUtils";
import moment from "moment";
import generateSrc, { ImageSize } from "@helpers/GenerateGallerySrc";

// Types
import { PhotosPageData } from "@contracts/photosPageData";

// Components
import CopyText from "@components/CopyText.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import Loader from "@components/Loader/Loader.vue";
import VideoRender from "@components/VideoRender.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { useHead } from "@unhead/vue";

export default defineComponent({
  name: "CommunityPhotosV2",

  components: {
    IconEmbedded,
    CopyText,
    NoteWithIcon,
    LinkWithIcon,
    Loader,
    VideoRender,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      title: "Photos & Videos",
      customerId: null as string | null,
      isLoading: true,
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      maxResults: 50,
      downloadUncompressed: true,

      // Data
      dataFromServer: null, // Raw data from server (we structure it differently on this page)
      groupedPhotoData: Array<PhotosPageData>(), // Grouped but not filtered
      filteredPhotoData: Array<PhotosPageData>(), // Filters applied to this
      photosToShow: Array<PhotosPageData>(), // Lazy loading applied ot this
      filterPhotoCount: 0,
      groupResultCount: 0,
      debounceCallback: null,
      sortField: "date",
      sortDescending: true,
      // Simple lazy loading
      maxImagesToShow: 250,
      showMoreIncremement: 250,
      hasMoreImages: true,

      // Filtering fields
      debounceWaitInMs: 250,
      filterText: "",
      filterShowVideos: true,
      filterShowPhotos: true,
      filterShowAdventurePhotos: true,
      filterShowCommunityUploads: true,
      filterShowMyUploads: true,
      groupingType: "adventure",

      isUserCommentsTextExpanded: [],
    };
  },

  computed: {
    sortFieldText(): string {
      if (this.sortField === "date") {
        return this.sortDescending ? "newest" : "oldest";
      } else return this.sortDescending ? "name desc" : "name asc";
    },
    groupTypeText(): string {
      let name = this.groupingType;
      if (this.groupingType === "community-upload") name = "community upload";
      return name;
    },
    groupTypeTextPluralized(): string {
      let name = this.groupingType;
      if (this.groupingType === "community-upload") name = "community upload";
      return name + (this.groupResultCount === 1 ? "" : "s");
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.customerId = this.$route.params.pageId as string;
    this.checkForDefaultFilters();
    await this.getData();
    this.updateHash();
  },

  methods: {
    moment,
    async getData() {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/photos/${this.customerId}/v2`);
      this.dataFromServer = data;
      this.groupData();
      this.isLoading = false;
      this.title += ` - ${this.dataFromServer.communityTitle}`;
    },
    showMoreClick() {
      this.maxImagesToShow += this.showMoreIncremement;
      this.buildListToShow();
    },
    groupData() {
      var startTime = performance.now();
      let grouped = new Array<PhotosPageData>();
      if (this.groupingType === "adventure") {
        // Adventure Photos
        this.dataFromServer.adventurePhotoSummaries.forEach(adv => {
          let photoCredit = adv.creatorDisplayName;
          if (adv.creatorCredit?.length > 0) photoCredit = adv.creatorCredit;
          let group = this.createPhotoGroup(
            adv.adventureName,
            [adv.titlePhoto],
            { name: "ItineraryPreview", params: { itineraryId: adv.uniqueName } },
            "adventure",
            adv.publishedDate,
            adv.publishedDate,
            `(Photo Credit: ${photoCredit})`
          );
          adv.locations.forEach(loc => group.photos.push(...loc.photos));
          grouped.push(group);
        });
        // Community Uploads
        if (this.dataFromServer.communityUploadPhotoSummaries?.length > 0) {
          let group = this.createPhotoGroup("Community Uploads", [], { name: "UserUploadedGalleryAdmin", params: { pageId: this.customerId } }, "communityupload", null, null, null);
          this.dataFromServer.communityUploadPhotoSummaries.forEach(communityUpload => group.photos.push(...communityUpload.photos));
          grouped.push(group);
        }
        // My Uploads
        if (this.dataFromServer.customerGalleryPhotos?.length > 0) {
          let group = this.createPhotoGroup(
            "My Uploads",
            [...this.dataFromServer.customerGalleryPhotos],
            { name: "PhotoGalleryAdmin", params: { pageId: this.customerId } },
            "myupload",
            null,
            null,
            null
          );
          grouped.push(group);
        }
      } else if (this.groupingType === "creator") {
        // Adventure Photos by Creator
        const creatorGrouping = this.groupBy(this.dataFromServer.adventurePhotoSummaries, adv => adv.creatorId);
        creatorGrouping.forEach(creatorGroup => {
          let name = creatorGroup[0].creatorDisplayName;
          let photoCredit = name;
          // Pass the Photo Credit as the nonLinkText
          if (creatorGroup[0].creatorCredit?.length > 0) photoCredit = creatorGroup[0].creatorCredit;
          let sortMinMax = this.getMinMax(creatorGroup, adv => adv.publishedDate);
          let group = this.createPhotoGroup(
            name,
            [],
            { name: "CreatorProfilePublic", params: { creatorId: creatorGroup[0].creatorId } },
            "adventure",
            sortMinMax[0],
            sortMinMax[1],
            `(Photo Credit: ${photoCredit})`
          );
          creatorGroup.forEach(adv => {
            group.photos.push(adv.titlePhoto);
            adv.locations.forEach(loc => group.photos.push(...loc.photos));
          });
          grouped.push(group);
        });
        // Community Uploads by Creator
        if (this.dataFromServer.communityUploadPhotoSummaries?.length > 0) {
          const communityUploadsGroupedByCreator = this.groupBy(this.dataFromServer.communityUploadPhotoSummaries, upload => upload.uploadersEmail);
          communityUploadsGroupedByCreator.forEach(uploadGroup => {
            let name = `${uploadGroup[0].uploadersName} (${uploadGroup[0].uploadersEmail})`;
            let sortMinMax = this.getMinMax(uploadGroup, upload => upload.createdDate);
            let group = this.createPhotoGroup(name, [], null, "communityupload", sortMinMax[0], sortMinMax[1], null, "");
            uploadGroup.forEach(upload => {
              group.photos.push(...upload.photos);
              if (upload.additionalInfo) {
                group.additionalInfo += upload.additionalInfo + "\n";
              }
            });
            grouped.push(group);
          });
        }
        // My Uploads
        if (this.dataFromServer.customerGalleryPhotos?.length > 0) {
          let group = this.createPhotoGroup(
            "My Uploads",
            [...this.dataFromServer.customerGalleryPhotos],
            { name: "PhotoGalleryAdmin", params: { pageId: this.customerId } },
            "myupload",
            null,
            null,
            null
          );
          grouped.push(group);
        }
      } else if (this.groupingType === "location") {
        // Adventure Photos by Location
        // Previously used flatMap but wanted a parent field so ended up with this
        const flattenedByLocation = [];
        this.dataFromServer.adventurePhotoSummaries.forEach(adv => {
          adv.locations.forEach(loc => {
            flattenedByLocation.push({
              publishedDate: adv.publishedDate,
              location: loc,
            });
          });
        });
        // const flattenedByLocation = this.dataFromServer.adventurePhotoSummaries.flatMap(adv => adv.locations);
        const locationGrouping = this.groupBy(flattenedByLocation, loc => loc.location.locationId);
        locationGrouping.forEach(locationGroup => {
          let sortMinMax = this.getMinMax(locationGroup, loc => loc.publishedDate);
          let group = this.createPhotoGroup(locationGroup[0].location.locationName, [], null, "adventure", sortMinMax[0], sortMinMax[1], null);
          if (!group.groupName || group.groupName.length === 0) group.groupName = "Unknown Location";
          locationGroup.forEach(loc => {
            group.photos.push(...loc.location.photos);
          });
          // Exclude locations without any photos
          if (group.photos.length > 0) grouped.push(group);
        });
        // NOTE: Not showing Community Uploads and My Uploads by location (since we don't have that data)
      } else if (this.groupingType === "community-upload") {
        // Only applies to Community Uploads
        const campaignIdGrouping = this.groupBy(this.dataFromServer.communityUploadPhotoSummaries, upload => upload.campaignId);
        campaignIdGrouping.forEach(byCampaignGroup => {
          let sortMinMax = this.getMinMax(byCampaignGroup, loc => loc.createdDate);
          let creatorNames = byCampaignGroup.map(upload => `${upload.uploadersName} (${upload.uploadersEmail})`).join(", ");
          let group = this.createPhotoGroup(byCampaignGroup[0].campaignName, [], null, "community-upload", sortMinMax[0], sortMinMax[1], null, "Uploads by " + creatorNames);
          if (!group.groupName || group.groupName.length === 0) group.groupName = "Unknown Campaign";
          byCampaignGroup.forEach(upload => {
            group.photos.push(...upload.photos);
          });
          grouped.push(group);
        });
      }
      var endTime = performance.now();
      this.globalLog.info(`Grouping took ${endTime - startTime}ms`);

      this.groupedPhotoData = grouped;
      this.applyFilters();
    },
    getMinMax(inputArray, fieldGetter) {
      if (!inputArray) {
        return null;
      }
      let minV = fieldGetter(inputArray[0]);
      let maxV = fieldGetter(inputArray[0]);
      for (let row of inputArray) {
        let rowVal = fieldGetter(row);
        if (rowVal) {
          if (rowVal < minV) minV = rowVal;
          if (rowVal > maxV) maxV = rowVal;
        }
      }
      return [minV, maxV];
    },
    calculateCounts() {
      this.groupResultCount = this.filteredPhotoData.length;
      this.filterPhotoCount = this.filteredPhotoData.reduce((n, { photos }) => n + photos.length, 0);
    },
    searchChanged() {
      // Note: Using Debounce here so it only actually fires periodically
      // this.globalLog.info("Search changed " + this.filterText);
      if (this.debounceCallback) {
        this.debounceCallback();
      } else {
        this.debounceCallback = debounce(this.applyFilters, this.debounceWaitInMs);
        this.debounceCallback();
      }
    },
    applyFilters() {
      this.globalLog.info("ApplyFilter " + this.filterText);

      var startTime = performance.now();
      // this.filterInProgress = true;
      let filterMatches = [];
      this.groupedPhotoData.forEach(inputGroup => {
        // Check if the group passes filters
        if (this.doesGroupPassFilters(inputGroup)) {
          // Check which photos pass filters
          let groupToAdd = this.applyPhotoFilter(inputGroup);
          if (groupToAdd?.photos.length > 0) {
            filterMatches.push(groupToAdd);
          }
        }
      });
      this.filteredPhotoData = filterMatches;
      var endTime = performance.now();
      // this.filterInProgress = false;
      this.globalLog.info(`Filtering took ${endTime - startTime}ms`);

      // Sort
      this.sortFilteredData();

      this.calculateCounts();
      this.buildListToShow();
      this.updateHash();
    },
    sortFilteredData() {
      if (this.sortField === "name") {
        if (this.sortDescending) this.filteredPhotoData.sort((a, b) => -a.groupName.localeCompare(b.groupName));
        else this.filteredPhotoData.sort((a, b) => a.groupName.localeCompare(b.groupName));
      } else {
        // Sorting by Date
        // The null coalesce should push null dates to the end
        if (this.sortDescending) this.filteredPhotoData.sort((a, b) => -(a.sortValueDesc ?? "2000").localeCompare(b.sortValueDesc ?? "2000"));
        else this.filteredPhotoData.sort((a, b) => (a.sortValueAsc ?? "9999").localeCompare(b.sortValueAsc ?? "9999"));
      }
    },
    doesGroupPassFilters(inputGroup: PhotosPageData): boolean {
      if (this.filterText?.trim().length > 0) {
        if (!this.includesCaseInsensitive(inputGroup.groupName, this.filterText)) return false;
      }
      // Source filters
      if (!this.filterShowAdventurePhotos && inputGroup.source === "adventure") return false;
      if (!this.filterShowCommunityUploads && inputGroup.source === "communityupload") return false;
      if (!this.filterShowMyUploads && inputGroup.source === "myupload") return false;

      return true;
    },
    applyPhotoFilter(inputGroup: PhotosPageData): PhotosPageData {
      // Currently there's only a single photo-level filter.
      // If this is show all just return the input
      if (this.filterShowVideos === true && this.filterShowPhotos === true) {
        return inputGroup;
      }
      let groupToReturn = this.createPhotoGroup(
        inputGroup.groupName,
        [],
        inputGroup.linkRoute,
        inputGroup.source,
        inputGroup.sortValueAsc,
        inputGroup.sortValueDesc,
        inputGroup.nonLinkText,
        inputGroup.additionalInfo
      );
      inputGroup.photos.forEach(photo => {
        // Video or Photo check
        let isVideo = this.isVideo(photo);
        if (this.filterShowVideos && isVideo) groupToReturn.photos.push(photo);
        else if (this.filterShowPhotos && !isVideo) groupToReturn.photos.push(photo);
      });
      return groupToReturn;
    },
    buildListToShow() {
      // Takes into account lazy loading
      let countAdded = 0;
      let toShowLocalList = new Array<PhotosPageData>();
      this.hasMoreImages = false;
      for (let i = 0; i < this.filteredPhotoData.length; i++) {
        // Check if we've added enough
        if (countAdded > this.maxImagesToShow) {
          this.hasMoreImages = true;
          break;
        }
        // Otherwise add the group
        let currentGroup = this.filteredPhotoData[i];
        countAdded += currentGroup.photos.length;
        toShowLocalList.push(currentGroup);
      }
      this.photosToShow = toShowLocalList;
    },
    createPhotoGroup(
      groupName: string,
      photos: Array<string>,
      linkRoute: any | null,
      source: string,
      sortValueAsc: string,
      sortValueDesc: string,
      nonLinkText: string,
      additionalInfo?: string | null
    ): PhotosPageData {
      return {
        groupName: groupName,
        photos: photos,
        linkRoute: linkRoute,
        // Currently used for PhotoCredit
        nonLinkText: nonLinkText,
        source: source,
        sortValueAsc: sortValueAsc,
        sortValueDesc: sortValueDesc,
        additionalInfo: additionalInfo,
      } as PhotosPageData;
    },
    setGroupingType(type: string) {
      this.groupingType = type;
      this.updateHash();
      this.groupData();
    },
    reverseSort() {
      this.sortDescending = !this.sortDescending;
      this.applyFilters();
    },
    includesCaseInsensitive(text: string, searchTerm: string): boolean {
      return text?.toLowerCase().includes(searchTerm.toLowerCase()) === true;
    },
    async downloadFile(imageName: string) {
      await FileDownload.downloadFile(imageName, this.downloadUncompressed, this.customerId, "photos-page");
    },
    checkForDefaultFilters() {
      // Some pages navigate in and want us to auto-apply filters
      let justCommunityUploads = this.getHashParameter("justCommunityUploads");
      if (justCommunityUploads) {
        // They typically get notified because a new creator uploads,
        // so defaulting this to creator (could also deafult to community-upload)
        this.groupingType = "creator";
        this.filterShowAdventurePhotos = false;
        this.filterShowCommunityUploads = true;
        this.filterShowMyUploads = false;
      }
      let group = this.getHashParameter("group");
      if (group) {
        this.groupingType = group;
      }
      let searchText = this.getHashParameter("search");
      if (searchText) {
        this.filterText = searchText;
      }
    },
    updateHash() {
      // Keep track of the filters based on the hash
      let hash = new URLSearchParams();
      hash.set("group", this.groupingType);
      if (this.filterText) hash.set("search", this.filterText);
      // (future) Comma separate filters
      window.location.hash = hash.toString();
    },
    getHashParameter(key: string): string | null {
      if (window.location.hash.length < 2) return null;
      const parsedHash = new URLSearchParams(window.location.hash.substring(1));
      return parsedHash.get(key);
    },
    generateImageSrc(imageName: string, size: ImageSize): string {
      return generateSrc(imageName, size);
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
    // Takes an Array<>, and a grouping property.
    // Returns a Map, grouped by the grouping property.
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach(item => {
        let key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// User comment text ==========================================================
.user-comments-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: #5b5b5b;
  font: 14px/18px sans-serif;
  white-space: pre-wrap;

  &--clamped {
    line-clamp: 5;
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
  }
}

// Filters section ============================================================
.filters-section {
  padding: 14px;
  margin-bottom: 2rem;
  box-sizing: border-box;
  border: 1px #ddd solid;
  border-radius: 6px;
  display: flex;

  &__details {
    width: 100%;
  }

  &__summary {
    width: 100%;
    margin-bottom: 15px;
    outline: none;
    position: relative;
    z-index: 6;
    font-weight: 700;
    cursor: pointer;
    user-select: none;

    &::before {
      content: "";
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__column {
    width: calc(50% - 20px);

    &--row {
      display: flex;
    }
  }

  &__section {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .filters-section {
    &__content {
      flex-direction: column;
    }

    &__column {
      width: 100%;
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// ============================================================================
.photos-container {
  // display: flex;
}
.filter-sort-group {
  margin-bottom: 1rem;
  .label-wrapper {
    display: flex;
    label {
      font-weight: 600;
      width: auto !important;
    }
    span {
      margin-left: 0.4rem;
    }
  }
  input {
    margin-top: 0 !important;
  }
}
.download-btn {
  font-size: 0.7rem !important;
  position: absolute;
  cursor: pointer;
  z-index: 30;
  margin-top: 0.25rem;
  margin-left: 0.25rem;
}
.other-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.main-label {
  font-weight: 600;
  color: gray;
  // padding: 0.3rem 0.6rem;
  // border-radius: 10px;
  // background-color: $faint-gray;
}
.photos-section {
  margin-bottom: 1rem;
  .section-header {
    margin-bottom: 0.5rem;
    .ui.label {
      color: $gray;
    }
    .divider {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      color: $gray;
    }
    .secondary-label {
      margin-right: 0.5rem;
    }
    .date-label {
      margin-left: 8px;
      font-size: 0.9rem;
    }
  }
}

.ui.button.primary {
  color: #fff !important;
  background: #118689 !important;
}
</style>
