<template>
  <div v-if="isVisible" class="spoiler-section">
    <SrpDetailsSummary class="spoiler-section__details-summary" v-model:is-opened="isOpened" :transition-duration="150">
      <template #heading="{ isOpened }">
        <div
          :class="{
            'global-h3': true,
            'spoiler-section__summary': true,
            'spoiler-section__summary--with-bottom-padding': !isOpened,
          }"
        >
          <span
            ><span>
              {{ collabComplete ? "Creator Visit Complete!" : "Content Ready!" }}
            </span></span
          >
        </div>
      </template>
      <template #details>
        <div class="spoiler-section__content">
          <template v-if="!collabComplete">
            <NoteWithIcon style="margin-bottom: 20px">
              <template #text>
                <div>
                  <template v-if="daysToProvideCorrections - countDaysFromDateToNow(collabInput.collaboration.notifications.firstApproveNotification) > 0">
                    <h5 class="global-h5" style="margin-bottom: 4px; display: inline">
                      In the next {{ daysToProvideCorrections - countDaysFromDateToNow(collabInput.collaboration.notifications.firstApproveNotification) }} days
                    </h5>
                    please review and request any corrections needed. Thanks!
                  </template>
                  <template v-else>
                    <h5 class="global-h5" style="display: inline">As soon as possible</h5>
                    please review and request any corrections needed. Thanks!
                  </template>
                </div>
              </template>
            </NoteWithIcon>

            <div class="spoiler-section__content-divider" style="margin-bottom: 20px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
          </template>

          <StatNumbersBanner
            style="margin-bottom: 35px"
            :fields="{
              Locations: customerSummary?.locationCount,
              Photos: customerSummary?.photoCount,
              Videos: customerSummary?.videoCount,
            }"
          />

          <div class="spoiler-section__content-divider" style="margin-bottom: 30px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

          <template v-if="customerSummary?.mediaIds?.length">
            <!-- Section header -->
            <div class="section-header" style="margin-bottom: 12px">
              <h4 class="global-h4 section-header__title">Photos &amp; Videos</h4>

              <LinkWithIcon
                v-if="collabInput.collaboration.completedDate"
                class="section-header__link"
                :to="{
                  name: 'CommunityPhotosV2',
                  params: { pageId: communityId },
                }"
                tag="RouterLink"
              >
                <template #icon><IconEmbedded name="image_2" :size="20" /></template>
                <span>All photos</span>
              </LinkWithIcon>
            </div>
            <!-- / Section header -->

            <!-- Highlights section wrap -->
            <div
              :class="{
                'highlights-section-wrap': true,
                'highlights-section-wrap--with-curtain': !isHighlightsExpanded && customerSummary?.mediaIds?.length > numberOfThumbnailsToShowWithoutCollapsing,
              }"
              style="margin-bottom: 30px"
            >
              <Component
                :is="customerSummary?.mediaIds?.length > numberOfThumbnailsToShowWithoutCollapsing ? 'TransitionedHeightAuto' : 'div'"
                class="highlights-section-wrap__transitioned-height-wrap"
                :minHeight="screenSize === 'mobile' ? 230 : 350"
                :isOpened="isHighlightsExpanded"
                isKeepAlive
                :transitionDuration="150"
              >
                <HighlightsSection class="highlights-section-wrap__highlights-section" :highlightAssetIds="customerSummary?.mediaIds" :visibleLines="100" />
              </Component>

              <LinkWithIcon
                v-if="customerSummary?.mediaIds?.length > numberOfThumbnailsToShowWithoutCollapsing"
                class="highlights-section-wrap__show-more-btn"
                isDottedUnderline
                iconInset="1px auto auto 2px"
                @click="isHighlightsExpanded = !isHighlightsExpanded"
              >
                <template #icon><IconEmbedded :name="isHighlightsExpanded ? 'caret-top_4' : 'caret-bottom_4'" :size="14" /></template>
                <span>{{ isHighlightsExpanded ? "Hide" : "Show more" }}</span>
              </LinkWithIcon>
            </div>
            <!-- / Highlights section wrap -->

            <div style="margin-bottom: 21px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
          </template>

          <div style="margin-bottom: 30px">
            <h4 v-if="customerSummary?.adventures.length > 0" class="global-h4" style="margin-bottom: 12px">Adventures</h4>
            <div v-if="customerSummary?.adventures.length > 0" style="padding-bottom: 10px">
              <ul class="adv-tiles-list">
                <ItineraryTile
                  class="adv-tiles-list__li"
                  v-for="itinerary in customerSummary?.adventures"
                  :key="itinerary.id"
                  :allowShortImage="false"
                  :itinerary="itinerary"
                  openNewWindow
                  hideFooter
                />
              </ul>
            </div>
          </div>

          <h4 v-if="customerSummary?.adventures.length > 0" class="global-h4" style="margin-bottom: 12px">Quick Adventure Edits</h4>
          <div v-if="customerSummary?.adventures.length > 0" style="padding-bottom: 10px">
            <ul style="width: 100%; padding: 0; margin: 0; display: flex; flex-wrap: wrap; list-style: none">
              <ItineraryDetailsSnippet
                class=""
                v-for="(itinerary, index) in customerSummary?.adventures"
                :key="itinerary.id"
                :itinerary="itinerary"
                :style="{ marginBottom: index !== customerSummary?.adventures.length - 1 ? '25px' : 0 }"
                target="_blank"
                :enableStopDetailsSection="true"
                :isEditingAllowed="!Boolean(collabInput.collaboration.completedDate)"
                :defaultStopsOpen="!Boolean(collabInput.collaboration.completedDate)"
              />
            </ul>
          </div>

          <template v-if="FeatureFlags.isFlagActive('EnableLetsMakeTheMostOfThisSection')">
            <div style="margin-bottom: 25px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

            <h4 class="global-h4" style="margin-bottom: 12px">Let's make the most of these!</h4>

            <!-- Tiles section -->
            <ul class="tiles-section" style="margin-bottom: 35px">
              <!-- Make the most snippet -->
              <li class="make-the-most-snippet tiles-section__tile">
                <h4 class="global-h4 make-the-most-snippet__title">Create Social<br />Media Post</h4>
                <div class="make-the-most-snippet__image-wrap">
                  <img class="make-the-most-snippet__image" :src="images.makeTheMostImage1" alt="Create Social Media Post" />
                </div>
                <SrpButton class="make-the-most-snippet__button">
                  <template #icon><IconEmbedded name="file-text_2-5" :size="23" /></template>
                  Create
                </SrpButton>
              </li>
              <!-- / Make the most snippet -->

              <!-- Make the most snippet -->
              <li class="make-the-most-snippet tiles-section__tile">
                <h4 class="global-h4 make-the-most-snippet__title">Add Photo Wall<br />to your website</h4>
                <div class="make-the-most-snippet__image-wrap">
                  <img class="make-the-most-snippet__image" :src="images.makeTheMostImage2" alt="Add Photo Wall to your website" />
                </div>
                <SrpButton class="make-the-most-snippet__button">
                  <template #icon><IconEmbedded name="tag_2-5" :size="24" /></template>
                  Add
                </SrpButton>
              </li>
              <!-- / Make the most snippet -->

              <!-- Make the most snippet -->
              <li class="make-the-most-snippet tiles-section__tile">
                <h4 class="global-h4 make-the-most-snippet__title">Add Adventure Wall<br />to your website</h4>
                <div class="make-the-most-snippet__image-wrap">
                  <img class="make-the-most-snippet__image" :src="images.makeTheMostImage3" alt="Add Adventure Wall to your website" />
                </div>
                <SrpButton class="make-the-most-snippet__button">
                  <template #icon><IconEmbedded name="tag_2-5" :size="24" /></template>
                  Add
                </SrpButton>
              </li>
              <!-- / Make the most snippet -->
            </ul>
            <!-- / Tiles section -->

            <h4 class="global-h4" style="margin-bottom: 12px">Locations</h4>

            <!-- Tiles section -->
            <ul class="tiles-section">
              <LocationSnippet class="tiles-section__tile" v-for="location in customerSummary?.locations" :key="location.id" :location="location" />
            </ul>
            <!-- / Tiles section -->
          </template>

          <div class="floating-buttons-panel">
            <div v-if="!showCompleteSection && !showCorrectionsSection" style="margin-bottom: 20px">
              <label v-if="correctionsWereMade" class="cms-section-title" style="margin-bottom: 12px !important">The creator made the corrections, how do they look?</label>
              <label v-else class="cms-section-title">How does everything look?</label>
              <button :disabled="isSaving" class="ui primary button" @click="looksGoodClick()" style="margin: 0 50px 12px 0">Looks good!</button>
              <button class="ui primary button" @click="showCorrectionsSection = true">I'd like to request {{ correctionsWereMade ? "further" : "" }} corrections</button>
            </div>
            <!--Looks Great-->
            <div v-if="showCompleteSection">
              <div style="font-size: 1.2em">
                <div v-if="!collabComplete">Awesome! We've marked this Creator Visit as complete.</div>
                <h4 class="global-h4" style="margin-bottom: 14px">Let's make the most of these!</h4>

                <ul style="margin: 0; padding: 0; list-style: none">
                  <li style="margin-bottom: 5px">
                    - Share them on
                    <a href="https://explore.shrpa.com/integrations/sharing-adventures-on-social-media/" target="_blank" style="text-decoration: underline">Social Media!</a>
                  </li>
                  <li style="margin-bottom: 5px">
                    - Add the adventures to your website with
                    <router-link :to="{ name: 'AdventureWidgets', params: { pageId: communityId } }" style="text-decoration: underline"> Website Integration</router-link>
                  </li>
                </ul>
              </div>
            </div>

            <!--Corrections-->
            <div v-if="showCorrectionsSection">
              <label class="cms-section-title" style="display: inline">Request Creator Corrections?</label>
              <span style="float: right; margin-top: 8px" @click="showCorrectionsSection = false">
                <div name="angle left" class="large angle left" />
              </span>
              <div>
                Give creative license when possible and be kind!
                <textarea maxlength="3000" v-model="collabInput.correctionsForCreator" :placeholder="textareaPlaceholder" style="height: 70px; min-height: 70px"></textarea>
                <div v-if="showRequired" style="color: red; font-weight: bold">Corrections Required</div>
                <div v-if="needsCommunityReview" style="margin-top: 10px">
                  <button :disabled="isSaving" @click="requestCorrections()" class="ui primary button">That's everything, request the corrections!</button>
                  <h4 v-if="isSaving" style="display: inline">&nbsp;Saving...</h4>
                </div>
                <div v-else style="margin-top: 12px; font-size: 1.3em">Thanks! We've notified the creator and you should expect the updates soon.</div>
              </div>
            </div>
          </div>

          <div>Questions or issues? Feel free to email us at <CopyText :text="'collabs@shrpa.com'" /></div>
        </div>
      </template>
    </SrpDetailsSummary>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import CollabStatuses from "@logic/CollabStatuses";
import CollabStateHelper from "@logic/CollabStateHelper";
import FeatureFlags from "@logic/FeatureFlags";
import FileUtils from "@logic/FileUtils";

// Types
import { CollabInput, CreatorCollab } from "@contracts/collab";
import { Itinerary } from "@contracts/itinerary";
import { ScreenSize } from "@contracts/screenSize";
import { CollabLocation } from "@contracts/collabLocations";

interface CustomerSummary {
  locationCount: number;
  photoCount: number;
  videoCount: number;

  mediaIds: Array<string>;
  adventures: Array<Itinerary>;
  locations: Array<CollabLocation>;
}

// Components
import CopyText from "@components/CopyText.vue";
import HighlightsSection from "@components/HighlightsSection.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import ItineraryDetailsSnippet from "@components/ItineraryDetailsSnippet.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import LocationSnippet from "./LocationSnippet.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import StatNumbersBanner from "@components/StatNumbersBanner.vue";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";

// Images
import makeTheMostImage1 from "@assets/images/make-the-most-image-1.jpg";
import makeTheMostImage2 from "@assets/images/make-the-most-image-2.jpg";
import makeTheMostImage3 from "@assets/images/make-the-most-image-3.jpg";

export default defineComponent({
  name: "CommunityCorrections",

  components: {
    CopyText,
    HighlightsSection,
    IconEmbedded,
    ItineraryDetailsSnippet,
    ItineraryTile,
    LinkWithIcon,
    LocationSnippet,
    SrpButton,
    SrpDetailsSummary,
    StatNumbersBanner,
    TransitionedHeightAuto,
    NoteWithIcon,
  },

  props: {
    communityId: { type: String, required: true },
    collabInput: { type: Object as () => CollabInput, required: true },
    collabIndex: { type: Number, required: true },
    creatorId: { type: String, required: true },
  },

  emits: ["updated"],

  data() {
    return {
      globalLog: inject("globalLog") as any,
      screenSize: inject("screenSize") as ScreenSize,

      daysToProvideCorrections: 10, // NOTE: Configured on the server also
      isSaving: false,
      showRequired: false,
      isOpened: true,

      showCorrectionsSection: false,
      showCompleteSection: false,

      textareaPlaceholder: "- Is anything missing or inaccurate?\n" + "- Other changes needed?",

      isHighlightsExpanded: false,

      FileUtils,
      FeatureFlags,

      customerSummary: null as CustomerSummary,

      images: {
        makeTheMostImage1,
        makeTheMostImage2,
        makeTheMostImage3,
      },

      numberOfThumbnailsToShowWithoutCollapsing: 12,
    };
  },

  computed: {
    collabComplete(): boolean {
      return Boolean(this.collabInput.collaboration.completedDate);
    },
    needsCommunityReview(): boolean {
      return this.collabInput.correctionsStatus === CollabStatuses.CorrectionStatusCommunityReview;
    },
    isVisible(): boolean {
      return CollabStateHelper.canShowCorrectionsUI(this.collabInput);
    },
    correctionsWereMade(): boolean {
      // Corrections status (see CollabStatuses.ts)
      return this.collabInput.collaboration.status === CollabStatuses.CreatorCorrectionsMade;
    },
  },

  watch: {
    // Resets the state since they changed collabs
    collabIndex: function (newVal, oldVal) {
      this.globalLog.info(`collabIndex changed: new=${newVal}, old=${oldVal}`);
      this.onLoad();
    },
    collabInput: {
      handler(_, oldVal) {
        if (oldVal != null) {
          this.globalLog.debug("Emitting update for Collab");
          this.$emit("updated", this.collabInput, this.collabIndex);
        }
      },
      deep: true,
    },
  },

  async mounted() {
    await this.onLoad();
  },

  methods: {
    async onLoad() {
      if (this.collabInput.collaboration.completedDate) {
        this.showCorrectionsSection = false;
        this.showCompleteSection = true;
      } else if (
        this.collabInput.correctionsForCreator &&
        this.collabInput.correctionsForCreator.length > 0 &&
        // NOTE: Use the status to check if corrections have been made and re-ask them if things look good.
        this.collabInput.collaboration.status !== "105"
      ) {
        this.showCorrectionsSection = true;
      } else {
        // Reset the variables back to their defaults
        this.showCompleteSection = false;
        this.showCorrectionsSection = false;
      }

      this.customerSummary = await this.loadCustomerSummary();
    },
    async loadCustomerSummary(): Promise<CustomerSummary> {
      let uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}/customer-summary?status=CollabCommunityReview`;
      const { data } = await axios.get<CustomerSummary>(uri);

      return data;
    },
    countDaysFromDateToNow(dateToStartFrom: string): number {
      if (!dateToStartFrom) {
        return 0;
      }
      const pastDate: Date = new Date(dateToStartFrom);
      const currentDate: Date = new Date();

      const diffInMilliseconds: number = currentDate.getTime() - pastDate.getTime();

      return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    },
    async requestCorrections() {
      if (!this.validate()) {
        return;
      }
      this.isSaving = true;
      const config = { headers: { "Content-Type": "application/json" } };
      await axios.put(`${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}/correctionsRequest`, JSON.stringify(this.collabInput.correctionsForCreator), config);
      this.isSaving = false;
      // Update the status
      this.collabInput.correctionsStatus = "PendingCreator";
    },
    async looksGoodClick() {
      this.isSaving = true;
      var response = await axios.put(`${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${this.collabInput.id}/communityapproved`);
      this.isSaving = false;
      // Mark complete
      this.collabInput.collaboration.completedDate = response.data;
      this.showCompleteSection = true;
    },
    validate(): boolean {
      if (!this.collabInput.correctionsForCreator || this.collabInput.correctionsForCreator.length == 0) {
        this.showRequired = true;
        return false;
      }
      // All good
      this.showRequired = false;
      return true;
    },
  },
});
</script>

<style scoped lang="scss">
@import "../AllCollabsV2.scss";
@import "@/scss/screen-size-ranges.scss";

// Section header =============================================================
.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
  }

  &__link {
  }
}

// Make the most snippet ======================================================
.make-the-most-snippet {
  padding: 30px 15px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(231, 237, 240, 1);

  &__title {
    margin-bottom: 7px;
    font-weight: 400;
    text-align: center;
  }

  &__image-wrap {
    width: 75%;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
  }

  &__image {
    width: 100%;
  }

  &__button {
  }
}

.floating-buttons-panel {
  margin-bottom: 20px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  position: sticky;
  inset: auto auto 0 0;
  z-index: 50;

  &::before {
    content: "";
    width: 100%;
    height: calc(100% + 50px);
    position: absolute;
    inset: auto auto 0 0;
    z-index: -1;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .floating-buttons-panel {
    padding-bottom: 60px;
  }
}

// Adventure tiles list =======================================================
.adv-tiles-list {
  width: 100%;
  gap: 20px;
  padding: 0 0 40px;
  margin: 0;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__li {
    width: calc(100% / 4 - 16px);
    margin: 0;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .adv-tiles-list {
    &__li {
      width: calc(100% / 4 - 15px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .adv-tiles-list {
    &__li {
      width: calc(100% / 3 - 14px);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .adv-tiles-list {
    gap: 15px;

    &__li {
      width: calc(100% / 2 - 10px);
    }
  }
}

// Tiles section ==============================================================
.tiles-section {
  width: var(--grid-col-right-width);
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: $desktop-wide-grid-gap-width;
  list-style: none;

  &__tile {
    width: calc(100% / 3 - $desktop-wide-grid-gap-width * 2 / 3);
    max-width: calc(100% / 3 - $desktop-wide-grid-gap-width * 2 / 3);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .tiles-section {
    width: var(--grid-content-full-width);
    gap: $tablet-large-grid-gap-width;

    &__tile {
      width: calc(100% / 3 - $tablet-large-grid-gap-width * 2 / 3);
      max-width: calc(100% / 3 - $tablet-large-grid-gap-width * 2 / 3);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .tiles-section {
    width: var(--grid-content-full-width);
    gap: $tablet-grid-gap-width;

    &__tile {
      width: calc(100% / 2 - $tablet-grid-gap-width / 2);
      max-width: calc(100% / 2 - $tablet-grid-gap-width / 2);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .tiles-section {
    width: var(--grid-content-full-width);
    gap: $mobile-grid-gap-width;

    &__tile {
      width: calc(100% / 2 - $mobile-grid-gap-width / 2);
      max-width: calc(100% / 2 - $mobile-grid-gap-width / 2);
    }
  }
}

// Highlights section wrap ====================================================
.highlights-section-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: 100%;
    height: 200px;
    position: absolute;
    inset: auto auto 0 0;
    z-index: 2;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease-in-out;
  }

  &--with-curtain {
    &::before {
      opacity: 1;
    }
  }

  &__transitioned-height-wrap {
    position: relative;
    z-index: 1;
  }

  &__highlights-section {
  }

  &__show-more-btn {
    position: relative;
    z-index: 3;
  }
}

.ui.form {
  padding: 20px;
  box-shadow: 0 0 5px 0 #ccc;
  .cms-section-title {
    margin: 25px 0 10px 0 !important;
  }
  .btn-groups {
    text-align: right;
  }

  .adventure-heading {
    display: inline;
  }
}
@media screen and (max-width: 420px) {
  .ui.form {
    padding: 15px;
    h1 {
      font-size: 18px !important;
    }
  }
}

.large-accordion-dropdown {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  position: relative !important;
  z-index: 5;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  &::before {
    content: "";
    width: calc(100% + 17px);
    height: calc(100% + 17px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  h2 {
    height: 30px;
    margin: 0;
    border-bottom: 1px transparent solid;
    display: inline-block;
    transition:
      border-color 0.12s ease-in-out,
      opacity 0.12s ease-in-out;
  }

  &:hover h2 {
    border-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
