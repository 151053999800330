<template>
  <div>
    <div class="ui form">
      <div>
        <h1 style="display: inline; margin-right: 25px">Manage your Subscription</h1>
      </div>
      <Loader v-if="isLoading" />
      <template v-else>
        <label class="cms-section-title">Current Plan</label>
        <div>
          <div style="font-weight: bold">
            {{ subscriptionData.subscriptionName }}<br />
            Your plan {{ subscriptionData.isCancelling ? "ends" : "renews" }} on:
            <span v-if="subscriptionData.subscriptionEndDate">
              {{ moment(subscriptionData.subscriptionEndDate).format("MMMM Do, YYYY") }}
            </span>
            <div v-if="subscriptionData.billingEmail">Billing Email: {{ subscriptionData.billingEmail }}</div>
          </div>
        </div>

        <template v-if="subscriptionData.canManageSubscriptionInStripe">
          <label class="cms-section-title">Update your Subscription</label>
          <div>
            <ul>
              <li>Update your payment method</li>
              <li>Update your billing email or address</li>
              <li>View past invoices</li>
            </ul>
            <button :disabled="stripeSessionMessage" class="ui small primary button" @click="navigateToStripe">Manage my Subscription</button>
            <span style="margin-left: 7px" v-if="stripeSessionMessage"
              ><b>{{ stripeSessionMessage }}</b></span
            >
            <br />
          </div>
        </template>

        <label class="cms-section-title" style="margin-top: 2em !important">Changes to your Subscription Level?</label>
        <p>
          <template v-if="subscriptionData.partOfCoop"> Your subscription is managed through a Co-op program.<br /> </template>
          Reach out anytime to <CopyText /> and we'll be happy to help!
        </p>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import { Page } from "@contracts/pages";
import OrgContext from "@logic/OrgContext";
import CopyText from "@components/CopyText.vue";
import moment from "moment";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useHead } from "@unhead/vue";

// Note: Integrates with Stripe Customer Portal
// - https://stripe.com/docs/customer-management/integrate-customer-portal

export default defineComponent({
  name: "SubscriptionSettings",

  components: {
    Loader,
    CopyText,
  },

  data() {
    return {
      globalRemoteLogger: inject("globalRemoteLogger") as any,

      title: "Subscription Management",
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      pageId: "",
      orgName: null,
      isLoading: true,
      // Tells the user the state of the session setup/redirect to Stripe
      stripeSessionMessage: null,

      subscriptionData: null,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile"]),
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.pageId = this.$route.params.pageId as string;
    const orgInContext = OrgContext.getOrgInContext(this);
    this.orgName = orgInContext?.name;
    this.title += " - " + this.orgName;
    this.loadSubscription();
  },

  methods: {
    moment,
    async loadSubscription() {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/customers/${this.pageId}/subscriptions/current`);
      this.subscriptionData = data;
      this.isLoading = false;
    },
    async navigateToStripe() {
      try {
        this.stripeSessionMessage = "Redirecting...";
        const returnUrl = window.location.href;
        const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/customers/${this.pageId}/stripe-session?returnUrl=${encodeURIComponent(returnUrl)}`);
        // @ts-ignore
        const currentUserAuthId = this.getViewingUserProfile?.authNameId;
        this.globalRemoteLogger.info(`StripeManageSubscription: ${this.pageId} by ${currentUserAuthId}`, true);
        const stripeUrl = data;
        window.location.href = stripeUrl;
      } catch (error) {
        this.stripeSessionMessage = "Error generating Stripe Session.";
        this.globalRemoteLogger.error(`--ALERT-- StripeSessionGenerate Failed for ${this.pageId} error: ${error.message}`, true);
      }
    },
  },
});
</script>

<style scoped lang="scss"></style>
