<template>
  <a
    class="fancybox-button file-thumbnail__fancybox-button"
    :data-fancybox="props.galleryPostfix"
    :href="props.fullSizeImgLink"
    :style="{
      transformOrigin: props.transformOrigin,
    }"
  >
    <IconEmbedded class="fancybox-button__icon-thick" name="maximize_2" :size="19" />
    <IconEmbedded class="fancybox-button__icon-thin" name="maximize_1-5" :size="19" />

    <img class="fancybox-button__invisible-image" :src="props.thumbnailLink" alt="image" />
  </a>
</template>

<script setup lang="ts">
// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import generateSrc from "@helpers/GenerateGallerySrc";

const props = withDefaults(
  defineProps<{
    galleryPostfix: string;
    fullSizeImgLink: string;
    thumbnailLink: string;
    callback?: Function;
    transformOrigin?: "top right" | "top left" | "bottom left" | "bottom right";
  }>(),
  {
    galleryPostfix: "",
    fullSizeImgLink: "",
    thumbnailLink: "",
    callback: () => {},
    transformOrigin: "top right",
  }
);
</script>

<style scoped lang="scss">
// Fancybox button ============================================================
.fancybox-button {
  width: 21px;
  height: 21px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  color: #fff;
  font-size: 17px;
  line-height: 17px;
  background: rgba(0, 0, 0, 0.15);
  //Caused issues when lots of photos: backdrop-filter: blur(10px);
  cursor: pointer;
  transition: transform 0.07s ease-in-out;

  &__invisible-image {
    display: none;
  }

  &::after {
    content: "";
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    border-radius: 6px;
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    transform: scale(1.4);
  }

  &__icon-thick {
    display: block;

    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }
  }
  &:hover &__icon-thick {
    display: none;
  }

  &__icon-thin {
    display: none;

    :deep(svg) {
      fill: rgba(255, 255, 255, 1);
    }
  }
  &:hover &__icon-thin {
    display: block;
  }
}
</style>
