import { ItineraryStep } from "@contracts/itinerary";
import AddressHelper from "@helpers/AddressHelper";
import { CollabLocation } from "@contracts/collabLocations";

export default {
  setItineraryFieldsFromPlace(place: globalThis.google.maps.places.PlaceResult, stop: ItineraryStep | CollabLocation) {
    // todo: encapsulate this?  Definately the city/state logic
    stop.title = place.name;
    stop.externalId = place.place_id;
    stop.address = AddressHelper.removeCountryAndZipCode(place.formatted_address);
    stop.website = place.website;
    if (place.geometry) {
      stop.latitude = place.geometry.location.lat();
      stop.longitude = place.geometry.location.lng();
    }

    // Set City, State, etc...
    // Note: Similar logic in PaidCreatorFields
    // NOTE! Logic duplicated in BackfillController.cs
    stop.country = place.address_components?.find(c => c.types.includes("country"))?.short_name;
    stop.state = place.address_components?.find(c => c.types.includes("administrative_area_level_1"))?.short_name;
    stop.city = place.address_components?.find(c => c.types.includes("locality"))?.short_name;
    // If city is null fallback to AdminAreaL3 (typically a Township), then L2 (typically a county)
    if (!stop.city) {
      stop.city = place.address_components?.find(c => c.types.includes("administrative_area_level_3"))?.short_name;
    }
    if (!stop.city) {
      stop.city = place.address_components?.find(c => c.types.includes("administrative_area_level_2"))?.short_name;
    }
  },
};
