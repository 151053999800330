<template>
  <div>
    <div class="ui form">
      <div style="margin-bottom: 15px">
        <div>
          <h1 style="margin-bottom: 10px; display: inline">
            Photo Wall<span v-if="widgetData"> - {{ widgetData.name }}</span>
          </h1>
        </div>
        <div>Embed amazing photos directly on your website!</div>
      </div>
    </div>

    <Loader v-if="isLoading" />
    <div v-else>
      <div class="ui clearing segment">
        <!--<div>
      <div :active="!previewCollapsed">
          <h3><i class="dropdown icon"></i>Preview</h3>
      </div>
      <div :active="!previewCollapsed">
        <PhotoWallPreview 
          :selectedImages="widgetData.photoIds"
          :settings="widgetData.settings"/>
      </div>
    </div>-->
        <div>
          <button class="ui mini basic right floated button" @click="isEditPreview = !isEditPreview">
            {{ isEditPreview ? "Done Editing" : "Edit/Move Photos" }}
          </button>
          <h3 style="margin-top: 0; margin-bottom: 1rem">Preview</h3>
        </div>
        <PhotoWallPreview
          :selectedImages="widgetData.photoIds"
          :settings="widgetData.settings"
          :isEdited="isEditPreview"
          :removeImageFunc="removeImage"
          @reorder="newImagesArray => (widgetData.photoIds = newImagesArray)"
        />
      </div>

      <details class="ui segment">
        <summary style="cursor: pointer; user-select: none">
          <h3 style="display: inline">Customize</h3>
        </summary>

        <!-- Filters spoiler -->
        <div class="filters-spoiler">
          <!-- Filters section -->
          <div class="filters-section filters-spoiler__section filters-spoiler__section--column">
            <label class="section-title">What to Show</label>
            <div class="ui form">
              <div class="grouped fields">
                <div style="padding: 10px 14px; margin-bottom: 10px; border: 1px #e3e3e3 solid; border-radius: 4px">
                  <label style="margin-bottom: 7px !important; display: flex; align-items: center; cursor: pointer">
                    <input type="radio" name="alwaysShowAll" v-model="widgetData.settings.alwaysShowAll" style="width: 13px; margin-right: 8px; position: relative; top: -1px" :value="false" />
                    <b>Set Number of Rows</b>
                  </label>
                  <div class="description">
                    <div style="margin-bottom: 0.5rem">How many rows of photos to show?</div>
                    <button class="ui icon left attached button" @click="setRowsToShow(-1)" :disabled="widgetData.settings.alwaysShowAll || widgetData.settings.rowsToShow <= 1">
                      <i class="minus circle icon"></i>
                    </button>
                    <span class="number-of-rows">
                      {{ widgetData.settings.rowsToShow }}
                    </span>
                    <button class="right attached ui icon button" @click="setRowsToShow(1)" :disabled="widgetData.settings.alwaysShowAll"><i class="plus circle icon"></i></button>
                    <br />
                    <div class="ui checkbox" style="margin-top: 1rem">
                      <input type="checkbox" id="allowShowMore" v-model="widgetData.settings.allowShowMore" :disabled="widgetData.settings.alwaysShowAll" />
                      <label for="allowShowMore" class="select-photos">"Show More" button enabled</label>
                    </div>
                  </div>
                </div>

                <div style="padding: 10px 14px; border: 1px #e3e3e3 solid; border-radius: 4px">
                  <label style="margin-bottom: 7px !important; display: flex; align-items: center; cursor: pointer">
                    <input type="radio" name="alwaysShowAll" v-model="widgetData.settings.alwaysShowAll" style="width: 13px; margin-right: 8px; position: relative; top: -1px" :value="true" />
                    <b>Show All Photos</b>
                  </label>
                  <div class="description">Always show all {{ selectedPhotoCount }} photos.</div>
                </div>
              </div>
            </div>
          </div>
          <!-- / Filters section -->

          <!-- Filters section -->
          <div class="filters-section filters-spoiler__section filters-spoiler__section--column">
            <label class="section-title">Photo Size</label>
            <div class="ui form">
              <div class="grouped fields">
                <label class="field checkbox-field" style="margin-bottom: 10px !important">
                  <div style="padding: 5px 0; display: flex; align-items: center; cursor: pointer">
                    <input type="radio" name="imageHeight" v-model="widgetData.settings.imageHeight" :value="5" style="width: 13px; height: 13px; margin-right: 8px" />
                    Small Photos
                  </div>
                  <div class="description">
                    <i class="image large grey icon"></i>
                    <i class="image large grey icon"></i>
                    <i class="image large grey icon"></i>
                  </div>
                </label>
                <label class="field checkbox-field" style="margin-bottom: 10px !important">
                  <div style="padding: 5px 0; display: flex; align-items: center; cursor: pointer">
                    <input type="radio" name="imageHeight" v-model="widgetData.settings.imageHeight" :value="7" style="width: 13px; height: 13px; margin-right: 8px" />
                    Medium Photos
                  </div>
                  <div class="description">
                    <i class="image big grey icon"></i>
                    <i class="image big grey icon"></i>
                    <i class="image big grey icon"></i>
                  </div>
                </label>
                <label class="field checkbox-field" style="margin-bottom: 10px !important">
                  <div style="padding: 5px 0; display: flex; align-items: center; cursor: pointer">
                    <input type="radio" name="imageHeight" v-model="widgetData.settings.imageHeight" :value="10" style="width: 13px; height: 13px; margin-right: 8px" />
                    Large Photos
                  </div>
                  <div class="description">
                    <i class="image huge grey icon"></i>
                    <i class="image huge grey icon"></i>
                    <i class="image huge grey icon"></i>
                  </div>
                </label>
                <!--<div>{{widgetData.settings.imageHeight}}</div>-->
              </div>
            </div>
          </div>
          <!-- / Filters section -->

          <!-- Filters section -->
          <div class="filters-section filters-spoiler__section filters-spoiler__section--column">
            <label class="section-title">Padding Between Photos</label>
            <i class="rounded image huge grey outline icon" :style="{ margin: '-5px', marginRight: Number(widgetData.settings.padding) / 2 - 5 + 'px' }"></i>
            <i class="rounded image huge grey outline icon" :style="{ margin: '-5px', marginRight: Number(widgetData.settings.padding) / 2 - 5 + 'px' }"></i>
            <i class="rounded image huge grey outline icon" :style="{ margin: '-5px', marginRight: Number(widgetData.settings.padding) / 2 - 5 + 'px' }"></i>
            <input type="range" step="10" max="40" style="max-width: 300px" class="slider custom-slider" v-model="widgetData.settings.padding" />
            <!--<div>{{widgetData.settings.padding}}</div>-->
          </div>
          <!-- / Filters section -->

          <!-- Filters section -->
          <div class="filters-section filters-spoiler__section filters-spoiler__section--column">
            <label class="section-title">Color Settings</label>
            <div class="sub-section-title">Theme</div>
            <div class="ui left icon input" style="margin-bottom: 1rem">
              <i class="hashtag black icon"></i>
              <input type="text" v-model="widgetData.settings.color" placeholder="Color Hex Value" />
            </div>

            <div class="ui divider"></div>
            <div class="field checkbox-field">
              <div class="ui checkbox">
                <input type="checkbox" v-model="widgetData.settings.randomizePhotos" id="random-photos" />
                <label for="random-photos" class="select-photos">Randomize Photos</label>
              </div>
              <div v-if="!widgetData.settings.randomizePhotos" class="description">Photos will show in the order they were selected.</div>
            </div>
          </div>
          <!-- / Filters section -->

          <!-- Filters section -->
          <div class="filters-section filters-spoiler__section filters-spoiler__section--row">
            <div class="field">
              <label class="section-title">Name</label>
              <div class="sub-section-title">This isn't shown, it's just so you can tell lists apart.</div>
              <div class="ui input name-div" style="margin: 8px 0">
                <input v-model="widgetData.name" type="text" placeholder="Name" />
              </div>
              <button style="margin: 8px 0" @click="askToRemoveList" class="ui basic small button right floated">Delete Photo Wall</button>
            </div>
          </div>
          <!-- / Filters section -->
        </div>
        <!-- / Filters spoiler -->
      </details>

      <div class="ui stackable two column grid">
        <div class="column">
          <button class="ui small primary button" @click="save">Save Photo Wall</button>
          <span v-if="saveStatus"
            ><b>{{ saveStatus }}</b></span
          >
        </div>
        <div class="column">
          <button class="ui tiny basic button right floated" @click="collapseAll">{{ allCollapsed ? "Expand" : "Collapse" }} All Adventures</button>
          <button class="ui tiny basic button right floated" @click="askToClearPhotos">Clear Selected Photos</button>
        </div>
      </div>

      <div class="ui stackable one column grid" style="margin-top: 1rem">
        <div class="ui small basic buttons" style="margin-left: 1rem; padding-left: 0; padding-right: 0">
          <button class="ui button" :class="filterType === 'all' && 'primary basic'" @click="setFilter('all')">All Photos</button>
          <button class="ui button" :class="filterType === 'your' && 'primary basic'" @click="setFilter('your')">Your Uploads</button>
          <button v-if="adventurePhotosResults.communityUploadPhotoSummaries.length > 0" @click="setFilter('community')" class="ui button" :class="filterType === 'community' && 'primary basic'">
            Community Uploads
          </button>
          <button class="ui button" :class="filterType === 'adventure' && 'primary'" @click="setFilter('adventure')">Adventure Photos</button>
        </div>

        <!--Your Uploaded Photos (ie. the ones the Customer uploaded)-->
        <div v-if="filterType === 'all' || filterType === 'your'" class="column">
          <div class="ui segment">
            <div class="ui grid">
              <div class="sixteen wide column">
                <div class="main-wrapper">
                  <div class="adventure-name-wrapper" @click="collapseNonAdventureGallery = !collapseNonAdventureGallery" style="cursor: pointer">
                    <div style="margin-bottom: 1.5rem">
                      <h3 style="display: inline; margin-right: 3px">Your Uploaded Photos</h3>
                      <span> - {{ nonAdventurePhotos.length }} photos</span>
                    </div>
                    <i :class="`angle big primary icon ${collapseNonAdventureGallery ? 'down' : 'up'}`" />
                  </div>
                </div>
              </div>

              <div class="sixteen wide column" :style="{ display: collapseNonAdventureGallery ? 'none' : 'block' }">
                <div style="margin: -30px 0 8px 5px">
                  <div v-if="!nonAdventurePhotos || nonAdventurePhotos.length === 0" style="margin-bottom: 10px">Want to add your photos that aren't included in an adventure?</div>
                  <router-link :to="{ name: 'PhotoGalleryAdmin', params: { pageId: customerId } }" class="ui basic tiny right floated button">Manage Uploads</router-link>
                  <UploadPhotosNonAdventure
                    :customerId="customerId"
                    :buttonClasses="
                      'ui small primary button ' + (adventurePhotosResults.adventurePhotoSummaries.length === 0 && (!nonAdventurePhotos || nonAdventurePhotos.length === 0) ? ' orange ' : ' basic ')
                    "
                    @imageAddedToGallery="addImageToNonAdventureGallery"
                  />
                </div>

                <div
                  :class="{
                    'columns-layout': true,
                    'columns-layout--7-col': !['mobile', 'tablet'].includes(screenSize),
                    'columns-layout--4-col': screenSize === 'tablet',
                    'columns-layout--3-col': screenSize === 'mobile',
                  }"
                >
                  <div
                    v-for="(image, index) in nonAdventurePhotos"
                    :key="index"
                    :class="{
                      'photo-div': true,
                      'selected-image': widgetData.photoIds.indexOf(image) !== -1,
                      'columns-layout__item': true,
                    }"
                    style="z-index: 0; cursor: default"
                  >
                    <ExpandImageIconButton
                      galleryPostfix="gallery-your-updated-photos"
                      :fullSizeImgLink="generateImageSrc(image, 'full')"
                      :thumbnailLink="generateImageSrc(image, 'thumb-tiny')"
                      style="position: absolute; inset: 5px 5px auto auto; z-index: 2"
                    />

                    <label>
                      <div class="ui compact segment select-photo" @click="selectImage(image)">
                        <div class="ui fitted checkbox">
                          <input type="checkbox" name="select-individual-photo" :checked="widgetData.photoIds.indexOf(image) !== -1" />
                          <label></label>
                        </div>
                      </div>
                      <template v-if="isVideo(image)">
                        <VideoRender
                          :videoId="image"
                          :customClasses="'photowall-admin-asset'"
                          :showProgressBarOnUpload="true"
                          :showControls="false"
                          :showViewerOnClick="true"
                          style="pointer-events: none"
                        />
                      </template>
                      <template v-else>
                        <!--Note: src is the thumbnail and then the image display library is smart enough to use the href to pull the full res when showing full-screen-->
                        <a lang="en" hreflang="en"><img class="photowall-admin-asset" :src="generateImageSrc(image, 'thumb-med')" style="pointer-events: none" /> </a>
                      </template>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Community Uploads-->
        <details open v-if="adventurePhotosResults.communityUploadPhotoSummaries.length > 0 && (filterType === 'all' || filterType === 'community')" style="width: 100%; margin-top: 20px">
          <summary style="position: relative; z-index: 4; cursor: pointer; user-select: none">
            <h2 style="margin-bottom: 13px; display: inline">
              Community Member Uploaded Photos
              <router-link class="ui basic primary small right floated button" :to="{ name: 'UserUploadedGalleryAdmin', params: { pageId: customerId } }">Manage</router-link>
            </h2>
          </summary>
          <div style="padding-top: 20px">
            <Loader v-if="isLoading" />
            <div v-else v-for="(adventure, adventureIndex) in adventurePhotosResults.communityUploadPhotoSummaries" :key="adventure.creatorEmail" class="ui one column grid">
              <div class="column">
                <div class="ui segment">
                  <div class="ui grid">
                    <div class="sixteen wide column">
                      <div class="main-wrapper">
                        <div class="row">
                          <h3>{{ adventure.creatorDisplayName }}</h3>
                          <div style="display: flex"><i class="grey mail icon"></i> {{ adventure.creatorEmail }}</div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="sixteen wide column"
                      :style="{
                        display: collapsedAdventures.indexOf(adventure.creatorEmail) > -1 ? 'none' : 'block',
                      }"
                    >
                      <div
                        :class="{
                          'columns-layout': true,
                          'columns-layout--7-col': !['mobile', 'tablet'].includes(screenSize),
                          'columns-layout--4-col': screenSize === 'tablet',
                          'columns-layout--3-col': screenSize === 'mobile',
                        }"
                      >
                        <div
                          v-for="(image, index) in adventure.photos"
                          :key="index"
                          :class="{
                            'photo-div': true,
                            'selected-image': widgetData.photoIds.indexOf(image) !== -1,
                            'columns-layout__item': true,
                          }"
                          style="z-index: 0; cursor: default"
                        >
                          <ExpandImageIconButton
                            :galleryPostfix="`gallery-${adventure.creatorEmail + String(adventureIndex)}`"
                            :fullSizeImgLink="generateImageSrc(image, 'full')"
                            :thumbnailLink="generateImageSrc(image, 'thumb-tiny')"
                            style="position: absolute; inset: 5px 5px auto auto; z-index: 2"
                          />
                          <label>
                            <div class="ui compact segment select-photo" @click="selectImage(image)">
                              <div class="ui fitted checkbox">
                                <input type="checkbox" name="select-individual-photo" :checked="widgetData.photoIds.indexOf(image) !== -1" />
                                <label></label>
                              </div>
                            </div>
                            <VideoRender v-if="isVideo(image)" :videoId="image" :customClasses="'photowall-admin-asset'" :showControls="false" :showViewerOnClick="true" style="pointer-events: none" />
                            <!--Note: src is the thumbnail and then the image display library is smart enough to use the href to pull the full res when showing full-screen-->
                            <img v-else class="photowall-admin-asset" :src="generateImageSrc(image, 'thumb-med')" style="pointer-events: none" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </details>

        <!--Adventure Photos-->
        <div v-if="filterType === 'all' || filterType === 'adventure'" style="width: 100%; margin-top: 1rem">
          <h2>Adventure Photos</h2>
          <div v-if="adventurePhotosResults.adventurePhotoSummaries.length === 0" class="column" style="padding-top: 0">No adventure photos yet</div>
          <div v-else v-for="(adventure, adventureIndex) in adventurePhotosResults.adventurePhotoSummaries" :key="adventure.adventureId" class="column">
            <div class="ui segment" :class="{ 'selected-adventure': false }">
              <div class="ui grid">
                <div class="sixteen wide column">
                  <div class="main-wrapper">
                    <div class="adventure-name-wrapper" @click="collapseAdventure(adventure.adventureId)" style="cursor: pointer">
                      <div style="margin-bottom: 1.5rem">
                        <h3 style="display: inline; margin-right: 3px">{{ adventure.adventureName }}</h3>
                        <span> - {{ adventure.photos.length }} photos</span>
                        <router-link class="ui tiny basic button" style="margin-left: 8px" target="_blank" :to="{ name: 'ItineraryPreview', params: { itineraryId: adventure.uniqueName } }"
                          >View</router-link
                        >
                      </div>
                      <i :class="`angle big primary icon ${collapsedAdventures.indexOf(adventure.adventureId) > -1 ? 'down' : 'up'}`" />
                    </div>
                    <div class="creator-info-wrapper" :style="{ display: collapsedAdventures.indexOf(adventure.adventureId) > -1 ? 'none' : '' }">
                      <div class="creator-name-photo-wrapper">
                        <div class="creator-name-photo-wrapper">
                          <router-link target="_blank" :to="{ name: 'CreatorProfilePublic', params: { creatorId: adventure.creatorId } }">
                            <img class="ui circular image" :src="adventure.creatorPhoto" />
                            <span>{{ getCredit(adventure) }}</span>
                          </router-link>
                        </div>
                      </div>
                      <div class="ui checkbox" style="margin-left: auto" @click="selectAdventure(adventure)">
                        <input type="checkbox" id="select-adventure-photos" :checked="adventureIds.indexOf(adventure.adventureId) !== -1" />
                        <label for="select-adventure-photos">Include All</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sixteen wide column" :style="{ display: collapsedAdventures.indexOf(adventure.adventureId) > -1 ? 'none' : 'block' }">
                  <div
                    :class="{
                      'columns-layout': true,
                      'columns-layout--7-col': !['mobile', 'tablet'].includes(screenSize),
                      'columns-layout--4-col': screenSize === 'tablet',
                      'columns-layout--3-col': screenSize === 'mobile',
                    }"
                  >
                    <div
                      v-for="(image, index) in adventure.photos"
                      :key="index"
                      :class="{
                        'photo-div': true,
                        'selected-image': widgetData.photoIds.indexOf(image) !== -1,
                        'columns-layout__item': true,
                      }"
                      style="z-index: 0; cursor: default"
                    >
                      <ExpandImageIconButton
                        :galleryPostfix="`Adventure-Photos-gallery-${adventure.creatorEmail + String(adventureIndex)}`"
                        :fullSizeImgLink="generateImageSrc(image, 'full')"
                        :thumbnailLink="generateImageSrc(image, 'thumb-tiny')"
                        style="position: absolute; inset: 5px 5px auto auto; z-index: 2"
                      />
                      <label>
                        <div class="ui compact segment select-photo" @click="selectImage(image)">
                          <div class="ui fitted checkbox">
                            <input type="checkbox" name="select-individual-photo" :checked="widgetData.photoIds.indexOf(image) !== -1" />
                            <label></label>
                          </div>
                        </div>
                        <VideoRender v-if="isVideo(image)" :videoId="image" :customClasses="'photowall-admin-asset'" :showControls="false" :showViewerOnClick="true" />
                        <!--Note: src is the thumbnail and then the image display library is smart enough to use the href to pull the full res when showing full-screen-->
                        <img v-else class="photowall-admin-asset" :src="generateImageSrc(image, 'thumb-med')" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 style="margin: 15px 0 10px 0" v-if="adventurePhotosResults.totalAdventureCount > 20">
            Showing {{ adventurePhotosResults.adventurePhotoSummaries.length }}<span> of {{ adventurePhotosResults.totalAdventureCount }}</span> Adventures
          </h4>
          <div style="text-align: center; margin-top: 30px">
            <button @click="loadMore" v-if="adventurePhotosResults && adventurePhotosResults.hasMore" class="ui primary button" :disabled="isLoadingMore">Load More</button>
          </div>
        </div>
      </div>

      <!--Delete Modal-->
      <SrpModal v-model:isVisible="showRemoveListModal">
        <template #header><h2 class="global-h2">Delete this photo wall?</h2></template>
        <template #footer>
          <div class="ui basic black button" @click="cancelRemoveList">Cancel</div>
          <div class="ui red button" @click="deleteList">Yep, delete it.</div>
        </template>
      </SrpModal>

      <!--Clear Photos Modal-->
      <SrpModal v-model:isVisible="showClearPhotosModal">
        <template #header><h2 class="global-h2">Are you sure you want to clear all selected photos from this wall?</h2></template>
        <template #footer>
          <div class="ui basic black button" @click="cancelClearPhotos">Cancel</div>
          <div class="ui red button" @click="clearPhotosConfirmed">Yep, remove them.</div>
        </template>
      </SrpModal>

      <!--Save and some info-->
      <div class="hoverSection">
        <div style="text-align: center">{{ selectedPhotoCount }} photos selected</div>
        <div style="margin: 0 5px 0 5px">
          <button v-if="!saveStatus" @click="save" class="ui small primary button" style="margin: 0">Save Photo Wall</button>
          <button v-else class="ui small loading primary button" style="margin: 0">{{ saveStatus }}</button>
          <br />
          <button style="margin-top: 5px" class="ui mini primary basic button" @click="copy">Copy the Code</button>
          <div v-if="justCopied"><b>Copied!</b></div>
        </div>
        <!-- <span v-if="saveStatus"><b>{{saveStatus}}</b></span> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import FileUtils from "@logic/FileUtils";

// Types
import { PhotoWidgetData } from "@contracts/photoWidget";
import { ScreenSize } from "@contracts/screenSize";

// Components
import Loader from "@components/Loader/Loader.vue";
import PhotoWallPreview from "./PhotoWallPreview.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import UploadPhotosNonAdventure from "../CMS/UploadPhotosNonAdventure.vue";
import VideoRender from "@components/VideoRender.vue";
import generateSrc, { ImageSize } from "@helpers/GenerateGallerySrc";
import { useHead } from "@unhead/vue";
import SrpButton from "@components/ui/SrpButton.vue";
import ExpandImageIconButton from "@components/ui/ExpandImageIconButton.vue";

export default defineComponent({
  name: "PhotoWallAdmin",

  components: {
    ExpandImageIconButton,
    SrpButton,
    Loader,
    PhotoWallPreview,
    SrpModal,
    UploadPhotosNonAdventure,
    VideoRender,
  },

  data() {
    return {
      screenSize: inject("screenSize") as ScreenSize,

      title: "Photo Wall",
      customerId: null as string | null,
      widgetId: null as string | null,
      isLoading: true,
      isLoadingMore: false,
      saveStatus: null,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      widgetData: null as PhotoWidgetData | null,
      // Those adventures that Select all have been clicked on (this is not persisted)
      adventureIds: [] as string[],
      adventurePhotosResults: null,
      maxResults: 20,
      nonAdventurePhotos: [] as string[],
      collapseNonAdventureGallery: false,
      collapsedAdventures: [],
      previewCollapsed: false,
      justCopied: false,
      allCollapsed: false,
      showRemoveListModal: false,
      showClearPhotosModal: false,
      isEditPreview: false,
      filterType: "all",
    };
  },

  computed: {
    selectedPhotoCount(): number {
      return this.widgetData?.photoIds?.length || 0;
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.customerId = this.$route.params.pageId;
    // @ts-ignore
    this.widgetId = this.$route.params.photoWallId;
    this.getAllData();
  },

  methods: {
    async getAllData() {
      const widgetDataResult = await axios.get(`${import.meta.env.VITE_API_URL}/photo-widget-admin/${this.customerId}/widgets/${this.widgetId}`);
      this.widgetData = widgetDataResult.data;
      // Using the existing endpoint to get all the photos data also...
      await this.getPhotoData();
      // Not awaiting this one
      this.getNonAdventurePhotoData();
      // this.previewCollapsed = this.widgetData.photoIds.length === 0;
      this.isLoading = false;
      this.title += ` - ${this.widgetData.name}`;
    },
    async getPhotoData() {
      const photoResult = await axios.get(`${import.meta.env.VITE_API_URL}/photos/${this.customerId}/byAdventure?resultCount=${this.maxResults}`);
      this.adventurePhotosResults = photoResult.data;
    },
    async getNonAdventurePhotoData() {
      const photoResult = await axios.get(`${import.meta.env.VITE_API_URL}/photo-gallery/customer/${this.customerId}`);
      this.nonAdventurePhotos = photoResult.data.photoIds;
    },
    async save() {
      this.saveStatus = "Saving...";
      const config = { headers: { "Content-Type": "application/json" } };
      await axios.put(`${import.meta.env.VITE_API_URL}/photo-widget-admin/${this.customerId}/widgets/${this.widgetId}`, JSON.stringify(this.widgetData), config);
      this.saveStatus = "Saved!";
      setTimeout(() => {
        this.saveStatus = null;
      }, 3000);
    },
    async loadMore() {
      this.isLoadingMore = true;
      this.maxResults = this.maxResults + 20;
      await this.getPhotoData();
      this.isLoadingMore = false;
    },
    addImageToNonAdventureGallery(imageId: string) {
      // Add it to the start
      this.nonAdventurePhotos.unshift(imageId);
    },
    getCredit(adventure) {
      // return adventure.creatorCredit ?? adventure.creatorDisplayName;
      return adventure.creatorDisplayName;
    },
    setRowsToShow(change: number) {
      this.widgetData.settings.rowsToShow += change;
    },
    selectAdventure(adventure: any) {
      const adventureIndex = this.adventureIds.indexOf(adventure.adventureId);
      let shouldAddAll = false;
      if (adventureIndex > -1) {
        this.adventureIds.splice(adventureIndex, 1);
      } else {
        shouldAddAll = true;
        this.adventureIds.push(adventure.adventureId);
      }
      // Now select/deselect all the photos
      adventure.photos.forEach(photoId => {
        const photoIndex = this.widgetData.photoIds.indexOf(photoId);
        if (!shouldAddAll && photoIndex > -1) {
          // Remove it
          this.widgetData.photoIds.splice(photoIndex, 1);
        }
        if (shouldAddAll && photoIndex < 0) {
          // Add it
          this.widgetData.photoIds.push(photoId);
        }
      });
    },
    selectImage(image: string) {
      const index = this.widgetData.photoIds.indexOf(image);
      if (index > -1) {
        this.widgetData.photoIds.splice(index, 1);
      } else {
        // Add it as the first image
        this.widgetData.photoIds.unshift(image);
      }
    },
    removeImage(imageId: string): void {
      const index = this.widgetData.photoIds.indexOf(imageId);
      if (index > -1) {
        this.widgetData.photoIds.splice(index, 1);
      }
    },
    changeImageOrder(imageIndex: number, positionShift: number) {
      let toIndex = imageIndex + positionShift;
      if (toIndex < 0) toIndex = this.widgetData.photoIds.length - 1;
      if (toIndex >= this.widgetData.photoIds.length) toIndex = 0;
      this.widgetData.photoIds.splice(toIndex, 0, this.widgetData.photoIds.splice(imageIndex, 1)[0]);
    },
    collapseAll() {
      this.allCollapsed = !this.allCollapsed;
      if (this.allCollapsed) {
        this.collapsedAdventures = this.adventurePhotosResults.adventurePhotoSummaries.map(a => a.adventureId);
      } else {
        this.collapsedAdventures = [];
      }
    },
    collapseAdventure(adventureId: string) {
      const index = this.collapsedAdventures.indexOf(adventureId);
      if (index > -1) {
        this.collapsedAdventures.splice(index, 1);
      } else {
        this.collapsedAdventures.push(adventureId);
      }
    },
    async copy(): Promise<void> {
      // NOTE: Need to add the \ in the final script tag or vue gets confused
      // eslint-disable-next-line no-useless-escape
      const code = `<script id="shrpa-go__${this.customerId}--${this.widgetId}" src="https://shrpa.com/widget/photos.js" async><\/script>`;
      await navigator.clipboard.writeText(code);

      this.justCopied = true;
      setTimeout(() => {
        this.justCopied = false;
      }, 3000);
    },
    askToRemoveList() {
      this.showRemoveListModal = true;
    },
    cancelRemoveList() {
      this.showRemoveListModal = false;
    },
    async deleteList() {
      await axios.delete(`${import.meta.env.VITE_API_URL}/photo-widget-admin/${this.customerId}/widgets/${this.widgetId}`);
      this.back();
    },
    askToClearPhotos() {
      this.showClearPhotosModal = true;
    },
    cancelClearPhotos() {
      this.showClearPhotosModal = false;
    },
    clearPhotosConfirmed() {
      this.widgetData.photoIds.splice(0);
      this.showClearPhotosModal = false;
    },
    back() {
      this.$router.push({ name: "WidgetDirections", params: { pageId: this.customerId } });
    },
    generateImageSrc(imageName: string, size: ImageSize): string {
      return generateSrc(imageName, size);
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
    setFilter(type) {
      this.filterType = type;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/screen-size-ranges.scss";

.customize-section {
  margin-top: 1rem;
}

.photo-div {
  position: relative;

  //Note: Moved a style into main.scss
}

.select-photo {
  position: absolute;
  bottom: 18px;
  right: 10px;
  cursor: pointer;
  z-index: 30;
  padding: 4px 4px 0px 4px !important;
  margin-bottom: 0 !important;
}

.section-title {
  margin-bottom: 0.5rem !important;
  font-weight: 500;
  color: $brand-color;
  font-size: 1.1rem;
}

.sub-section-title {
  margin: 1rem 0 0.5rem 0;
  font-size: 14px;
}

.name-div {
  width: 100%;
  max-width: 500px;
}

.select-photos {
  margin-bottom: 1rem !important;
  cursor: pointer !important;
}

.ui.three.cards {
  @media screen and (max-width: 767px) {
    margin-top: 1rem;
    margin-left: 0;
  }
}

img {
  cursor: pointer;
}

.selected-image {
  outline: 4px solid $brand-color !important;
  border-radius: 5px;

  img {
  }
}

.selected-adventure {
  background-color: $brand-color-lighter;
}

.padding-section {
  margin-bottom: 3rem;
  input.slider.custom-slider {
    margin-top: 0.75rem;
  }
  i.icon {
    margin-right: 20px;
  }
}

.main-wrapper {
  .adventure-name-wrapper {
    justify-content: space-between;
    display: flex;
    .ui.checkbox {
      margin-left: 0.5rem;
      flex-shrink: 0;
    }
  }
  .creator-info-wrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex-flow: wrap;
    }
    .creator-name-photo-wrapper {
      display: flex;
      margin-right: 1.5rem;
      align-items: center;
      img {
        height: 50px;
        width: 50px;
        margin-right: 0.5rem;
        flex-shrink: 0;
        display: inline;
      }
    }
  }
}

.hoverSection {
  position: fixed;
  bottom: 120px; /*Remember to keep this high enough for the bottom nav on mobile*/
  right: 20px;
  z-index: 99;
  box-shadow:
    rgb(0 0 0 / 10%) 0px 1px 6px,
    rgb(0 0 0 / 20%) 0px 2px 24px !important;
  padding: 8px;
  border-radius: 4px;
  background-color: white;
  button {
    margin-right: 0.5rem;
  }
  span {
    color: $gray;
  }
}

.number-of-rows {
  padding: 0.78571429em 0.78571429em 0.78571429em;
  font-size: 15px;
  line-height: 1em;
}

.field.checkbox-field {
  padding: 8px !important;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  cursor: pointer;
  margin-bottom: 1rem;
  font-weight: 500;
  .description {
    font-weight: 300;
  }
}

.ui.stackable.divided:not(.vertically).grid > .column:not(.row) {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

// Filters section ============================================================
.filters-section {
}

// Filters spoiler ============================================================
.filters-spoiler {
  padding-top: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__section {
    box-sizing: border-box;

    &--column {
      width: calc(25% - 25px);
      position: relative;

      &::before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        inset: auto auto auto -16px;
        background: rgba(0, 0, 0, 0.1);
      }

      &:first-child::before {
        display: none;
      }
    }

    &--row {
      width: 100%;
      padding-top: 16px;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .filters-spoiler {
    &__section {
      &--column {
        width: calc(50% - 13px);
        margin-bottom: 26px;

        &::before {
          left: -13px;
        }

        &:nth-child(3)::before {
          display: none;
        }
      }

      &--row {
        padding-top: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .filters-spoiler {
    &__section {
      &--column {
        width: 100%;
        margin-bottom: 36px;

        &::before {
          width: 100%;
          height: 1px;
          inset: -16px auto auto 0;
        }
      }

      &--row {
        padding-top: 0;
        position: relative;

        &::before {
          content: "";
          width: 100%;
          height: 1px;
          position: absolute;
          inset: -16px auto auto 0;
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
