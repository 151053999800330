<template>
  <div>
    <Loader v-if="isLoading" style="position: absolute"></Loader>
    <div v-else class="ui form">
      <!--<router-link :to="{name: 'CommunityAdminDashboard'}" lang="en" hreflang="en" class="ui small basic button right floated">Back to Dashboard</router-link>-->
      <div>
        <h1 style="display: inline; margin-right: 25px">Invite Community Members to Upload Photos and Videos!</h1>
      </div>
      <p>
        The best way to experience your community is through the eyes of those who love it most!
        <br />Feature photos and videos from members of your community by sending them an easy upload link.
      </p>

      <div class="ui stackable grid" style="margin-top: 3em">
        <div class="ten wide column">
          <template v-if="campaignData && campaignData.uploadedPhotoCount > 0">
            <div>
              <h3>View your uploaded photos and videos!</h3>
            </div>
            <div style="margin-top: 0.5em">
              You've got {{ campaignData.uploadedPhotoCount }} uploaded photos and videos.
              <router-link
                class="ui primary tiny button"
                lang="en"
                hreflang="en"
                :to="{ name: 'CommunityPhotosV2', params: { pageId: pageId }, hash: '#justCommunityUploads=true' }"
                style="margin-left: 8px"
                >View Uploads</router-link
              >
            </div>
          </template>

          <label class="cms-section-title">Upload Links</label>
          <p>
            These links allows community members to easily upload photos that you can use in your marketing!
            <br />
            Share this link with community members via email, your website, or social media.
          </p>
          <table v-if="unarchivedCampaigns.length > 0" class="ui very basic table">
            <tbody>
              <tr v-for="(campaign, index) in unarchivedCampaigns" :key="campaign.id">
                <td>
                  <LinkWithIcon isDottedUnderline @click="edit(campaign, index)">
                    <IconEmbedded name="edit-pencil_2" :size="21" />
                    <span
                      ><b>{{ campaign.title }}</b></span
                    >
                  </LinkWithIcon>
                </td>
                <td>
                  <button class="ui primary basic tiny button" @click="copy(campaign.uriKey || campaign.campaignId)">Copy Link</button>
                  <button class="ui grey basic tiny button" @click="askToArchive(campaign)">Archive</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="justCopied" style="float: right"><b>Link Copied to Clipboard</b></div>
          <div>
            <button class="ui primary tiny button" @click="askToCreate()">New Upload Link</button>
          </div>

          <div v-if="archivedCampaigns.length > 0">
            <label class="cms-section-title" style="margin-top: 40px !important">Archived Links</label>
            <table class="ui very basic table">
              <thead>
                <tr>
                  <th>Upload Links</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="campaign in archivedCampaigns" :key="campaign.id">
                  <td>{{ campaign.title }}</td>
                  <td>
                    <button class="ui grey basic mini button" @click="unarchive(campaign)">Unarchive</button>
                    <button class="ui basic tiny button" @click="askToSoftDelete(campaign)"><i class="icon trash"></i>Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="six wide column">
          <img class="ui small image" style="width: 100%" src="https://cdn.shrpa.com/images/misc/integrations/CommunityPhotoUpload-med.jpg" />
        </div>
      </div>
    </div>

    <!--Edit Modal-->
    <SrpModal v-if="campaignToSave" v-model:isVisible="showEditModal" :isClosable="false">
      <template #header>
        <h2 class="global-h2">{{ campaignToSave.campaignId ? "Update the Upload Link" : "Create an Upload Link" }}</h2>
      </template>
      <template #content>
        <div class="ui form">
          <div class="field">
            <label class="subsection-title">Title</label>
            <p class="cms">Use a theme or category you want to highlight!</p>
            <div class="ui input">
              <input type="text" v-model="campaignToSave.title" placeholder="Ex. Fall colors, or Holiday lights, or Summer fun" />
            </div>
          </div>
          <div class="field" style="margin-bottom: 20px">
            <label class="subsection-title">Description</label>
            <div class="ui fluid input">
              <textarea type="text" v-model="campaignToSave.description" style="height: 80px; min-height: 20px"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="subsection-title">Photo Inspiration (optional) </label>
            <div class="ui fluid input">
              <UploadPhotoForm
                style="width: 100%; min-height: 150px; max-height: 360px; margin-bottom: 20px"
                :img="campaignToSave.exampleMediaIds?.map(i => ({ serverId: i })) || []"
                @imageUploadedToServer="
                  image => {
                    if (!campaignToSave.exampleMediaIds) {
                      campaignToSave.exampleMediaIds = [];
                    }
                    campaignToSave.exampleMediaIds.push(image.serverId);
                  }
                "
                @removeMediaFile="imgId => (campaignToSave.exampleMediaIds = campaignToSave.exampleMediaIds.filter(i => i !== imgId))"
                :columnsNumber="{ mobile: 3, tablet: 5, 'tablet-large': 6, laptop: 6, desktop: 6, 'desktop-wide': 6 }[screenSize]"
                showRemove
              />
            </div>
          </div>
          <div v-if="validationError" class="validation-errors">
            <div>{{ validationError }}</div>
          </div>

          <div class="field">
            <label class="subsection-title">Upload Link to Share</label>

            <div v-if="!isEditingExistingCampaign && Boolean(campaignToSave.campaignId)" style="display: flex">
              <b>{{ getUploadLink(campaignToSave.uriKey || campaignToSave.campaignId) }}</b>
              <LinkWithIcon v-if="campaignToSave.campaignId && !isEditingExistingCampaign" color="blue" isDottedUnderline @click="isEditingExistingCampaign = true" style="margin-left: 7px">
                <IconEmbedded name="edit-pencil_2" :size="21" />
                <span>Edit</span>
              </LinkWithIcon>
            </div>
            <template v-else>
              <div v-if="isNewUriKeyAlreadyTaken" style="font-weight: bold; color: firebrick">This url is already taken by another campaign</div>
              <label class="input-with-prefix">
                <span class="input-with-prefix__prefix-overlay">
                  {{
                    getUploadLink(campaignToSave.uriKey || campaignToSave.campaignId)
                      .split("/")
                      .slice(0, -1)
                      .join("/") + "/"
                  }}
                </span>
                <input class="input-with-prefix__input" type="text" v-model="campaignToSaveUriKey" style="max-width: 600px" ref="domRefUriKeyInput" />
                <div class="global-text-input input-with-prefix__input-bg"></div>
              </label>
            </template>

            <NoteWithIcon v-if="isEditingExistingCampaign" style="margin-top: 7px">
              <template #text>
                Changing this will break any previous links you've posted.<br />
                Make sure to change this before you post or update any links you've posted.
              </template>
            </NoteWithIcon>
          </div>
        </div>
      </template>
      <template #footer>
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
          <div>
            <SrpButton :isDisabled="!campaignToSave.uriKey || isNewUriKeyAlreadyTaken" @click="copy(campaignToSave.uriKey)">Copy Link</SrpButton>
            <div style="font-weight: bold" v-if="justCopied">Copied to clipboard</div>
          </div>

          <div style="display: flex; gap: 7px">
            <SrpButton fill="outlined" color="black" @click="cancelCreate()">Cancel</SrpButton>
            <SrpButton fill="outlined" color="black" :isDisabled="isSaving || !campaignToSave.uriKey || isNewUriKeyAlreadyTaken" @click="saveCampaign(true, true)">Preview</SrpButton>
            <SrpButton :isDisabled="isSaving || !campaignToSave.uriKey || isNewUriKeyAlreadyTaken" @click="saveCampaign(true, false)">{{ isSaving ? "Saving..." : "Save" }}</SrpButton>
          </div>
        </div>
      </template>
    </SrpModal>

    <!--Archive Modal-->
    <SrpModal v-model:isVisible="showArchiveModal">
      <template #header><h2 class="global-h2">Ready to archive this?</h2></template>
      <template #content>
        <div>
          <b>Users will no longer be able to upload with this link.</b><br />
          Note: Any content uploaded via this link won't be removed.
        </div>
      </template>
      <template #footer>
        <h3 v-if="isSaving" style="display: inline">Archiving...</h3>
        <div class="ui basic black button" @click="showArchiveModal = false">Cancel</div>
        <button class="ui grey button" :disabled="isSaving" @click="archiveConfirmed()">Yep, archive it.</button>
      </template>
    </SrpModal>

    <!--Delete Modal-->
    <SrpModal v-model:isVisible="showDeleteModal">
      <template #header>
        <h2 class="global-h2">Are you sure you want to delete this?</h2>
      </template>
      <template #content>
        <div>
          <b>This link will be gone for good.</b><br />
          Note: Any content uploaded via this link won't be removed.
        </div>
      </template>
      <template #footer>
        <h3 v-if="isSaving" style="display: inline">Deleting...</h3>
        <div class="ui basic black button" @click="showDeleteModal = false">Cancel</div>
        <button class="ui red button" :disabled="isSaving" @click="deleteConfirmed()">Yep, delete it.</button>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import OrgContext from "@logic/OrgContext";
import { PartnerNavInfo } from "@contracts/partnerNavInfo";
import CopyText from "@components/CopyText.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useUserProfileStore } from "@stores/userProfileStore";
import { mapState } from "pinia";
import { useHead } from "@unhead/vue";
import UploadPhotoForm from "@views/CMS/UploadPhotoForm.vue";
import { ScreenSize } from "@contracts/screenSize";
import { UserUploadCampaigns, UserContentUploadCampaignData } from "@contracts/userContentUploadCampaignData";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";

export default defineComponent({
  name: "UserUploadCampaigns",

  components: {
    IconEmbedded,
    LinkWithIcon,
    UploadPhotoForm,
    SrpButton,
    SrpModal,
    Loader,
    CopyText,
    NoteWithIcon,
  },

  data() {
    return {
      screenSize: inject("screenSize") as ScreenSize,
      title: "Community Uploads",
      pageId: null,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      orgInContext: null as PartnerNavInfo,
      // Campaign Data
      campaignData: null as UserUploadCampaigns,
      justCopied: false,
      isLoading: true,

      showEditModal: false,
      campaignToSave: null as UserContentUploadCampaignData, // The campaign being edited/created
      campaignBeforeEdit: null, // In case they hit cancel
      campaignEditIndex: -1,
      isSaving: false,
      validationError: null,

      showDeleteModal: false,
      showArchiveModal: false,

      exampleMediaIds: [],

      isEditingExistingCampaign: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
    unarchivedCampaigns(): Array<UserContentUploadCampaignData> {
      return this.campaignData.campaigns.filter(c => !c.expiryDateTime);
    },
    archivedCampaigns(): Array<UserContentUploadCampaignData> {
      return this.campaignData.campaigns.filter(c => c.expiryDateTime);
    },
    isNew(): boolean {
      return this.campaignToSave.campaignId == null; // Using the abstract equality check here so we catch undefined also
    },
    campaignToSaveUriKey: {
      get() {
        return this.campaignToSave.uriKey;
      },
      async set(newValue) {
        this.campaignToSave.uriKey = newValue;
        await this.$nextTick(); // waits for the text input to re-render, otherwise, the restricted symbols will appear in the text input
        this.campaignToSave.uriKey = newValue.replace(/[^a-zA-Z0-9-_]/g, "");
      },
    },
    isNewUriKeyAlreadyTaken(): boolean {
      if (!this.showEditModal) return false;

      const allUriKeysExceptCurrent = this.campaignData.campaigns.filter(c => c.uriKey !== null && c.campaignId !== this.campaignToSave.campaignId).map(c => c.uriKey);

      return allUriKeysExceptCurrent.includes(this.campaignToSaveUriKey);
    },
  },

  watch: {
    showEditModal() {
      this.isEditingExistingCampaign = false;
    },
    async isEditingExistingCampaign() {
      if (this.showEditModal) {
        await this.$nextTick();
        this.$refs.domRefUriKeyInput.focus();
      }
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.pageId = this.$route.params.pageId;
    this.orgInContext = OrgContext.getOrgInContext(this);
    this.title += " - " + this.orgInContext?.name;
    this.loadData();
  },

  methods: {
    async copy(uriKeyOrId: string) {
      let link = this.getUploadLink(uriKeyOrId);
      await navigator.clipboard.writeText(link);

      this.justCopied = true;
      setTimeout(() => {
        this.justCopied = false;
      }, 5000);
    },
    async loadData() {
      this.isLoading = true;
      const campaigns = await axios.get(`${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.pageId}`);
      this.campaignData = campaigns.data;

      this.isLoading = false;
    },
    askToCreate() {
      this.campaignToSave = {
        title: null,
        description: null,
      };
      if (this.isNew) {
        this.campaignToSave.uriKey = String(this.campaignData.campaigns.length + 1);
      }
      this.campaignBeforeEdit = null;
      this.campaignEditIndex = -1;
      this.showEditModal = true;
    },
    cancelCreate() {
      if (this.campaignEditIndex >= 0) this.campaignData.campaigns[this.campaignEditIndex] = this.campaignBeforeEdit;
      this.showEditModal = false;
    },
    edit(campaign, index) {
      this.campaignToSave = campaign;
      // Shallow clone
      this.campaignBeforeEdit = { ...campaign };
      this.campaignEditIndex = index;
      this.showEditModal = true;
    },
    validate(): boolean {
      this.validationError = null;
      if (this.campaignToSave?.title == null || this.campaignToSave.title.trim().length === 0 || this.campaignToSave?.description == null || this.campaignToSave.description.trim().length === 0) {
        this.validationError = "Add a Title and Description";
        return false;
      }
      return true;
    },
    async saveCampaign(validate: boolean, preview = false) {
      if (validate && !this.validate()) return;

      this.isSaving = true;
      const config = { headers: { "Content-Type": "application/json" } };
      let result = await axios.put(`${import.meta.env.VITE_API_URL}/useruploadcampaigns/${this.pageId}/campaign`, JSON.stringify(this.campaignToSave), config);
      if (this.isNew) this.campaignData.campaigns.push(result.data);
      this.isSaving = false;

      if (preview) {
        // Preview leaves the modal open so we need to set the state based on what just saved
        this.edit(result.data, this.campaignEditIndex);
        // Open the url in a new window
        const url = this.getUploadLink(this.campaignToSave.uriKey || this.campaignToSave.campaignId);
        window.open(url, "_blank");
      } else {
        this.showEditModal = false;
      }
    },
    getUploadLink(uriKeyOrId: string): string {
      return `${window.location.protocol}//${window.location.host}/upload/${this.pageId}/${uriKeyOrId}`;
    },
    askToArchive(campaign) {
      this.campaignToSave = campaign;
      this.showArchiveModal = true;
    },
    async archiveConfirmed() {
      this.isSaving = true;
      this.campaignToSave.expiryDateTime = new Date().toISOString();
      await this.saveCampaign(false);
      this.showArchiveModal = false;
      this.isSaving = false;
    },
    async unarchive(campaign) {
      this.isSaving = true;
      this.campaignToSave = campaign;
      this.campaignToSave.expiryDateTime = null;
      await this.saveCampaign(false);
      this.isSaving = false;
    },
    askToSoftDelete(campaign) {
      this.campaignToSave = campaign;
      this.campaignEditIndex = this.campaignData.campaigns.indexOf(campaign);
      this.showDeleteModal = true;
    },
    async deleteConfirmed() {
      this.isSaving = true;
      this.campaignToSave.deletedDateTime = new Date().toISOString();
      await this.saveCampaign(false);
      this.showDeleteModal = false;
      this.isSaving = false;
      this.campaignData.campaigns.splice(this.campaignEditIndex, 1);
    },
  },
});
</script>

<style scoped lang="scss">
.validation-errors {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}
.limit-hit-message {
  margin-top: 10px;
}

// Input with prefix ==========================================================
.input-with-prefix {
  height: 41px;
  display: flex !important;
  cursor: text;
  position: relative;
  z-index: 0;

  &__prefix-overlay {
    height: 100%;
    padding: 0 0 3px 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    font:
      14px/14px "Quicksand",
      sans-serif;
    user-select: none;
    pointer-events: none;
  }

  &__input {
    padding: 0 0 3px 0 !important;
    border: none !important;
    background: none !important;
  }

  &__input-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
  }

  &__input:focus + &__input-bg {
    border-color: #4fa2a3;
    outline: 1px #4fa2a3 solid;
  }
}
</style>
