<template>
  <component
    :is="disableTopLevelRouterLink ? 'span' : 'router-link'"
    :to="buildLink(isDraft())"
    :target="openNewWindow ? '_blank' : '_self'"
    @click="itineraryClick"
    :class="{
      'ui card wow fadeIn': true,
      'itinerary-tile': true,
    }"
    style="visibility: visible; cursor: pointer"
    :style="styles"
  >
    <div :class="`itinerary-tile__image-wrap image ${allowShortImage ? 'short-image' : ''}`">
      <span v-if="itinerary.secondaryStatus === 'CollabReview'" :class="`ui tiny ribbon label ${itinerary.status}`"> Shrpa Review </span>
      <span v-else-if="itinerary.secondaryStatus === 'CollabCommunityReview'" :class="`ui tiny ribbon label ${itinerary.status}`"> Destination Review </span>
      <span v-else-if="isCms" :class="`ui tiny ribbon label ${itinerary.status}`"> {{ itinerary.secondaryStatus === "PendingReview" ? "Shrpa Review!" : itinerary.status }} </span>
      <!-- Pencil icon -->
      <div v-if="isCms" class="pencil-icon itinerary-tile__pencil-icon">
        <IconEmbedded class="pencil-icon__icon-itself" name="edit-pencil_2-5" />
      </div>
      <!-- / Pencil icon -->
      <img :src="`${contentBaseUri}/cms/images/expeditions/${coverImageWithFallback}`" />
      <!--<div v-if="itinerary.isNew && showIsNew && !isCms" class="new">New!</div>-->
    </div>
    <div v-if="!hideDetails" class="content">
      <label v-if="Object.keys(this.$slots).includes('inputRadio')" class="content__input-label" @click.stop>
        <slot name="inputRadio" />
        <div class="content__border-overlay"></div>
      </label>
      <div class="content__text-wrap">
        <div class="global-h5 header truncate">{{ titleWithFallback }}</div>
        <div class="meta" v-if="!isCms && !customButtonText && !hideFooter">
          <span class="city" style="margin: 0px">{{ itinerary.location }}</span>
          <div class="right floated timeframe">{{ itinerary.timeRequired }}</div>
        </div>

        <!--CMS Buttons-->
        <div v-if="isCms" style="margin-top: 4em">
          <div class="ui relaxed grid" style="position: absolute; bottom: 1em">
            <div class="seven wide column">
              <button @click.stop.prevent="goToViewLink(isDraft())" class="ui compact menu" id="share-dropdown">
                <div class="ui simple dropdown item" style="padding: 0.5em 0.7em">
                  View
                  <template v-if="hasPublished() && isDraft()">
                    <i class="dropdown icon"></i>
                    <div class="menu">
                      <button @click.stop.prevent="goToViewLink(true)" class="item">Draft</button>
                      <button @click.stop.prevent="goToViewLink(false)" class="item">Published</button>
                    </div>
                  </template>
                </div>
              </button>
            </div>
            <div class="eight wide column">
              <button v-if="hasPublished() && hideShareButton === false" @click.stop.prevent="$emit('shareItinerary', itinerary.id, itinerary.title)" class="ui primary small button share-button">
                Share
              </button>
            </div>
          </div>
        </div>
        <!--Custom Button-->
        <div v-if="customButtonText" style="margin-top: 4em">
          <div class="ui relaxed grid" style="position: absolute; bottom: 1em">
            <div class="sixteen wide column">
              <button class="ui primary button" style="padding: 0.5em 0.7em" @click.stop="customButtonClick()">
                {{ customButtonText }}
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="showRemoveButton"
          class="ui mini button icon"
          style="position: absolute; top: -8px; right: -10px; border-radius: 50%; background-color: black; padding: 0.4rem"
          @click.stop="$emit('removeItinerary', itinerary.id)"
        >
          <i class="x icon" style="color: white"></i>
        </div>

        <slot name="buttons" :goToViewLink="goToViewLink" />
      </div>
    </div>
  </component>
</template>

<script>
import FeatureFlags from "@logic/FeatureFlags";
import ItineraryHelper from "@helpers/ItineraryHelper";
import { MetricSender } from "@helpers/MetricSender";
import { inject } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { useUserProfileStore } from "@stores/userProfileStore";
import { mapState } from "pinia";

/* REMINDER: There's a V2 version of this that's used a few places.
   - This V1 component is used in a lot more places than V2 (should consider renaming them)
*/

export default {
  name: "ItineraryTile",
  components: { IconEmbedded },

  props: {
    itinerary: { type: Object, required: true },
    // Currently the way pages are styled ByTheme allows the adventure image to shrink vertically,
    // but the Search page does not since it allows them to go full width.  Long-term just combine the pages.
    allowShortImage: { type: Boolean, required: true },
    showRemoveButton: { type: Boolean },
    isCms: { type: Boolean },
    // Allows us to make the main click of the image emit the customButtonClick event.
    // Often we'll add a customButtonText so they can view the adventure
    invertClickForView: { type: Boolean, required: false, default: false },
    // If we want to show a custom button on the tile
    customButtonText: { type: String, required: false, default: "" },
    hideFooter: { type: Boolean, required: false, default: false },
    showIsNew: { type: Boolean, required: false, default: true },
    openNewWindow: { type: Boolean },
    // Used when re-ordering lists
    disableLink: { type: Boolean },
    styles: { type: String, required: false, default: "" },
    // context to send along to metrics (so we can tell where this impression came from)
    source: { type: String, required: false, default: "notset" },
    hideDetails: { type: Boolean, required: false, default: false },
    hideShareButton: { type: Boolean, required: false, default: false },
  },

  emits: ["customButtonClick", "shareItinerary", "removeItinerary"],

  data() {
    return {
      globalLog: inject("globalLog"),

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "isSuperOrSalesUser", "isImpersonating"]),
    disableTopLevelRouterLink() {
      // NOTE!!! We put the top-level router link so it actually renders as a link (and the browser knows it so ctrl+click works, etc...)
      // BUT there is some logic in itineraryClick() that we don't handle so suppress that top-level link in those cases.
      // For any of these conditions let the click handle it.
      // NOTE: This whole component has gotten fairly complex (caused some issues in the Vue3 upgrade), may want to refactor or split up.
      return Boolean(this.disableLink || this.invertClickForView);
    },
    coverImageWithFallback() {
      return this.itinerary?.thumbnail?.toLowerCase() !== "thumbnails/" ? this.itinerary.thumbnail : "thumbnails/placeholder-image.jpg";
    },
    titleWithFallback() {
      return this.itinerary?.title || "New Adventure";
    },
  },

  mounted() {
    // Fire the impression metric
    MetricSender.impressionAdventure(this.itinerary.id, this.itinerary.uniqueName, this.source);
  },

  methods: {
    itineraryClick() {
      if (this.disableLink) {
        return;
      }
      // If they inverted the click then emit and let the caller decide
      if (this.invertClickForView) {
        this.$emit("customButtonClick", this.itinerary);
        return;
      }
    },
    goToViewLink(isDraft, isTargetBlank) {
      const _isDraft = typeof isDraft === "boolean" ? isDraft : ItineraryHelper.isDraft(this.itinerary.status);

      this.globalLog.info("CMS: GoToViewLink " + this.itinerary?.id);

      if (isTargetBlank) {
        const routeData = this.$router.resolve(this.getViewLink(_isDraft));
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push(this.getViewLink(_isDraft));
      }
    },
    getViewLink(isDraft) {
      const queryParams = {};
      if (isDraft) {
        queryParams.status = this.itinerary.status;
      }
      // Use the Id if a Draft (since uniqueName can change), otherwise use the more friendly name
      const id = this.itinerary.id,
        uniqueName = this.itinerary.uniqueName;
      var idToUse = (isDraft ? id : uniqueName) ?? id;
      return { name: "ItineraryPreview", params: { itineraryId: idToUse }, query: queryParams };
    },
    buildLink(isDraft) {
      if (this.isCms) {
        let route = { name: "CmsEditorV2", query: { id: this.itinerary.id } };
        if (this.isImpersonating) {
          route.query.creatorId = this.getViewingUserProfile?.sherpaId;
        }
        return route;
      } else {
        return this.getViewLink(isDraft);
      }
    },
    customButtonClick() {
      if (this.invertClickForView) {
        // Currently assume this is to view the adventure (could let the caller decide...)
        let route = this.buildLink(false);
        if (this.openNewWindow) {
          let routeData = this.$router.resolve(route);
          window.open(routeData.href, "_blank");
        } else {
          this.$router.push(route);
        }
      } else {
        this.$emit("customButtonClick", this.itinerary);
      }
    },
    hasPublished() {
      return this.itinerary.publishedDateTime != null;
    },
    isDraft() {
      return ItineraryHelper.isDraft(this.itinerary.status);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

.share-button {
  margin-top: -4px;
  padding-top: 1em;
  padding-bottom: 1em;
  @media screen and (max-width: 420px) {
    padding: 1em;
  }
}

.ui.card.wow.fadeIn .content .header.truncate {
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  height: 100%;
}

.content {
  display: flex;

  &__input-label {
    width: 31px;
    min-width: 31px;
    height: 31px;
    margin: -4px 8px 0 0 !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.09);
    cursor: pointer;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0 auto auto 0;
    }

    :deep(input) {
      cursor: pointer;
    }
  }

  &__border-overlay {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 5px #118689 solid;
    border-radius: 5px;
    position: absolute;
    inset: 0 auto auto 0;
    opacity: 0;
    pointer-events: none;
  }

  :deep(input[type="radio"]:checked) + &__border-overlay {
    opacity: 1;
  }

  &__text-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .content {
    padding-top: 6px !important;
    flex-direction: column;

    &__input-label {
      margin: 0 0 5px 0 !important;
    }
  }
}

// Pencil icon ================================================================
.pencil-icon {
  width: 1px;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon-itself {
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    //backdrop-filter: blur(5px); // Caused issues when lots of photos

    :deep(svg) {
      width: 24px;
      fill: #444;
    }
  }
}

// Itinerary tile =============================================================
.itinerary-tile {
  position: relative;

  &__image-wrap {
    position: relative;
  }

  &__pencil-icon {
    position: absolute;
    inset: 35px auto auto 35px;
    transform: scale(1);
    transition: transform 0.07s ease-in-out;
    transform-origin: center center;
  }

  &:hover &__pencil-icon {
    transform: scale(1.2);
  }
}
</style>
