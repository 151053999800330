<template>
  <div class="photo-wall-page">
    <Loader v-if="isLoading" style="position: absolute"></Loader>

    <div v-else class="ui form">
      <!--<router-link :to="{name: 'CommunityAdminDashboard'}" lang="en" hreflang="en" class="ui small basic button right floated">Back to Dashboard</router-link>-->
      <h1 style="margin-right: 25px">Show Shrpa Photos on your Website!</h1>

      <!-- Description section -->
      <div class="description-section photo-wall-page__description">
        <VideoIntroSnippet class="description-section__video" :thumbnail="`${contentBaseUri}/images/misc/photowall-intro.jpg`" :videoUrl="`${contentBaseUri}/videos/howto/photowall-intro.mp4`" />

        <div class="description-section__text">
          Share stunning visuals right on your website that link back to your destination's adventures!<br />
          Inspire travelers to plan their next trip with our easy Photo Wall integration.<br />
        </div>
      </div>
      <!-- / Description section -->

      <div class="ui stackable grid">
        <div class="ten wide column">
          <table class="ui very basic table">
            <thead>
              <tr>
                <th>
                  <label class="cms-section-title" style="margin-top: 1em !important"> Photo Walls </label>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="photoWidget in photoWidgets" :key="photoWidget.id">
                <td>
                  <router-link :to="{ name: 'PhotoWallAdmin', params: { pageId: pageId, photoWallId: photoWidget.widgetId } }">
                    <LinkWithIcon class="adventure-wall-link">
                      <IconEmbedded name="edit-pencil_2" :size="21" />
                      <span>
                        <b>{{ photoWidget.name }}</b>
                        <span style="margin-left: 3px">({{ photoWidget.photoCount }} photos)</span>
                      </span>
                    </LinkWithIcon>
                  </router-link>
                </td>
                <td>
                  <button class="ui tiny basic primary icon button" @click="copy(photoWidget.widgetId)">
                    <i class="icon copy outline"></i>
                    Copy Code
                  </button>
                  <button class="ui tiny basic button" @click="askToDelete(photoWidget.widgetId)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="justCopied" style="float: right"><b>Code Copied to Clipboard</b></div>
          <div style="margin-top: 8px">
            <button class="ui primary small button" @click="askToCreate(true)">New Photo Wall</button>
          </div>
        </div>
        <div class="six wide column">
          <img class="ui small image photo-wall-img" style="width: 100%" src="https://cdn.shrpa.com/images/misc/integrations/PhotoWall-med.jpg" />
        </div>
      </div>

      <label class="cms-section-title" style="margin-top: 50px !important">Other Settings</label>
      <p>
        <span v-if="integrationSettings" class="ui toggle checkbox">
          <input type="checkbox" :disabled="isSaving" v-model="integrationSettings.openAdventuresInNewTab" @change="otherSettingsChanged" />
          <label>Open adventures in a new browser tab/window.</label>
        </span>
      </p>
      <p v-if="justSaved">
        <b>Settings Saved!</b>
      </p>

      <label class="cms-section-title" style="margin-top: 1em !important">What do I do with that code?</label>
      <p>
        It depends what your site is build on (Wordpress, Simpleview, etc...)
        <br />
        Here's a full walkthrough!
        <br />
        <a style="text-decoration: underline" target="_blank" href="https://explore.shrpa.com/integrations/how-to-integrate-adventures-on-your-site/"
          >https://explore.shrpa.com/integrations/how-to-integrate-adventures-on-your-site/</a
        >
      </p>

      <br />
      <label class="cms-section-title">Questions?</label>
      <p>
        Having issues integrating this into your site? If you or your developer have any questions we're happy to help! <br />
        Email us anytime at <CopyText />
      </p>
    </div>

    <!--Create Modal-->
    <SrpModal v-model:isVisible="showCreateModal" :isClosable="false">
      <template #header><h2 class="global-h2">What to name it?</h2></template>
      <template #content>
        What's a short name you can use to tell this {{ creatingPhotoWidget ? "photo wall" : "adventure wall" }} apart from others?
        <input type="text" v-model="newGroupingName" style="max-width: 300px" />
      </template>
      <template #footer>
        <div class="ui basic black button" @click="cancelCreate()">Cancel</div>
        <div class="ui primary button" @click="createNewGrouping()">{{ isCreatingNewGroup ? "Creating..." : "Create It" }}</div>
      </template>
    </SrpModal>

    <!--Delete Modal-->
    <SrpModal v-model:isVisible="showDeleteModal" :isClosable="false">
      <template #header><h2 class="global-h2">Delete Photo Wall</h2></template>
      <template #content>
        Are you sure you want to delete this photo wall?<br />
        This action cannot be undone.
      </template>
      <template #footer>
        <div class="ui basic black button" @click="cancelDelete()">Cancel</div>
        <SrpButton class="ui negative button" :isDisabled="isDeletingList" @click="deleteList()">Delete</SrpButton>
        <span v-if="isDeletingList"><b>Deleting...</b></span>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import OrgContext from "@logic/OrgContext";

// Types
import { PartnerNavInfo } from "@contracts/partnerNavInfo";

// Components
import CopyText from "@components/CopyText.vue";
import Loader from "@components/Loader/Loader.vue";
import VideoIntroSnippet from "@components/VideoIntroSnippet.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useHead } from "@unhead/vue";

// Photo Walls page
export default defineComponent({
  name: "WidgetDirections",

  components: {
    VideoIntroSnippet,
    SrpButton,
    SrpModal,
    Loader,
    CopyText,
    LinkWithIcon,
    IconEmbedded,
  },

  data() {
    return {
      title: "Photo Walls",
      pageId: "",
      orgInContext: null as PartnerNavInfo,
      // Photo Walls
      photoWidgets: null,
      // Other Settings
      integrationSettings: null,
      code: "",
      justCopied: false,
      isSaving: false,
      justSaved: false,
      isLoading: true,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isHotel: false,

      showCreateModal: false,
      showDeleteModal: false,
      creatingPhotoWidget: false,
      isCreatingNewGroup: false,
      newGroupingName: "",
      listIdToDelete: null,
      isDeletingList: false,
    };
  },

  computed: {},

  created() {
    this.globalRoot.widgetDirectionsComponent = this;
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.pageId = this.$route.params.pageId;
    this.isHotel = OrgContext.isOrgInContextHotel(this);
    this.orgInContext = OrgContext.getOrgInContext(this);
    this.title += " - " + this.orgInContext?.name;
    await this.loadData();
  },

  methods: {
    async loadData() {
      this.isLoading = true;

      // Photo Walls
      const photoWidgetsResponse = await axios.get(`${import.meta.env.VITE_API_URL}/photo-widget-admin/${this.pageId}/widgets/summary`);
      this.photoWidgets = photoWidgetsResponse.data;

      // Widget and Subscription Settings
      const settingsResponse = await axios.get(`${import.meta.env.VITE_API_URL}/widget-admin/${this.pageId}/widgets/settings`);
      this.integrationSettings = settingsResponse.data.widgetSettings;

      this.isLoading = false;
    },
    async otherSettingsChanged(event, value: boolean) {
      this.isSaving = true;
      await axios.put(`${import.meta.env.VITE_API_URL}/widget-admin/${this.pageId}/widgets/settings?openInNewWindow=${this.integrationSettings.openAdventuresInNewTab}`);
      this.justSaved = true;
      this.isSaving = false;
      setTimeout(() => (this.justSaved = false), 5000);
    },
    async copy(groupId) {
      // Note: Typescript just couldn't handle the /s in the string below so I threw in the unnecessary concat after fighting with it for too long
      const code = `<script id="shrpa-go__${this.pageId}--${groupId}" src="https://shrpa.com/widget/photos.js" async></` + `script>`;
      await navigator.clipboard.writeText(code);

      this.justCopied = true;
      setTimeout(() => {
        this.justCopied = false;
      }, 5000);
    },
    viewCommunityPage() {
      this.$router.push({ name: "Pages", params: { pageId: this.orgInContext.uniqueName } });
    },
    askToCreate(isPhotoWall: boolean) {
      this.creatingPhotoWidget = isPhotoWall;
      this.showCreateModal = true;
    },
    cancelCreate() {
      this.showCreateModal = false;
    },
    async createNewGrouping() {
      if (this.isCreatingNewGroup) return;
      if (!this.newGroupingName || this.newGroupingName.length == 0) return;

      this.isCreatingNewGroup = true;
      // Photo Wall
      const config = { headers: { "Content-Type": "application/json" } };
      const newPhotoWidget = await axios.post(`${import.meta.env.VITE_API_URL}/photo-widget-admin/${this.pageId}/widgets`, JSON.stringify(this.newGroupingName), config);
      this.$router.push({ name: "PhotoWallAdmin", params: { pageId: this.pageId, photoWallId: newPhotoWidget.data.widgetId } });
    },
    askToDelete(listIdToDelete: string) {
      this.listIdToDelete = listIdToDelete;
      this.showDeleteModal = true;
    },
    cancelDelete() {
      this.listIdToDelete = null;
      this.showDeleteModal = false;
    },
    async deleteList() {
      this.isDeletingList = true;
      await axios.delete(`${import.meta.env.VITE_API_URL}/photo-widget-admin/${this.pageId}/widgets/${this.listIdToDelete}`);
      this.photoWidgets.splice(
        this.photoWidgets.findIndex(g => g.widgetId === this.listIdToDelete),
        1
      );
      this.isDeletingList = false;
      this.showDeleteModal = false;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

.adventure-wall-link {
  & > :deep(span),
  & > :deep(span > span) {
    text-decoration: none;
  }

  & > :deep(span b) {
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: rgba(0, 0, 0, 0.3);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }

  &:hover > :deep(span b) {
    text-decoration: none;
  }
}

.photo-wall-img {
  margin-top: 60px;
  @media screen and (max-width: 420px) {
    margin-top: 0px;
  }
}

.limit-hit-message {
  margin-top: 10px;
}

// Description section ========================================================
.description-section {
  display: inline-flex;

  &__video {
    margin: 5px 22px 10px 0;
  }

  &__text {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .description-section {
    display: inline-block;

    &__video {
      float: left;
      position: relative;
    }
  }
}

// Photo wall page ============================================================
.photo-wall-page {
  &__description {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>
