<template>
  <div class="photos-layout">
    <!-- Recommendations note -->
    <div v-if="!imagesList.length && props.isEditProfileMode" class="global-h3 recommendations-note photos-layout__recommendations-note">
      <span class="recommendations-note__bold-text">
        Upload <span class="recommendations-note__orange-text">{{ minimumRequiredPhotoCount }}+ photos & videos</span> from your travel experiences</span
      >
      <br />
      that highlight your style, the quality of your content, and showcase why you're a great creator!
    </div>
    <!-- / Recommendations note -->

    <!-- Drop section -->
    <div v-if="props.isEditProfileMode" class="drop-section photos-layout__drop-section">
      <RequiredFieldNote v-if="enableRequiredFieldNote && imagesList.length < minimumRequiredPhotoCount" class="drop-section__error-field-note">
        upload at least {{ minimumRequiredPhotoCount - imagesList.length }} more</RequiredFieldNote
      >

      <UploadPhotoForm
        :img="imagesList"
        class="drop-section__drop-area"
        :isThumbnailsVisible="false"
        @imageUploadedToServer="newImages => appendNewImage(newImages.serverId)"
        :isError="enableRequiredFieldNote && imagesList.length < minimumRequiredPhotoCount"
      />
      <!--  -->
    </div>
    <!-- / Drop section -->

    <LinkWithIcon
      v-if="!props.isEditProfileMode && imagesList.length"
      class="photos-layout__show-more-link"
      color="gray"
      isDottedUnderline
      @click="isAllPhotosVisible = !isAllPhotosVisible"
      iconInset="1px auto auto 2px"
    >
      <template #icon><IconEmbedded :name="isAllPhotosVisible ? 'caret-top_4' : 'caret-bottom_4'" :size="15" /></template>
      <span v-if="isAllPhotosVisible">Show less</span>
      <span v-else>Show more</span>
    </LinkWithIcon>

    <TransitionedHeightAuto
      v-if="(props.isEditProfileMode && !imagesList.length) || imagesList.length"
      class="photos-layout__transition-height-container"
      :isOpened="Boolean(imagesList.length) && (isAllPhotosVisible || props.isEditProfileMode)"
      :transitionDuration="200"
      :minHeight="
        imagesList.length > 4
          ? {
              'desktop-wide': 600,
              desktop: 600,
              laptop: 600,
              'tablet-large': 470,
              tablet: 440,
              mobile: 380,
            }[screenSize]
          : {
              'desktop-wide': 325,
              desktop: 325,
              laptop: 325,
              'tablet-large': 270,
              tablet: 440,
              mobile: 300,
            }[screenSize]
      "
      isKeepAlive
    >
      <div
        :class="{
          'photos-layout__masonry-container-wrap': true,
          'photos-layout__masonry-container-wrap--with-curtain': !imagesList.length || (!isAllPhotosVisible && !props.isEditProfileMode),
        }"
        ref="domRefMasonryContainerWrap"
      >
        <SrpMasonry
          v-if="!imagesList.length"
          class="photos-layout__masonry-container"
          :items="[
            { fileType: 'image', aspectRatio: '1/1.33' },
            { fileType: 'image', aspectRatio: '1/0.66' },
            { fileType: 'image', aspectRatio: '1/1.33' },
            { fileType: 'video', aspectRatio: '1/0.74' },
            { fileType: 'image', aspectRatio: '1/0.74' },
            { fileType: 'image', aspectRatio: '1/1.33' },
            { fileType: 'image', aspectRatio: '1/0.66' },
            { fileType: 'image', aspectRatio: '1/1.33' },
            { fileType: 'image', aspectRatio: '1/0.74' },
          ]"
          :columns="masonryColumnsCount"
          :columnGap="parseInt(gridColGapWidth)"
          :style="{ marginBottom: gridColGapWidth }"
          ref="domRefMasonryContainer"
        >
          <template #default="{ item }">
            <div
              class="photos-layout__thumbnail-placeholder"
              :style="{
                width: '100%',
                aspectRatio: item.aspectRatio,
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'rgba(228, 228, 228, 1)',
              }"
              draggable="false"
            >
              <img :src="item.fileType === 'image' ? iconImagePlaceholder : iconVideoPlaceholder" alt="Thumbnail" />
            </div>
          </template>
        </SrpMasonry>

        <SrpMasonry
          v-else
          class="photos-layout__masonry-container"
          :items="imagesList"
          :rerenderTimeout="50"
          :columns="masonryColumnsCount"
          :columnGap="parseInt(gridColGapWidth)"
          :style="{ marginBottom: gridColGapWidth }"
          ref="domRefMasonryContainer"
        >
          <template #default="{ item, index, rebuildMasonry }">
            <!-- Thumbnail container -->
            <a
              :class="{
                'thumbnail-container': true,
                'thumbnail-container--with-left-highlighting-line': elementIndexDraggedOver === index || elementIndexTouchDraggedOver === index,
                'thumbnail-container--gray-overlay': (isDragInProgress || isTouchDragInProgress) && (elementIndexDragged === index || elementIndexTouchDragged === index),
                'photos-layout__thumbnail-container': true,
              }"
              lang="en"
              hreflang="en"
              data-fancybox="gallery"
              :href="generateSrc(item, 'full')"
              :data-index="index"
              :draggable="true"
            >
              <CloseDeleteButton
                v-if="props.isEditProfileMode"
                class="thumbnail-container__delete-button"
                @click.prevent.stop="deletePhotoOnTouch(index, rebuildMasonry)"
                @touchstart.stop="deletePhotoOnTouch(index, rebuildMasonry)"
                @touchmove.stop="clearDeletePhotoOnTouchTimeout"
              />

              <DragAndDropHandle class="thumbnail-container__drag-n-drop-button" :data-index="index" />

              <ReorderSnippet
                v-if="props.isEditProfileMode"
                :class="{
                  'thumbnail-container__reorder-snippet': true,
                  'thumbnail-container__reorder-snippet--transparent-for-clicks': isDragInProgress || isTouchDragInProgress,
                }"
                :index="index"
                :isRightArrowVisible="index !== imagesList.length - 1"
                :isLeftArrowVisible="index !== 0"
                @click.stop.prevent
                @clickRightArrow="reorderImage(index, 1, rebuildMasonry)"
                @clickLeftArrow="reorderImage(index, -1, rebuildMasonry)"
              />

              <SrpFileThumbnail
                :class="{
                  'thumbnail-container__thumbnail': true,
                  'thumbnail-container__thumbnail--pointer-events-none': isDragInProgress,
                }"
                :thumbSize="'thumb-large'"
                :fileName="item"
                :isFixedAspectRatio="false"
                @imageLoad="rebuildMasonry"
              />
            </a>
            <!-- / Thumbnail container -->
          </template>
        </SrpMasonry>
      </div>
    </TransitionedHeightAuto>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, Ref, onMounted, watch, computed, nextTick, toRef } from "vue";
import lodashCloneDeep from "lodash-es/cloneDeep";
import generateSrc from "@helpers/GenerateGallerySrc";

// Composables
import { useMouseDragNDrop } from "@composables/useMouseDragNDrop";
import { useTouchDragNDrop } from "@composables/useTouchDragNDrop";

// Components
import LinkWithIcon from "@components/LinkWithIcon.vue";
import RequiredFieldNote from "@components/ui/RequiredFieldNote.vue";
import SrpFileThumbnail from "@components/ui/SrpFileThumbnail.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";
import UploadPhotoForm from "@views/CMS/UploadPhotoForm.vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Icons
import iconImagePlaceholder from "@assets/icons/image_placeholder.svg";
import iconVideoPlaceholder from "@assets/icons/video_placeholder.svg";
import ReorderSnippet from "@views/CreatorProfilePublic/ReorderSnippet.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CloseDeleteButton from "@components/ui/CloseDeleteButton.vue";
import DragAndDropHandle from "@components/ui/DragAndDropHandle.vue";

const props = withDefaults(
  defineProps<{
    imagesList: Array<string>;
    isEditProfileMode: boolean;
    enableRequiredFieldNote?: boolean;
    minimumRequiredPhotoCount: number;
  }>(),
  {
    imagesList: () => [],
    isEditProfileMode: false,
    enableRequiredFieldNote: true,
    minimumRequiredPhotoCount: 15,
  }
);

const emit = defineEmits<{
  (e: "clickApply"): void;
  (e: "update:imagesList", newList: string[]): void;
}>();

// Global variables
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;
const gridColGapWidth = inject("gridColGapWidth") as Ref<string>;
const screenSize = inject("screenSize") as Ref<ScreenSize>;

// Dom refs
const domRefMasonryContainer = ref<any>();
const domRefMasonryContainerWrap = ref<HTMLElement | null>(null);

// Masonry columns count ======================================================
const masonryColumnsCount = computed<number>(() => {
  return { "desktop-wide": 4, desktop: 4, laptop: 3, "tablet-large": 3, tablet: 2, mobile: 2 }[screenSize.value];
});

// Append new images ==========================================================
function appendNewImage(newImage: string) {
  emit("update:imagesList", [...props.imagesList, newImage]);
}

// Toggle is all photos visible ===============================================
const isAllPhotosVisible = ref<boolean>(false);

// Reorder image ==============================================================
function reorderImage(index: number, direction: -1 | 1, rebuildMasonryCallback: () => void) {
  const newIndex = index + direction;
  const elementCurrent = JSON.parse(JSON.stringify(props.imagesList[index]));
  const elementToReplace = JSON.parse(JSON.stringify(props.imagesList[newIndex]));
  const newList = [...props.imagesList];

  newList[index] = elementToReplace;
  newList[newIndex] = elementCurrent;

  // The emit does not fire from the watch here for some reason
  emit("update:imagesList", newList);

  rebuildMasonryCallback();
}

// Move item to the new index ===============================================
function moveItemToTheNewIndex(oldIndex, newIndex) {
  const reorderedElement = props.imagesList[oldIndex];
  const newList = [...props.imagesList];
  newList.splice(oldIndex, 1);

  newIndex = newIndex > oldIndex ? newIndex + 1 : newIndex; // when Dragged element index changes to a higher value it should be compensated by adding +1 to an index otherwise it will end up before the DraggedOver element (it happens because all the element before the DraggedOver element shifts to compensate the empty spaces caused by moving the Dragged element)

  emit("update:imagesList", [...newList.slice(0, newIndex), reorderedElement, ...newList.slice(newIndex)]);

  domRefMasonryContainer.value?.rebuildMasonry();
}

// Set mouse drag & drop ======================================================
const { isDragInProgress, elementIndexDragged, elementIndexDraggedOver } = useMouseDragNDrop(
  toRef(() => props.isEditProfileMode),
  toRef(() => props.imagesList),
  domRefMasonryContainerWrap,
  ".thumbnail-container",
  ".thumbnail-container .drag-n-drop-handle",
  moveItemToTheNewIndex
);

// Set touch drag & drop ======================================================
const { isTouchDragInProgress, elementIndexTouchDragged, elementIndexTouchDraggedOver } = useTouchDragNDrop(
  toRef(() => props.isEditProfileMode),
  toRef(() => props.imagesList),
  domRefMasonryContainerWrap,
  ".thumbnail-container",
  ".thumbnail-container .drag-n-drop-handle",
  moveItemToTheNewIndex
);

// Delete photo on touch ======================================================
let deleteTouchStartTimeout: ReturnType<typeof setTimeout> | null = null;

function deletePhotoOnTouch(index: number, rebuildMasonryCallback: () => void) {
  deleteTouchStartTimeout = setTimeout(() => {
    const newList = [...props.imagesList];
    newList.splice(index, 1);
    emit("update:imagesList", newList);
    rebuildMasonryCallback();
  }, 250);
}

function clearDeletePhotoOnTouchTimeout() {
  clearTimeout(deleteTouchStartTimeout);
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/modal-close-button.scss";

// Recommendations note =======================================================
.recommendations-note {
  color: rgba(138, 138, 138, 1);
  text-align: center;
  font-weight: 400;

  &__bold-text {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
  }

  &__orange-text {
    color: rgba(236, 87, 59, 1);
  }
}

// Thumbnail container ========================================================
.thumbnail-container {
  display: block;
  position: relative;
  z-index: 0;

  &--with-left-highlighting-line {
    &::before {
      content: "";
      width: 5px;
      height: 100%;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto calc(var(--grid-col-gap-width) / 2 * -1 - 2px);
      background: rgba(17, 134, 137, 1);
    }
  }

  &--gray-overlay {
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 6px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: 4;
      background: rgba(220, 220, 220, 1);
    }
  }

  &__drag-n-drop-button {
    position: absolute;
    z-index: 3;
    inset: 10px auto auto 10px;
  }

  &__delete-button {
    position: absolute;
    z-index: 2;
    inset: -10px -10px auto auto;
  }

  &__reorder-snippet {
    position: absolute;
    z-index: 2;
    inset: calc(50% - 43px) auto auto calc(50% - 82px);

    &--transparent-for-clicks {
      pointer-events: none;
      touch-action: none;
    }
  }

  &__thumbnail {
    margin-bottom: var(--grid-col-gap-width);
    pointer-events: none;

    &--pointer-events-none {
      pointer-events: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .thumbnail-container {
    &__reorder-snippet {
      transform: scale(0.7);
    }
  }
}

// Drop section ===============================================================
.drop-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__error-field-note {
    margin-bottom: 5px;
  }

  &__drop-area {
    width: 100%;
  }
}

// Photos layout ==============================================================
.photos-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::after {
    content: "";
    width: 100vw;
    height: 0;
    border-bottom: 1px rgba(225, 225, 225, 1) solid;
    position: absolute;
    inset: auto auto 0 calc(50% - 50vw);
  }

  &__recommendations-note {
    width: 640px;
    max-width: var(--grid-content-full-width);
    margin: 0 auto 35px;
  }

  &__drop-section {
    width: var(--grid-content-full-width);
    margin-bottom: 45px;
  }

  &__show-more-link {
    position: absolute;
    z-index: 3;
    inset: auto auto 50px calc(50% - 58px);
  }

  &__transition-height-container {
    width: 100%;
  }

  &__masonry-container-wrap {
    width: 100%;
    padding-bottom: 70px;
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
    transition: padding-bottom 0.2s ease-in-out;

    &::before {
      content: "";
      width: 100%;
      height: 190px;
      position: absolute;
      inset: auto auto 0 0;
      z-index: 2;
      opacity: 0;
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%);
      transition: opacity 0.2s ease-in-out;
      pointer-events: none;
    }

    &--with-curtain {
      padding-bottom: 0;

      &::before {
        opacity: 1;
      }
    }
  }

  &__masonry-container {
    width: var(--grid-content-full-width);
    padding-top: 15px;
  }

  &__thumbnail-container {
    :deep(.file-thumbnail__video-icon) {
      width: 65px;
      min-width: 65px;
      max-width: 65px;
      height: 65px;

      svg {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  &__thumbnail-placeholder {
    margin-bottom: var(--grid-col-gap-width);
    pointer-events: none !important;

    :deep(img) {
      width: 55% !important;
      min-width: 55% !important;
      margin-top: -4%;
      pointer-events: none !important;
      opacity: 0.07;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .photos-layout {
    &__thumbnail-container {
      :deep(.file-thumbnail__video-icon) {
        width: 55px;
        min-width: 55px;
        max-width: 55px;
        height: 55px;

        svg {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
}
</style>
