<template>
  <template v-if="useCollabLocationsLogic && !stop.collabLocationId">
    <div style="position: relative">
      <img src="/images/map-placeholder.gif" alt="Map Background" style="width: 100%; height: 250px; object-fit: cover" />
      <SrpButton @click="emit('click:setLocation')" style="position: absolute; top: 0; left: 0; display: flex; right: 0; max-width: 150px; bottom: 0; margin: auto"> Set a Location </SrpButton>
      <div v-if="props.fieldsWithErrors.find(i => i.field === 'collabLocationId')" style="color: red">{{ props.fieldsWithErrors.find(i => i.field === "collabLocationId").errorMsg }}</div>
    </div>
  </template>
  <div v-else ref="domRefRoot">
    <!-- if collab locations are being used for the stop, don't show the label -->
    <label v-if="!useCollabLocationsLogic" class="subsection-title">What's the name of this stop?</label>
    <template v-if="useCollabLocationsLogic">
      <!-- instead show name/address override inputs -->
      <template v-if="isStopEditMode">
        <label class="subsection-title"
          >Name
          <TooltipWrapper
            style="margin-left: 0.5rem; position: relative; top: 3px"
            :width="250"
            text="Events or large locations, like County Fairgrounds, can be adjusted to be more specific. 
Ex. Ferris Wheel at the Fair"
            placement="top"
          >
            <template #hoverableElement>
              <IconEmbedded name="info_2" :size="18" color="rgba(17, 134, 137, 1)" />
            </template>
          </TooltipWrapper>
        </label>
        <input style="margin-bottom: 1rem" v-model="stop.title" />
        <label class="subsection-title">Address</label>
        <input style="margin-bottom: 1rem" v-model="stop.address" />
        <label class="subsection-title">Website</label>
        <input style="margin-bottom: 1rem" v-model="stop.website" />
        <div class="map-section__edit--actions">
          <SrpButton class="map-section__edit--new-location-button" size="tiny" fill="outlined" @click="setNewLocation">Set a different Location</SrpButton>
          <SrpButton class="map-section__edit--done-button" size="tiny" @click="isStopEditMode = false" style="margin-left: auto">Done</SrpButton>
        </div>
      </template>
      <template v-else>
        <h2>
          {{ stop.title }}

          <LinkWithIcon v-if="useCollabLocationsLogic && !isStopEditMode" @click="isStopEditMode = true" style="margin-left: auto" isDottedUnderline iconInset="1px auto auto auto">
            <template #icon>
              <IconEmbedded name="edit-pencil_2" :size="20" color="rgba(17, 134, 137, 1)" />
            </template>
          </LinkWithIcon>
        </h2>
        <p>{{ stop.address }}</p>
      </template>
    </template>
    <div v-if="props.fieldsWithErrors.map(i => i.field).includes('title') || props.fieldsWithErrors.map(i => i.field).includes('latitude')" style="color: red">
      <template v-if="props.fieldsWithErrors.map(i => i.field).includes('latitude')"> {{ props.fieldsWithErrors.find(i => i?.field === "latitude").errorMsg }}<br /> </template>
      <template v-if="props.fieldsWithErrors.map(i => i.field).includes('title')">
        {{ props.fieldsWithErrors.find(i => i?.field === "title").errorMsg }}
      </template>
    </div>
    <div
      :class="{
        field: true,
        error: props.fieldsWithErrors.map(i => i.field).includes('title') || props.fieldsWithErrors.map(i => i.field).includes('latitude'),
      }"
    >
      <GMap
        v-model:stop="props.stop"
        :locationDefault="firstStopLocation"
        v-model:index="props.stopTabIndex"
        :hideFormFields="useCollabLocationsLogic"
        @placeChanged="placeChanged"
        @markerManuallyMoved="emit('markerManuallyMoved')"
        :class="`map-section marginBottom1 stop-tab-index-${props.stopTabIndex}`"
        @mapReady="
          () => {
            isMapReady = true;
            emit('mapReady');
          }
        "
      />
    </div>
    <slot name="under-map"></slot>
    <div class="map-section__adjust-stop" v-if="useCollabLocationsLogic && !isStopEditMode">
      <a v-if="stop.website" class="website" :href="stop.website" target="_blank" rel="noopener noreferrer">{{ stop.website }}</a>
    </div>
    <SrpSpotlightGuide
      v-model:isVisible="isSameLocationSpotlightVisible"
      :targetNodes="[[domRefRoot]]"
      :placement="['mobile', 'tablet', 'tablet-large', 'laptop'].includes(screenSize) ? 'top' : 'right'"
      :isWithCloseBtn="false"
    >
      <template #textBubble1="{ close }">
        <div style="width: 350px">
          <h3 class="global-h3" style="margin-bottom: 8px">Shrpa is meant for multi-stop adventures!</h3>
          <p class="global-p">
            <b>Highlight different locations and businesses that make up a great itinerary!</b>
          </p>
          <p class="global-p" style="margin-bottom: 16px">
            Sometimes the same location can be re-used (ex. a fair or state park) if there are distinct activities at that location. If so, adjust the map marker.<br />
            Otherwise, make the location distinct from other stops.
          </p>

          <div style="display: flex; flex-direction: column; align-items: flex-start">
            <SrpButton @click="clearAddressInput(close)" style="margin-bottom: 12px">Change the Location</SrpButton>
            <SrpButton @click="shiftPinBy100Yards(close)" color="gray" fill="outlined">Shift map marker by 100 yards</SrpButton>
          </div>
        </div>
      </template>
    </SrpSpotlightGuide>

    <SrpSpotlightGuide
      v-model:isVisible="isTooFarAwaySpotlightVisible"
      :targetNodes="[[domRefRoot]]"
      :placement="['mobile', 'tablet', 'tablet-large', 'laptop'].includes(screenSize) ? 'top' : 'right'"
      :isWithCloseBtn="false"
    >
      <template #textBubble1="{ close }">
        <div style="width: 350px">
          <h2 class="global-h2">Shrpa is meant for day-sized adventures!</h2>
          <br />
          <p class="global-p">We recommend choosing locations near enough to be combined into a fun, single day experience.<br /></p>
          <ul style="margin-bottom: 22px; color: #5b5b5b">
            <li style="margin-bottom: 7px">Choose a closer location to the previous stops.</li>
            <li style="margin-bottom: 7px">Or you can create multiple adventures.</li>
            <!--<li>Reach out if you have any questions!</li>-->
          </ul>

          <SrpButton @click="clearAddressInput(close)"> Change the Location </SrpButton>
        </div>
      </template>
    </SrpSpotlightGuide>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, Ref, inject, watch, nextTick, onMounted } from "vue";

// Types
import { Itinerary, ItineraryStep, FieldErrorInfo } from "@contracts/itinerary";
import { ScreenSize } from "@contracts/screenSize";
import { CollabLocation } from "@contracts/collabLocations";

// Components
import GMap from "@views/CMS/GMap.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpSpotlightGuide from "@components/SrpSpotlightGuide.vue";
import { distanceInMilesBetweenEarthCoordinates } from "@helpers/MapHelper.js";
import { ComputedRef } from "vue";
import CollabStopLocationSelectorModal from "./CollabStopLocationSelectorModal.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import TooltipWrapper from "@components/ui/TooltipWrapper.vue";

const props = withDefaults(
  defineProps<{
    stop: ItineraryStep | null;
    fieldsWithErrors: FieldErrorInfo[];
    firstStop: any;
    stopTabIndex: number;
    isTabActive: boolean;
    allStops: ItineraryStep[];
    useCollabLocationsLogic: boolean;
  }>(),
  {
    stop: null,
    fieldsWithErrors: () => [],
    firstStop: () => ({}),
    stopTabIndex: -1,
    isTabActive: false,
    allStops: () => [],
  }
);

const emit = defineEmits<{
  (e: "placeChanged", updatedStop: ItineraryStep, stopTabIndex: number, callback: Function): void;
  (e: "markerManuallyMoved"): void;
  (e: "mapReady"): void;
  (e: "resetPlace"): void;
  (e: "click:setLocation"): void;
  (e: "click:clearLocation"): void;
}>();

// Global variables
const screenSize = inject("screenSize") as Ref<ScreenSize>;

// Set dom refs ===============================================================
const domRefRoot = ref<HTMLElement | null>(null);

// Get first stop location ====================================================
// Used to default the subsequent stops map location
const firstStopLocation = computed<Location | null>(() => {
  return props.firstStop?.latitude && props.firstStop?.longitude ? (props.firstStop as Location) : null;
});

const isStopEditMode = ref(false);

// Place change emit ==========================================================
function placeChanged(updatedStop: ItineraryStep) {
  resetAllSpotlightsVisibility();
  // eslint-disable-next-line
  emit("placeChanged", updatedStop, props.stopTabIndex, () => {});
}

// Is map ready ===============================================================
const isMapReady = ref(false);

// Set spotlight guide visibility =============================================
const isSameLocationSpotlightVisible = ref(false);
let isSameLocationStopName = ""; // Just for logging
const isTooFarAwaySpotlightVisible = ref(false);
let isTooFarAwayDistanceInMiles = -1; // Just for logging

const isSameLocationSpotlightWasClosedByUser = ref(false);
const isTooFarAwaySpotlightWasClosedByUser = ref(false);

watch(
  () => props.isTabActive,
  () => {
    isTooFarAwaySpotlightWasClosedByUser.value = false;
    isSameLocationSpotlightWasClosedByUser.value = false;
  }
);

watch(
  () => [props.stop.latitude, props.stop.longitude, props.stop.address, props.stop.city, props.stop.country, props.stop.externalId],
  async () => {
    await nextTick();
    await resetAllSpotlightsVisibility();
  },
  {
    deep: true,
  }
);

onMounted(async () => {
  await nextTick();
  await resetAllSpotlightsVisibility();
});

async function resetAllSpotlightsVisibility(): Promise<void> {
  isSameLocationSpotlightVisible.value = false;
  isTooFarAwaySpotlightVisible.value = false;

  isSameLocationSpotlightVisible.value = setIsSameLocationSpotlightVisible();
  isTooFarAwaySpotlightVisible.value = setIsTooFarAwaySpotlightVisible();
}

function setIsSameLocationSpotlightVisible(): boolean {
  if (!props.isTabActive || isTooFarAwaySpotlightVisible.value) {
    return false;
  }
  const currentStop = props.allStops[props.stopTabIndex];

  if (!currentStop?.latitude && !currentStop?.longitude) {
    return false;
  }

  for (let i = 0; i < props.allStops.length; i++) {
    if (i === props.stopTabIndex) continue;

    if (props.allStops[i].latitude === currentStop.latitude && props.allStops[i].longitude === currentStop.longitude) {
      isSameLocationStopName = props.allStops[i].title;
      return true;
    }
  }
}

const MAX_DISTANCE = 150;
function setIsTooFarAwaySpotlightVisible(): boolean {
  if (!props.isTabActive || isSameLocationSpotlightVisible.value) {
    return false;
  }

  const currentStop = props.allStops[props.stopTabIndex];

  if (!currentStop?.latitude && !currentStop?.longitude) {
    return false;
  }

  let isAtLeastOneStopIsFurtherThanMaxDistance = false;

  for (let i = 0; i < props.allStops.length; i++) {
    if (i === props.stopTabIndex) continue;

    const lat1 = currentStop?.latitude;
    const lon1 = currentStop?.longitude;
    const lat2 = props.allStops[i]?.latitude;
    const lon2 = props.allStops[i]?.longitude;

    if ([lat2, lon2].includes(null) || [lat2, lon2].includes(undefined)) {
      continue;
    }

    let distanceBetweenStops = distanceInMilesBetweenEarthCoordinates(lat1, lon1, lat2, lon2);
    if (distanceBetweenStops > MAX_DISTANCE) {
      isTooFarAwayDistanceInMiles = distanceBetweenStops;
      isAtLeastOneStopIsFurtherThanMaxDistance = true;
      break;
    }
  }

  return isAtLeastOneStopIsFurtherThanMaxDistance;
}

// Set a new location =========================================================
function setNewLocation() {
  emit("click:setLocation");
  isStopEditMode.value = false;
}

// Clear the address input ====================================================
function clearAddressInput(callback: () => void): void {
  // clearing the address for collab locations means clearing the selected collabLocationId and related data
  if (props.useCollabLocationsLogic) {
    emit("click:clearLocation");
    return;
  }
  const addressInput: HTMLInputElement = document.querySelector(`.map-section.stop-tab-index-${props.stopTabIndex} input[placeholder="Enter a location"]`);

  emit("resetPlace");

  if (addressInput) {
    addressInput.value = "";
    addressInput.focus();
  }

  callback();
}

// Shift marker by 100 yards =====================================================
function shiftPinBy100Yards(callback: () => void): void {
  const yards = 100;

  function deg2rad(deg) {
    return (deg * Math.PI) / 180;
  }

  function rad2deg(rad) {
    return (rad * 180) / Math.PI;
  }

  // Earth's radius in kilometers
  const earthRadiusKm = 6371.0;

  // Convert yards to kilometers
  const distanceKm = yards * 0.0009144;

  // Random angle in radians
  const angle = Math.random() * 2 * Math.PI;

  // Convert the distance and angle into changes in coordinates
  let deltaLat = (distanceKm * Math.cos(angle)) / earthRadiusKm;
  let deltaLng = (distanceKm * Math.sin(angle)) / (earthRadiusKm * Math.cos(deg2rad(props.stop.latitude)));

  // Apply the deltas to the original coordinate
  props.stop.latitude += rad2deg(deltaLat);
  props.stop.longitude += rad2deg(deltaLng);

  callback();
}

// Log the spotlight appearing ================================================
const globalRemoteLogger = inject("globalRemoteLogger") as any;
const itinerary = inject("itinerary") as ComputedRef<Itinerary | null>;

watch(
  () => [isSameLocationSpotlightVisible.value, isTooFarAwaySpotlightVisible.value],
  () => {
    if (isSameLocationSpotlightVisible.value) {
      globalRemoteLogger.info(`AdventureValidation.SameLocation shown. creatorId: ${itinerary.value.sherpaId}, stopName: ${isSameLocationStopName}, itineraryId: ${itinerary.value.id}`);
    }
    if (isTooFarAwaySpotlightVisible.value) {
      globalRemoteLogger.info(
        `AdventureValidation.TooFarAway shown. creatorId: ${itinerary.value.sherpaId}, distance: ${Math.floor(isTooFarAwayDistanceInMiles)}mi, itineraryId: ${itinerary.value.id}`
      );
    }
  }
);
</script>

<style scoped lang="scss">
.map-section__edit--actions {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.map-section__adjust-stop {
  margin-top: 1em !important;
  display: flex;
  align-items: center;
  &__link {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-grow: 1;
  }
  .website {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
