<template>
  <div class="collab-exp">
    <div class="collab-exp__section">
      <div class="global-h6 collab-exp__title">By accepting a collab, you agree to the following:</div>

      <CollabExpectationsList class="collab-exp__thesis-list" :requestedLocationCount="props.requestedLocationCount" :requestedAdventureCount="props.requestedAdventureCount" />

      <NoteWithIcon class="collab-exp__warning" color="gray" size="tiny">
        <template #icon><IconEmbedded name="info-simple_4" :size="17" /></template>
        <template #text>
          <span>
            Upon confirmation, you are committing to complete this collaboration. Cancellations are highly discouraged and can impact your reputation and cause significant inconvenience for the
            destination you're working with.
          </span>
        </template>
      </NoteWithIcon>
    </div>
    <div class="collab-exp__section">
      <div class="global-h6 collab-exp__title">Tips for a successful collab:</div>
      <!-- Thesis list -->
      <ul class="thesis-list collab-exp__thesis-list">
        <li class="thesis-list__thesis">Take LOTS of photos! You can add descriptions after your trip but you can't create photos after the fact.</li>
        <li class="thesis-list__thesis">You can re-use stops across adventures but vary your descriptions and photos.</li>
        <li class="thesis-list__thesis">Communicate! If you have questions or a change of plans, let both Shrpa and the destination know.</li>
        <li class="thesis-list__thesis"><a target="_blank" :href="globalGetLink('CollabGuidance')">Get more tips</a>.</li>
      </ul>
      <!-- / Thesis list -->
    </div>
    <div class="collab-exp__section">
      <div class="global-h6 collab-exp__title">Payment Policy</div>
      <!-- Thesis list -->
      <ul class="thesis-list collab-exp__thesis-list">
        <li class="thesis-list__thesis">1st Collaboration - $300</li>
        <li class="thesis-list__thesis">2nd-4th Collaboration - $325</li>
        <li class="thesis-list__thesis">5th-7th Collaboration - $350</li>
        <li class="thesis-list__thesis">8+ Collaborations - $400</li>
        <li class="thesis-list__thesis">Shrpa will add $250 to your payment to cover lodging if the destination is unable to arrange it.</li>
      </ul>
      <!-- / Thesis list -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from "vue";

// Components
import CollabExpectationsList from "./CollabExpectationsList.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const globalGetLink = inject("globalGetLink") as any;

const props = withDefaults(
  defineProps<{
    requestedLocationCount: number;
    requestedAdventureCount: number;
  }>(),
  {
    requestedLocationCount: 10,
    requestedAdventureCount: 4,
  }
);
</script>

<style scoped lang="scss">
// Thesis list ================================================================
.thesis-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__thesis {
    padding-left: 12px;
    margin-bottom: 5px;
    position: relative;
    color: #5b5b5b;
    font-family: sans-serif;

    &::before {
      content: "";
      width: 6px;
      height: 2px;
      display: block;
      position: absolute;
      inset: 9px auto auto 0;
      font-size: 0;
      line-height: 0;
      background: rgba(0, 0, 0, 0.2);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  :deep(a) {
    color: #2165a6;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

// Collab expectations ========================================================
.collab-exp {
  &__section {
    padding-bottom: 20px;
    margin-bottom: 19px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__title {
    margin-bottom: 7px;
  }

  &__thesis-list {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
