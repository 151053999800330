<template>
  <div>
    <Loader v-if="isLoading" style="position: absolute"></Loader>
    <div v-else class="ui form">
      <!--<router-link :to="{name: 'CommunityAdminDashboard'}" lang="en" hreflang="en" class="ui small basic button right floated">Back to Dashboard</router-link>-->
      <div>
        <h1 style="display: inline; margin-right: 25px">Show Shrpa Adventures on your Website!</h1>
      </div>
      <p>
        Shrpa adventures are an engaging way to introduce travelers to local areas and attractions!
        <br />We want to make sure that you are able to feature this content {{ isHotel ? "on your site" : "in all of the channels you use to promote your destination" }}.
        <span v-if="!isHotel"><br />Your website is a great place to start!</span>
      </p>

      <div class="ui stackable grid">
        <div class="ten wide column">
          <table class="ui very basic table">
            <thead>
              <tr>
                <th>
                  <label class="cms-section-title" style="margin-top: 1em !important"> Adventure Walls </label>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <!--Note: The default wall is now just another customGrouping-->
              <tr v-for="group in customGroupings" :key="group.id">
                <td>
                  <router-link :to="{ name: 'CustomIntegration', params: { pageId: pageId, listId: group.id } }">
                    <LinkWithIcon class="adventure-wall-link">
                      <IconEmbedded name="edit-pencil_2" :size="21" />
                      <span>
                        <b>{{ group.name }}</b>
                        <span style="margin-left: 3px">({{ group.adventureCount }} adventures)</span>
                      </span>
                    </LinkWithIcon>
                  </router-link>
                </td>
                <td>
                  <button class="ui tiny basic primary icon button" @click="copy(group.id)">
                    <i class="icon copy outline"></i>
                    Copy Code
                  </button>
                  <button class="ui tiny basic button" @click="askToDelete(group.id)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="justCopied" style="float: right"><b>Code Copied to Clipboard</b></div>
          <div style="margin-top: 8px">
            <button class="ui primary small button" @click="askToCreate(false)">New Adventure Wall</button>
          </div>
        </div>
        <div class="six wide column">
          <img class="ui small image" style="width: 100%" src="https://cdn.shrpa.com/images/misc/integrations/AdventureWall-med.jpg" />
        </div>
      </div>

      <label class="cms-section-title" style="margin-top: 50px !important">Other Settings</label>
      <p>
        <span v-if="integrationSettings" class="ui toggle checkbox">
          <input type="checkbox" :disabled="isSaving" v-model="integrationSettings.openAdventuresInNewTab" @change="otherSettingsChanged" />
          <label>Open adventures in a new browser tab/window.</label>
        </span>
      </p>
      <p v-if="justSaved">
        <b>Settings Saved!</b>
      </p>

      <label class="cms-section-title" style="margin-top: 1em !important">What do I do with that code?</label>
      <p>
        It depends what your site is build on (Wordpress, Simpleview, etc...)
        <br />
        Here's a full walkthrough!
        <br />
        <a style="text-decoration: underline" target="_blank" href="https://explore.shrpa.com/integrations/how-to-integrate-adventures-on-your-site/"
          >https://explore.shrpa.com/integrations/how-to-integrate-adventures-on-your-site/</a
        >
      </p>

      <br />
      <label class="cms-section-title">Questions?</label>
      <p>
        Having issues integrating this into your site? If you or your developer have any questions we're happy to help! <br />
        Email us anytime at <CopyText />
      </p>
    </div>

    <!--Create Modal-->
    <SrpModal v-model:isVisible="showCreateModal" :isClosable="false">
      <template #header><h2 class="global-h2">What to name it?</h2></template>
      <template #content>
        What's a short name you can use to tell this {{ creatingPhotoWidget ? "photo wall" : "adventure wall" }} apart from others?
        <input type="text" v-model="newGroupingName" style="max-width: 300px" />
      </template>
      <template #footer>
        <div class="ui basic black button" @click="cancelCreate()">Cancel</div>
        <div class="ui primary button" @click="createNewGrouping()">{{ isCreatingNewGroup ? "Creating..." : "Create It" }}</div>
      </template>
    </SrpModal>

    <!--Delete Modal-->
    <SrpModal v-model:isVisible="showDeleteModal" :isClosable="false">
      <template #header><h2 class="global-h2">Delete Adventure Wall</h2></template>
      <template #content>
        Are you sure you want to delete this adventure wall? <br />
        This action cannot be undone.
      </template>
      <template #footer>
        <div class="ui basic black button" @click="cancelDelete()">Cancel</div>
        <SrpButton class="ui negative button" :isDisabled="isDeletingList" @click="deleteList()">Delete</SrpButton>
        <span v-if="isDeletingList"><b>Deleting...</b></span>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import OrgContext from "@logic/OrgContext";
import { PartnerNavInfo } from "@contracts/partnerNavInfo";
import CopyText from "@components/CopyText.vue";
import ListRepo from "@logic/ListRepo";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useHead } from "@unhead/vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Adventure Walls
export default defineComponent({
  name: "AdventureWidgets",

  components: {
    SrpButton,
    SrpModal,
    Loader,
    CopyText,
    LinkWithIcon,
    IconEmbedded,
  },

  data() {
    return {
      title: "Adventure Walls",
      pageId: "",
      orgInContext: null as PartnerNavInfo,
      // Custom Site Integrations
      customGroupings: null,
      // Other Settings
      integrationSettings: null,
      code: "",
      justCopied: false,
      isSaving: false,
      justSaved: false,
      isLoading: true,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isHotel: false,

      showCreateModal: false,
      showDeleteModal: false,
      creatingPhotoWidget: false,
      isCreatingNewGroup: false,
      newGroupingName: "",
      listIdToDelete: null,
      isDeletingList: false,
    };
  },

  computed: {},

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.pageId = this.$route.params.pageId;
    this.isHotel = OrgContext.isOrgInContextHotel(this);
    this.orgInContext = OrgContext.getOrgInContext(this);
    this.title += " - " + this.orgInContext?.name;
    this.loadData();
  },

  methods: {
    async loadData() {
      this.isLoading = true;
      // Custom Groupings
      const groupingsResponse = await axios.get(`${import.meta.env.VITE_API_URL}/widget-admin/${this.pageId}/widgets/summary`);
      this.customGroupings = groupingsResponse.data;

      // Widget and Subscription Settings
      const settingsResponse = await axios.get(`${import.meta.env.VITE_API_URL}/widget-admin/${this.pageId}/widgets/settings`);
      this.integrationSettings = settingsResponse.data.widgetSettings;

      this.isLoading = false;
    },
    async otherSettingsChanged(event, value: boolean) {
      this.isSaving = true;
      await axios.put(`${import.meta.env.VITE_API_URL}/widget-admin/${this.pageId}/widgets/settings?openInNewWindow=${this.integrationSettings.openAdventuresInNewTab}`);
      this.justSaved = true;
      this.isSaving = false;
      setTimeout(() => (this.justSaved = false), 5000);
    },
    async copy(groupId) {
      let groupCode = groupId ? `--${groupId}` : "";
      // Note: Typescript just couldn't handle the /s in the string below so I threw in the unnecessary concat after fighting with it for too long
      let code = `<script id="shrpa-adv__${this.pageId}${groupCode}" src="https://shrpa.com/widget/adv.js" async></` + `script>`;
      await navigator.clipboard.writeText(code);

      this.justCopied = true;
      setTimeout(() => {
        this.justCopied = false;
      }, 5000);
    },
    viewCommunityPage() {
      this.$router.push({ name: "Pages", params: { pageId: this.orgInContext.uniqueName } });
    },
    askToCreate(isPhotoWall: boolean) {
      this.creatingPhotoWidget = isPhotoWall;
      this.showCreateModal = true;
    },
    cancelCreate() {
      this.showCreateModal = false;
    },
    async createNewGrouping() {
      if (this.isCreatingNewGroup) return;
      if (!this.newGroupingName || this.newGroupingName.length == 0) return;

      this.isCreatingNewGroup = true;
      // Adventure Integration
      var listId = await ListRepo.createSiteIntegrationList(this.pageId, this.newGroupingName);
      // Redirect
      this.$router.push({ name: "CustomIntegration", params: { pageId: this.pageId, listId: listId } });
    },
    askToDelete(listIdToDelete: string) {
      this.listIdToDelete = listIdToDelete;
      this.showDeleteModal = true;
    },
    cancelDelete() {
      this.listIdToDelete = null;
      this.showDeleteModal = false;
    },
    async deleteList() {
      this.isDeletingList = true;
      await ListRepo.deleteSiteIntegrationList(this.pageId, this.listIdToDelete);
      this.customGroupings.splice(
        this.customGroupings.findIndex(g => g.id === this.listIdToDelete),
        1
      );
      this.isDeletingList = false;
      this.showDeleteModal = false;
    },
  },
});
</script>

<style scoped lang="scss">
.adventure-wall-link {
  & > :deep(span),
  & > :deep(span > span) {
    text-decoration: none;
  }

  & > :deep(span b) {
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: rgba(0, 0, 0, 0.3);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }

  &:hover > :deep(span b) {
    text-decoration: none;
  }
}

.limit-hit-message {
  margin-top: 10px;
}
</style>
