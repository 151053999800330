<template>
  <span v-if="globalRoot.settings.enableSharing">
    <!--Button Bar-->
    <span v-if="useButtonBar">
      <div v-if="!useWebShareApi" style="display: flex; justify-content: center; align-items: center">
        <a @click.prevent="facebookShareClick" target="_blank"><IconEmbedded class="icon-button" name="facebook" /></a>
        <IconEmbedded @click.prevent="emailCurrentPage" class="icon-button" name="envelope_2-5" />
        <IconEmbedded @click.prevent="copyCurrentUriToClipboard" class="icon-button" name="link_2-5" />
      </div>
      <div v-if="useWebShareApi" @click.prevent="fireWebShareApi" class="ui large orange button">Share</div>
    </span>

    <!--Button to fire the modal-->
    <button v-else-if="!parentOwnsHideShow" @click.prevent="show" class="ui basic primary button">Share</button>

    <!--Share Modal-->
    <SrpModal :isVisible="shouldShowModal" @close="close" size="small" :zIndex="400" :isContentPaddings="false" max-height="calc(100% - 100px)">
      <template #content>
        <div style="padding-top: 19px; display: flex; flex-direction: column; align-items: center">
          <img class="shrpa-logo" src="https://cdn.shrpa.com/images/shrpa-full-color.png" alt="Shrpa logo" />
          <div class="published-content">
            <span v-if="hasHotelInContextComputed">
              <!--Hotel POC-->
              <p class="wrapper">Share Experiences by</p>
              <img :src="`${contentBaseUri}/cms/images/orgs/${globalRoot.orgInContext.logoUri}`" alt="partner logo" class="partner-img" />
            </span>
            <span v-else>
              <p class="wrapper" style="text-align: center">Sharing options for:</p>
              <p v-if="entityName && entityName.length > 0" class="wrapper2">{{ entityName }}</p>
            </span>
            <p v-if="sentMessage && sentMessage.length > 0" class="wrapper" style="text-align: center">{{ sentMessage }}</p>
            <h3 v-if="hasHotelInContextComputed">With Guests</h3>
            <div @click.prevent="copyCurrentUriToClipboard" class="ui primary button share-button">
              <span class="button-inner">
                <IconEmbedded class="icon-button" name="link_2-5" />
                Copy Link
              </span>
            </div>
            <div v-if="webShareApiIsAvailable" @click.prevent="fireWebShareApi" class="ui primary button share-button">
              <span class="button-inner"><!--<div name="share" class="large icon-button"></div>-->Share via Device</span>
            </div>
            <div @click.prevent="facebookShareClick" class="ui primary button share-button">
              <span class="button-inner">
                <IconEmbedded class="icon-button" name="facebook" />
                Facebook
              </span>
            </div>
            <br />
          </div>
        </div>
      </template>
    </SrpModal>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { MetricSender } from "@helpers/MetricSender";
import axios from "axios";
import OrgContext from "@logic/OrgContext";
import SrpModal from "@components/ui/SrpModal.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "ShareButton",
  components: { IconEmbedded, SrpModal },

  props: {
    useButtonBar: { default: false },
    sourceContext: { default: "unknown" },
    useIcon: { default: false },
    // If false, the consumer must specify an entityId
    shareCurrentPageUri: { default: true },
    entityName: { default: "" },
    entityIdToShare: { type: String as PropType<string | null>, required: false, default: null },
    // NOTE!  For the modal to work on the MyItineraries page we needed
    // to have a dual mode where either this control or the page could control the button.
    // Ideally should find a better way to do this
    parentOwnsHideShow: { default: false },
    showModal: { default: false },
  },

  emits: ["close"],

  data() {
    return {
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      webShareApiIsAvailable: false,
      // Just used by the older ButtonBar logic
      useWebShareApi: false,
      localShowShareModal: false,
      showEmailSection: false,
      emailAddress: "",
      showPhoneSection: false,
      phoneNumber: "",
      sentMessage: "",
    };
  },

  computed: {
    shouldShowModal(): boolean {
      if (this.parentOwnsHideShow) {
        return this.showModal;
      } else {
        return this.localShowShareModal;
      }
    },
    // Currently we pivot some fields based on this
    hasHotelInContextComputed(): boolean {
      // Note: This won't be properly reactive since we're calling a method, which should be fine here...
      return OrgContext.isOrgInContextHotel(this);
    },
  },

  async mounted() {
    // Note: navigator.share only works over https
    if (navigator.share) {
      this.webShareApiIsAvailable = true;
      // Update! Chrome now defauts to the built-in Windows Share which isn't what we want...
      if (this.getOS() !== "Windows") {
        this.useWebShareApi = true;
      }
    }
  },

  methods: {
    clearFields() {
      this.showEmailSection = false;
      this.showPhoneSection = false;
      this.emailAddress = "";
      this.phoneNumber = "";
      this.sentMessage = "";
    },
    show() {
      this.clearFields();
      this.localShowShareModal = true;
    },
    close() {
      this.localShowShareModal = false;
      this.$emit("close");
    },
    showEmail() {
      this.clearFields();
      this.showEmailSection = true;
    },
    showPhone() {
      this.clearFields();
      this.showPhoneSection = true;
    },
    async sendText() {
      // Server validation
      if (this.phoneNumber && this.phoneNumber.length > 0) {
        var hotelParam = "";
        let orgInContext = OrgContext.getOrgInContext(this);
        if (OrgContext.isHotel(orgInContext)) {
          hotelParam = `&hotelId=${orgInContext?.customerId}`;
        }
        const { data } = await axios.post(`${import.meta.env.VITE_API_URL}/sms?itineraryId=${this.entityIdToShare}&phone=${this.phoneNumber}${hotelParam}`);
        if (data.length === 0) {
          this.sentMessage = `Sent to ${this.phoneNumber}!`;
          this.showPhoneSection = false;
        } else {
          // Error
          this.sentMessage = data;
        }
      }
    },
    fireWebShareApi() {
      this.clearFields();
      // Uses the mobile OS's built-in share feature
      navigator.share({
        title: document.title,
        url: this.getUriToShare(),
      });
      MetricSender.share("webshareapi", this.getUriToShare());
    },
    getFacebookShareLink() {
      let currentPage = this.getUriToShare();
      if (currentPage.startsWith("http://localhost")) {
        currentPage = currentPage.replace("http://localhost:8080", "https://dev.shrpa.com");
      }
      return `https://www.facebook.com/sharer/sharer.php?u=${currentPage}`;
    },
    facebookShareClick() {
      this.clearFields();
      window.open(this.getFacebookShareLink());
      MetricSender.share("facebook", this.getUriToShare());
    },
    copyCurrentUriToClipboard() {
      this.clearFields();
      if (!navigator.clipboard) {
        console.error("navigator.clipboard not supported");
        return;
      }
      let text = this.getUriToShare();
      navigator.clipboard.writeText(text).then(
        () => {
          console.info("Copied to clipboard: " + text);
          this.sentMessage = "Copied!";
        },
        function (err) {
          console.error("Failed to copy to clipboard: ", err);
        }
      );

      MetricSender.share("clipboard", this.getUriToShare());
    },
    emailCurrentPage() {
      // Note: Only used for the ButtonBar format currently (the Just Published flow)
      MetricSender.share("email", this.getUriToShare());
      window.location.href = "mailto:?subject=" + escape(document.title) + "&body=" + escape(this.getUriToShare());
    },

    getUriToShare(): string {
      if (this.shareCurrentPageUri) {
        return window.location.href;
      } else {
        // Just assume entityId is an Itinerary
        // @ts-ignore
        const domain = Bootstrap.Config.isDev ? "https://dev.shrpa.com" : "https://shrpa.com";
        return `${domain}/itinerary/${this.entityIdToShare}`;
      }
    },
    getOS(): string {
      // https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
      } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
      }
      return os;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Icon button ================================================================
.icon-button {
  width: 28px;
  height: 28px;
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  user-select: none;

  &::before {
    color: #fff;
    font-size: 28px;
    line-height: 28px;
  }

  &::after {
    content: "";
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 100px;
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition:
      width 0.07s ease-in-out,
      height 0.07s ease-in-out,
      opacity 0.07s ease-in-out;
  }

  &:hover::after {
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    opacity: 1;
  }
}

.social {
  font-size: 2.5em !important;
  margin: 0 5px;
}

/* Modal Styles */
#published-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.5);
}

#published {
  background-color: #eeeeee;
  display: block;
  justify-content: space-around;
  text-align: center;
  font-family: "Quicksand", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  max-width: 420px !important;
  min-height: 750px;
  height: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shrpa-logo {
  padding: 2rem 0 0;
  background: white;
}

@media screen and (max-width: 420px) {
  #published-wrapper {
    background-color: #eeeeee;
  }

  #published {
    max-width: 100% !important;
    width: 100% !important;
    height: 100%;
    min-height: auto;
    margin-top: 0% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
    padding-top: 1.25rem !important;
  }
}

.published-header {
  position: relative;
  height: 50px;
}

.header-over {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
}

.shrpa-logo {
  height: 70px;
  padding: 0;
  margin-bottom: 20px;
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .shrpa-logo {
    height: 60px;
  }
}

.published-content {
  width: 100%;
  padding: 18px 25px 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
}

.close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.wrapper {
  font-size: 22px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 0;
}

.wrapper2 {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 16px;
}

.share-button {
  min-height: 50px;
  min-width: 200px;
  margin: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.button-inner {
  display: flex;
  align-items: center;

  .icon-button {
    margin-left: 0;
  }
}

.link {
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  text-decoration: underline;
}

.image-content {
  display: block;
  text-align: center;
  margin: 0 auto;
  height: 230px;
  padding: 12px 24px;
}

.image-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
