<template>
  <div class="recent-creator-visits">
    <div class="title-n-stats__title">Recent Shrpa Creator Visits</div>
    <!-- Title & stats
    <div class="title-n-stats recent-creator-visits__title-n-stats">
      <div class="title-n-stats__title">Recent Shrpa Creator Visits</div>
      <ul class="title-n-stats__stats-table">
        <li class="main-stats-cell title-n-stats__stats-cell" v-for="statsItem in stats" :key="statsItem.description">
          <div v-if="!['tablet', 'mobile'].includes(screenSize)" class="main-stats-cell__icon-wrap">
            <img class="main-stats-cell__icon" :src="`/images/highlightsIllustrations/${statsItem.icon}.svg`" :alt="statsItem.description" />
          </div>
          <div class="main-stats-cell__text-side">
            <div class="main-stats-cell__number">
              <div v-if="['tablet', 'mobile'].includes(screenSize)" class="main-stats-cell__icon-wrap">
                <img class="main-stats-cell__icon" :src="`/images/highlightsIllustrations/${statsItem.icon}.svg`" :alt="statsItem.description" />
              </div>
              {{ statsItem.number.toLocaleString(undefined) }}
            </div>
            <div class="main-stats-cell__description">{{ statsItem.description }}</div>
          </div>
        </li>
      </ul>
      <div class="title-n-stats__bg-image-wrap title-n-stats__bg-image-wrap--lower-z-index">
        <img class="title-n-stats__bg-image-blurred" src="https://cdn.shrpa.com/images/backgrounds/recent-creator-visits-bg-image-blurred4.jpg" alt="bg landscape" />
      </div>
      <div class="title-n-stats__bg-image-wrap">
        <img class="title-n-stats__bg-image" src="https://cdn.shrpa.com/images/backgrounds/recent-creator-visits-bg-image.jpg" alt="bg landscape" />
      </div>
    </div>
       / Title & stats -->

    <Loader v-if="isLoading" class="recent-creator-visits__loader" />

    <ul v-else class="recent-creator-visits__visit-summaries-list">
      <CollabSummarySection
        class="recent-creator-visits__summary-section"
        v-for="(summarySection, index) in summarySections"
        :key="summarySection.itineraries"
        :summarySection="summarySection"
        isVisitDateVisible
      />
    </ul>

    <div class="recent-creator-visits__foot-cta-snippets-list">
      <!-- Shrpa round logo -->
      <div class="shrpa-round-logo recent-creator-visits__srpa-round-logo">
        <img class="shrpa-round-logo__img" src="https://cdn.shrpa.com/images/shrpa_logo_white.png" alt="Shrpa" />
      </div>
      <!-- / Shrpa round logo -->

      <!-- Foot cta snippet -->
      <div class="foot-cta-snippet recent-creator-visits__foot-cta-snippet">
        <div class="global-h2 foot-cta-snippet__title">Destinations in need of authentic content?</div>
        <SrpButton class="foot-cta-snippet__cta-button" color="orange" size="big" :href="globalGetLink('ForCommunities')">
          <template #icon><IconEmbedded name="aperture_2" :size="27" color="rgba(255, 255, 255, 0.8)" /></template>
          &nbsp;Learn More
        </SrpButton>
      </div>
      <!-- / Foot cta snippet -->

      <!-- Foot cta snippet -->
      <div class="foot-cta-snippet recent-creator-visits__foot-cta-snippet">
        <div class="global-h2 foot-cta-snippet__title">Creators interested in collaborating with destinations?</div>
        <SrpButton class="foot-cta-snippet__cta-button" color="orange" size="big" :href="globalGetLink('BecomingACreator')">
          <template #icon><IconEmbedded name="zap_2" :size="30" color="rgba(255, 255, 255, 0.8)" /></template>
          &nbsp;Creator Onboarding
        </SrpButton>
      </div>
      <!-- / Foot cta snippet -->
    </div>
  </div>
</template>

<!-- prettier-ignore -->
<!--<script lang="ts">export default { name: "CollabSummaryPublic" };</script>-->

<script lang="ts">
import axios from "axios";
import { defineComponent, inject } from "vue";
import { getInstance } from "@auth/authWrapper";

// Types
import { ItinerarySummary } from "@contracts/itinerary";
import { ScreenSize } from "@contracts/screenSize";

// Components
import ItineraryTile from "@components/ItineraryTile.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import CreatorAvatar from "@components/CreatorAvatar.vue";
import Loader from "@components/Loader/Loader.vue";
import { RouteHelper } from "@helpers/RouteHelper";
import HighlightsSection from "@components/HighlightsSection.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { useHead } from "@unhead/vue";
import StatNumbersBanner from "@components/StatNumbersBanner.vue";
import DateUtils from "@logic/DateUtils";
import CollabSummarySection from "@components/CollabSummarySection.vue";

export default defineComponent({
  name: "RecentCreatorVisits",

  components: { CollabSummarySection, StatNumbersBanner, IconEmbedded, HighlightsSection, Loader, CreatorAvatar, ItineraryTile, SrpButton },

  data() {
    return {
      globalGetLink: inject("globalGetLink") as any,
      globalLog: inject("globalLog") as any,

      screenSize: inject("screenSize") as ScreenSize,

      DateUtils: DateUtils,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isLoading: true,
      title: "Recent Creator Visits - Shrpa",
      pageDetailsNotFound: false,

      stats: [
        { icon: "treasuremap", number: 350, description: "Creator Visits Completed" },
        { icon: "thumbsup", number: 300, description: "Upcoming Visits" },
        { icon: "mapmark", number: 4000, description: "Locations Highlighted" },
        { icon: "photocamera", number: 35000, description: "Photos and Videos" },
      ],

      summarySections: [],
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "", meta: [{ name: "description", content: () => this.description ?? "" }] });

    var returnCountString = RouteHelper.getQueryStringParam("returnCount");
    var state = RouteHelper.getQueryStringParam("state");
    // If they pass in a requestCount, wait for auth to complete
    if (returnCountString || state) {
      this.globalLog.info(`CreatorVisits with returnCount=${returnCountString}, state=${state}`);
      const returnCount = returnCountString ? parseInt(returnCountString) : null;
      let authService = getInstance();
      if (authService.loading === true) {
        authService.$watch("loading", loading => {
          if (loading === false) {
            this.loadPageDetails(returnCount, state);
          }
        });
      } else {
        await this.loadPageDetails(returnCount, state);
      }
    } else {
      await this.loadPageDetails(null, null);
    }
  },

  methods: {
    loadPageDetails: async function (returnCount?: number, state?: string | null): Promise<void> {
      try {
        let queryParam = "?homepage=false";
        if (returnCount) queryParam += `&returnCount=${returnCount}`;
        if (state) queryParam += `&state=${state}`;
        const uri = `${import.meta.env.VITE_API_URL}/collabsummary/recent` + queryParam;

        const response = await axios.get(uri);
        this.summarySections = response.data;

        this.title = response.data.page?.title || this.title;

        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        if (err.response.status == 404) {
          this.pageDetailsNotFound = true;
          return;
        }
        return;
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Main Stats cell ============================================================
.main-stats-cell {
  display: flex;

  &__icon {
    width: 70px;
    height: 70px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text-side {
    outline: none;
  }

  &__number {
    margin-bottom: 7px;
    color: #058587;
    font:
      500 55px/55px "Quicksand",
      sans-serif;
  }

  &__description {
    color: rgba(0, 0, 0, 0.5);
    font: 20px/25px sans-serif;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .main-stats-cell {
    &__icon {
      width: 55px;
      height: 55px;
    }

    &__number {
      margin-bottom: 1px;
      font-size: 47px;
      line-height: 47px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .main-stats-cell {
    &__icon {
      width: 55px;
      height: 55px;
    }

    &__number {
      margin-bottom: 1px;
      font-size: 47px;
      line-height: 47px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .main-stats-cell {
    display: flex;
    justify-content: center;

    &__icon {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    &__text-side {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__number {
      margin-bottom: 3px;
      display: flex;
      align-items: center;
      font:
        500 45px/45px "Quicksand",
        sans-serif;
    }

    &__description {
      font: 17px/21px sans-serif;
      text-align: center;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .main-stats-cell {
    display: flex;
    justify-content: center;

    &__icon {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    &__text-side {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__number {
      margin-bottom: 3px;
      display: flex;
      align-items: center;
      font:
        500 35px/35px "Quicksand",
        sans-serif;
    }

    &__description {
      max-width: 70%;
      font: 16px/19px sans-serif;
      text-align: center;
    }
  }
}

// Title & stats ==============================================================
.title-n-stats {
  padding: 135px 0 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
  overflow: hidden;

  &__title {
    margin-top: 80px;
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 65px;
    line-height: 72px;
    text-align: center;
  }

  &__stats-table {
    width: calc(384px * 2 + 70px);
    padding: 35px 0 35px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    background: rgba(255, 255, 255, 0.85);
    //backdrop-filter: blur(25px); // Caused issues when lots of photos
    box-shadow: 0 30px 50px -10px rgba(0, 0, 0, 0.55);
  }

  &__stats-cell {
    width: 384px;
    padding: 10px 0 35px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    &:nth-child(odd) {
      border-right: 1px rgba(0, 0, 0, 0.1) solid;
    }
    &:nth-child(n + 3) {
      padding: 30px 0 15px;
      border-bottom: none;
    }
    &:nth-child(even) {
      padding-left: 40px;
    }
  }

  &__bg-image-wrap {
    width: 100%;
    height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: auto auto 0 0;
    z-index: -1;
    pointer-events: none;

    &--lower-z-index {
      z-index: -2;
    }
  }

  &__bg-image-blurred,
  &__bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__bg-image-blurred {
    outline: none;
  }

  &__bg-image {
    outline: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .title-n-stats {
    padding: 85px 0 115px;

    &__title {
      margin-bottom: 70px;
      font-size: 45px;
      line-height: 53px;
    }

    &__stats-table {
      width: calc(351px * 2 + 60px);
      padding: 33px 0 30px;
    }

    &__stats-cell {
      width: 351px;
      padding: 5px 0 25px;

      &:nth-child(n + 3) {
        padding: 20px 0 10px;
        border-bottom: none;
      }
      &:nth-child(even) {
        padding-left: 30px;
      }
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .title-n-stats {
    padding: 85px 0 115px;

    &__title {
      margin-bottom: 70px;
      font-size: 45px;
      line-height: 53px;
    }

    &__stats-table {
      width: calc(351px * 2 + 60px);
      padding: 33px 0 30px;
    }

    &__stats-cell {
      width: 351px;
      padding: 5px 0 25px;

      &:nth-child(n + 3) {
        padding: 20px 0 10px;
        border-bottom: none;
      }
      &:nth-child(even) {
        padding-left: 30px;
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .title-n-stats {
    padding: 85px 0 115px;

    &__title {
      margin-bottom: 50px;
      font-size: 45px;
      line-height: 53px;
    }

    &__stats-table {
      width: calc(351px * 2 + 60px);
      padding: 33px 0 30px;
    }

    &__stats-cell {
      width: 351px;
      padding: 5px 0 25px;

      &:nth-child(n + 3) {
        padding: 20px 0 10px;
        border-bottom: none;
      }
      &:nth-child(even) {
        padding-left: 30px;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .title-n-stats {
    padding: 85px 0 105px;

    &__title {
      margin-bottom: 50px;
      font-size: 36px;
      line-height: 42px;
    }

    &__stats-table {
      width: calc(235px * 2 + 32px);
      padding: 23px 0 22px;
    }

    &__stats-cell {
      width: 235px;
      padding: 0 0 20px;

      &:nth-child(n + 3) {
        padding: 20px 0 10px;
        border-bottom: none;
      }
      &:nth-child(even) {
        padding-left: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .title-n-stats {
    padding: 50px 0 75px;

    &__title {
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 30px;
      line-height: 33px;
    }

    &__stats-table {
      width: calc(100% - 36px);
      padding: 20px 0 18px;
    }

    &__stats-cell {
      width: calc(50% - 18px);
      padding: 0 0 15px;

      &:nth-child(n + 3) {
        padding: 15px 0 5px;
        border-bottom: none;
      }
      &:nth-child(even) {
        padding-left: 0;
      }
    }
  }
}

// Stats cell =================================================================
.stats-cell {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    width: 1px;
    height: calc(100% - 26px);
    position: absolute;
    inset: 13px -1px auto auto;
    background: rgba(0, 0, 0, 0.1);
  }

  &__number {
    margin-right: 23px;
    color: #118689;
    font:
      500 40px/40px "Quicksand",
      sans-serif;
  }

  &__text {
    font:
      400 20px/23px "Quicksand",
      sans-serif;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .stats-cell {
    &__number {
      font-size: 40px;
      line-height: 40px;
    }
    &__text {
      font-size: 20px;
      line-height: 23px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .stats-cell {
    &__number {
      font-size: 30px;
      line-height: 30px;
    }
    &__text {
      font-size: 20px;
      line-height: 23px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .stats-cell {
    &__number {
      margin-right: 15px;
      font-size: 30px;
      line-height: 30px;
    }
    &__text {
      font-size: 17px;
      line-height: 21px;
    }
  }
}

// Foot Cta snippet ===========================================================
.foot-cta-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    max-width: 70%;
    margin-bottom: 28px;
    text-align: center;
  }

  &__cta-button {
    outline: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .foot-cta-snippet {
    &__title {
      max-width: 100%;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .foot-cta-snippet {
    &__title {
      margin-bottom: 15px;
    }
  }
}

// Shrpa round logo ===========================================================
.shrpa-round-logo {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bcc2c5;

  &__img {
    width: 42%;
    position: relative;
    top: -1px;
  }
}

// Recent creator visits ======================================================
.recent-creator-visits {
  &__title-n-stats {
    margin-bottom: 150px;
  }

  &__loader {
    margin-bottom: 150px;
  }

  &__visit-summaries-list {
    width: 1269px;
    gap: 50px;
    padding: 0;
    margin: 0 auto 150px;
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  &__summary-section {
    padding-bottom: 60px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    &:last-child {
      border-bottom: none;
    }
  }

  &__foot-cta-snippets-list {
    margin: 0 auto;
    padding: 95px 0 115px;
    display: flex;
    justify-content: center;
    position: relative;
    background: #e7edf0;

    &::before {
      content: "";
      width: 1px;
      height: calc(100% - 67px - 93px);
      position: absolute;
      inset: 67px auto auto 50%;
      background: rgba(0, 0, 0, 0.15);
    }
  }

  &__foot-cta-snippet {
    width: calc(1269px / 2 - 15px);
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__srpa-round-logo {
    position: absolute;
    inset: auto auto -35px calc(50% - 35px);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .recent-creator-visits {
    &__visit-summaries-list {
      width: 1104px;
    }

    &__foot-cta-snippet {
      width: calc(1104px / 2 - 12px);
      margin-right: 24px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .recent-creator-visits {
    &__title-n-stats {
      margin-bottom: 100px;
    }

    &__visit-summaries-list {
      width: 762px;
      margin-bottom: 110px;
    }

    &__summary-section {
    }

    &__foot-cta-snippet {
      width: calc(762px / 2 - 12px) !important;
      margin-right: 25px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .recent-creator-visits {
    &__title-n-stats {
      margin-bottom: 60px;
    }

    &__visit-summaries-list {
      width: 502px;
      margin-bottom: 70px;
    }

    &__summary-section {
    }

    &__foot-cta-snippets-list {
      padding: 60px 0 75px;
      flex-direction: column;
      align-items: center;

      &::before {
        display: none;
      }
    }

    &__foot-cta-snippet {
      width: 502px;
      margin: 0 0 70px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:last-child::before {
        content: "";
        width: 502px;
        height: 1px;
        position: absolute;
        inset: -35px auto auto calc(50% - 251px);
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .recent-creator-visits {
    &__title-n-stats {
      margin-bottom: 60px;
    }

    &__visit-summaries-list {
      width: calc(100% - 36px);
      margin-bottom: 30px;
    }

    &__summary-section {
    }

    &__foot-cta-snippets-list {
      padding: 55px 0 80px;
      flex-direction: column;
      align-items: center;

      &::before {
        display: none;
      }
    }

    &__foot-cta-snippet {
      width: calc(100% - 36px);
      margin: 0 0 60px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:last-child::before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        inset: -25px auto auto 0;
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
</style>
