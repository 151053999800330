<template>
  <div>
    <div v-if="pageDetailsNotFound" class="stackable very relaxed ui grid container">
      <br />
      <h4>
        Sorry, we couldn't find that community. <br /><br />
        Email us at support@shrpa.com if you think you found a bug!
      </h4>
    </div>

    <div v-else>
      <div v-if="isLoading"><Loader /></div>
      <div v-if="pageDetails">
        <div v-if="pageDetails.page.type === 'Organization'">
          <div class="content-wrapper">
            <div class="stackable very relaxed ui grid container">
              <div class="row">
                <div class="five wide column">
                  <a v-if="pageDetails.page.website" lang="en" hreflang="en" target="_blank" :href="pageDetails.page.website"
                    ><img class="ui image" :src="`${contentBaseUri}/cms/images/orgs/${pageDetails.page.icon}`"
                  /></a>
                  <img v-else class="ui image" :src="`${contentBaseUri}/cms/images/orgs/${pageDetails.page.icon}`" />
                  <!-- This position looks better for the Follow button on Desktop, but worse on mobile. It really depends on how much text you have in pageDetails.page.description -->
                  <!-- <div id="follow-button-wrapper">
                                <div @click="toggleFollow" class="ui basic orange button">{{isFollowed ? 'Unfollow' : 'Follow'}}
                                    Need to get the abbreviated pageDetails.page.title for the button or just punt and keep it as a generic "Follow"
                                </div>
                            </div> -->
                  <span v-if="pageDetails.page.inPartnershipWithIcon">
                    <h3 class="ui header" style="margin-top: 20px; text-align: center">
                      {{ pageDetails.page.inPartnershipWithTextOverride === null ? "A Co-op Program with" : pageDetails.page.inPartnershipWithTextOverride }}
                    </h3>
                    <a v-if="pageDetails.page.inPartnershipWithSite" lang="en" hreflang="en" target="_blank" :href="pageDetails.page.inPartnershipWithSite">
                      <img
                        class="ui image coop-logo"
                        :src="
                          pageDetails.page.inPartnershipWithIcon.startsWith('http')
                            ? pageDetails.page.inPartnershipWithIcon
                            : `${contentBaseUri}/cms/images/partners/${pageDetails.page.inPartnershipWithIcon}`
                        "
                      />
                    </a>
                    <img
                      v-else
                      class="ui image coop-logo"
                      :src="
                        pageDetails.page.inPartnershipWithIcon.startsWith('http')
                          ? pageDetails.page.inPartnershipWithIcon
                          : `${contentBaseUri}/cms/images/partners/${pageDetails.page.inPartnershipWithIcon}`
                      "
                    />
                  </span>
                </div>
                <div class="eleven wide column">
                  <router-link v-if="canEdit" :to="getEditRoute()" class="ui small basic primary right floated button" style="margin-top: 30px">Edit</router-link>
                  <h1 class="global-h1" style="margin-bottom: 8px">
                    {{ pageDetails.page.title }}
                  </h1>
                  <p style="white-space: pre-line">
                    <span v-if="pageDetails.page.description?.length > 0">
                      <ClampedText :visibleLines="17">
                        {{ pageDetails.page.description }}
                      </ClampedText>
                    </span>
                    <span v-else-if="!pageDetails.page.website">Coming soon...</span>
                  </p>
                  <div class="organization">
                    <a class="ui header" v-if="pageDetails.page.website" lang="en" hreflang="en" target="_blank" :href="pageDetails.page.website">
                      {{ pageDetails.page.websiteText && pageDetails.page.websiteText.trim().length > 0 ? pageDetails.page.websiteText : pageDetails.page.website }}
                    </a>
                  </div>
                  <div class="social-links" style="position: relative; z-index: 9; display: flex">
                    <SocialMediaLinksBar :socialFields="pageDetails.page" />
                  </div>

                  <h2 class="ui header" v-if="pageDetails.sherpas.length > 0">Contributing creators</h2>
                  <div class="ui grid creators-wrapper">
                    <div class="row">
                      <router-link
                        class="five wide column contributing-sherpa hide-on-mobile"
                        v-for="sherpa in pageDetails.sherpas.slice(0, 3)"
                        :key="'desktop' + sherpa.sherpaId"
                        lang="en"
                        hreflang="en"
                        :to="{ name: 'CreatorProfilePublic', params: { creatorId: sherpa.uriKey } }"
                      >
                        <img class="ui centered circular tiny image" :src="sherpa.profileImageUri" />
                        <p>{{ sherpa.firstName }}</p>
                      </router-link>
                      <router-link
                        class="eight wide column contributing-sherpa hide-on-desktop"
                        v-for="sherpa in pageDetails.sherpas.slice(0, 3)"
                        :key="'mobile' + sherpa.sherpaId"
                        lang="en"
                        hreflang="en"
                        :to="{ name: 'CreatorProfilePublic', params: { creatorId: sherpa.uriKey } }"
                      >
                        <img class="ui centered circular tiny image" :src="sherpa.profileImageUri" />
                        <p>
                          {{ sherpa.firstName }}
                        </p>
                      </router-link>
                    </div>
                    <div class="row" :class="{ hidden: !toggled }">
                      <router-link
                        class="five wide column contributing-sherpa hide-on-mobile"
                        v-for="sherpa in pageDetails.sherpas.slice(3)"
                        :key="'desktop' + sherpa.sherpaId"
                        lang="en"
                        hreflang="en"
                        :to="{ name: 'CreatorProfilePublic', params: { creatorId: sherpa.uriKey } }"
                      >
                        <img class="ui centered circular tiny image" :src="sherpa.profileImageUri" />
                        <p>
                          {{ sherpa.firstName }}
                        </p>
                      </router-link>
                      <router-link
                        class="eight wide column contributing-sherpa hide-on-desktop"
                        v-for="sherpa in pageDetails.sherpas.slice(3)"
                        :key="'mobile' + sherpa.sherpaId"
                        lang="en"
                        hreflang="en"
                        :to="{ name: 'CreatorProfilePublic', params: { creatorId: sherpa.uriKey } }"
                      >
                        <img class="ui centered circular tiny image" :src="sherpa.profileImageUri" />
                        <p>
                          {{ sherpa.firstName }}
                        </p>
                      </router-link>
                    </div>
                    <div class="toggle-text" v-if="pageDetails.sherpas.length > 3" @click.prevent.stop="onToggle">
                      {{ toggled ? "Collapse" : "Show more" }}
                    </div>
                  </div>
                  <!--</div> -->
                </div>

                <!--<div class="six wide column" id="follow-button-wrapper">-->
                <!--  <div @click="toggleFollow" class="ui basic orange button">{{ isFollowed ? "Unfollow" : "Follow" }}</div>-->
                <!--</div>-->
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div>
            <div class="expedition-hero" :style="`background-image: url(${contentBaseUri}/cms/images/expeditions/${pageDetails.page.primaryImage})`">
              <div class="expedition-overlay wow fadeIn">
                <h1 class="ui inverted header header-title">{{ pageDetails.page.title }}</h1>
              </div>
            </div>

            <div class="stackable very relaxed ui grid container" style="clear: left">
              <div class="row">
                <div class="six wide column">
                  <div class="expedition-bring">
                    <h3>Address</h3>
                    <div class="ui grid">
                      <div class="row">
                        <div class="sixteen wide column">
                          {{ pageDetails.page.address }}
                        </div>
                      </div>
                    </div>
                    <h3>Website</h3>
                    <div class="ui grid">
                      <div class="row">
                        <div class="sixteen wide column">
                          <a lang="en" hreflang="en" target="_blank" :href="pageDetails.page.website">
                            {{ pageDetails.page.websiteText && pageDetails.page.websiteText.trim().length > 0 ? pageDetails.page.websiteText : pageDetails.page.website }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <!--<div class="ui grid">-->
                    <!--  <div class="row">-->
                    <!--    <div class="sixteen wide column">-->
                    <!--      <img height="150" width="150" :src="`${contentBaseUri}/cms/images/pages/icon/${pageDetails.page.icon}`" />-->
                    <!--    </div>-->
                    <!--  </div>-->
                    <!--</div>-->
                  </div>
                </div>

                <div class="ten wide column">
                  <h2 class="ui header">Summary</h2>
                  <p style="white-space: pre-line">
                    {{ pageDetails.page.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Creator visits list -->
        <div v-if="pageDetails.collabSummaries?.length > 0" class="creator-visits-section" style="margin-bottom: 80px">
          <div class="divider"></div>
          <ul class="creator-visits-section__list">
            <CollabSummarySection
              class="creator-visits-section__li"
              v-for="collabSummary in pageDetails.collabSummaries"
              :key="collabSummary.id"
              :summarySection="collabSummary"
              isVisitDateVisible
              :isPageAvatarVisible="false"
            />
          </ul>
        </div>
        <!-- / Creator visits list -->

        <template v-if="pageDetails.nonCollabAdventures?.length">
          <div class="divider"></div>

          <!-- Adventures section -->
          <div class="adventures-section">
            <h2 class="global-h2 adventures-section__title">Adventures</h2>

            <ul class="adventures-section__adventure-tiles-list">
              <ItineraryDetailsSnippet style="width: 100%" v-for="itinerary in pageDetails.nonCollabAdventures" :key="itinerary.id" :itinerary="itinerary" />
            </ul>
          </div>
          <!-- / Adventures section -->
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { inject } from "vue";
import { mapState } from "pinia";
import { useHead } from "@unhead/vue";

// Components
import CollabSummarySection from "@components/CollabSummarySection.vue";
import SocialMediaLinksBar from "@components/SocialMediaLinksBar.vue";
import Loader from "@components/Loader/Loader.vue";
import ItineraryDetailsSnippet from "@components/ItineraryDetailsSnippet.vue";
import ClampedText from "@components/ui/ClampedText.vue";

// Types
import { CollabSummaryModel } from "@contracts/collabCredits";

interface CustomerDetailsModelWithCollabs {
  page: any;
  collabSummaries: Array<CollabSummaryModel>;
  nonCollabAdventures: Array<any>;
  sherpas: Array<any>;
}

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";

export default {
  name: "PagesView",

  components: {
    CollabSummarySection,
    ClampedText,
    Loader,
    SocialMediaLinksBar,
    ItineraryDetailsSnippet,
  },

  data() {
    return {
      globalLog: inject("globalLog"),

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isLoading: true,
      pageId: null,
      pageDetails: null,
      pageDetailsNotFound: false,
      isFollowed: false,
      title: "Shrpa",
      toggled: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getActingUserProfile", "isSuperOrSalesUser"]),
    canEdit(): boolean {
      if (this.isSuperOrSalesUser) return true;
      if (this.getActingUserProfile?.adminOf.some(a => a.toUpperCase() === this.pageId?.toUpperCase()) === true) return true;

      return false;
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.pageId = this.$route.params.pageId;
    await this.loadPageDetails(this.pageId);
  },

  methods: {
    async loadPageDetails(identifier: string): Promise<boolean> {
      try {
        let uri = `${import.meta.env.VITE_API_URL}/pages/${identifier}/v2?isPublicFacing=true`;
        const response = await axios.get<CustomerDetailsModelWithCollabs>(uri);
        this.pageDetails = response.data;
        if (this.pageDetails.page) this.title = this.pageDetails.page.title;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        if (err.response.status == 404) {
          this.pageDetailsNotFound = true;
          return false;
        }
        return true;
      }
    },
    onToggle: function () {
      this.toggled = !this.toggled;
    },
    getEditRoute() {
      return { name: "CommunityPageAdmin", params: { pageId: this.pageDetails.page.id } };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Adventures section =========================================================
.adventures-section {
  width: $desktop-content-width;
  margin: 0 auto;

  &__title {
    margin-bottom: 30px;
  }

  &__adventure-tiles-list {
    gap: 20px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__adventure-tile {
    width: calc(100% / 6 - 17px);
    margin: 0;
    padding: 0;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .adventures-section {
    width: $laptop-content-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .adventures-section {
    width: $tablet-large-content-width;

    &__adventure-tiles-list {
      gap: 20px;
    }

    &__adventure-tile {
      width: calc(100% / 4 - 15px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .adventures-section {
    width: $tablet-content-width;

    &__adventure-tiles-list {
      gap: 20px;
    }

    &__adventure-tile {
      width: calc(100% / 3 - 14px);
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .adventures-section {
    width: $mobile-content-width;

    &__adventure-tiles-list {
      gap: 20px;
    }

    &__adventure-tile {
      width: calc(100% / 2 - 10px);
    }
  }
}

// Creator visits section =====================================================
.creator-visits-section {
  width: $desktop-content-width;
  margin: 0 auto;

  &__title {
    margin-bottom: 30px;

    &--text-centered {
      text-align: center;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    list-style: none;
  }

  &__li {
    padding-bottom: 60px;
    margin-bottom: 50px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .creator-visits-section {
    width: $laptop-content-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .creator-visits-section {
    width: $tablet-large-content-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .creator-visits-section {
    width: $tablet-content-width;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-visits-section {
    width: $mobile-content-width;
  }
}

// ============================================================================
.creators-wrapper {
  transition: all 0.5s ease;
  .hidden {
    display: none;
  }
}
.toggle-text {
  width: 100%;
  text-align: right;
  color: grey;
  font-weight: bold;
  cursor: pointer;
}

.paid-sherpa {
  /*color: #EC563B;*/
  font-size: 14px;
}

.coop-logo {
  max-height: 150px;
}

.divider {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 40px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}
</style>
