import amplitude from "amplitude-js";
import Logger from "@helpers/Logger";

export class MetricSender {
  private static instance = null;
  public static initAmplitude(amplitudeInstance) {
    this.instance = amplitudeInstance;
  }

  public static navigate(toName, fromName) {
    this.sendMetric("navigated", { to: toName, from: fromName });
  }

  // NOTE: If source is null this metric is not fired (since we don't want to track impressions for certain admin pages)
  public static impressionAdventure(itineraryId: string, uniqueName: string, source: string | null) {
    // Passing a null source indicates that this is an impression we don't want to log (ex. backend/admin screens)
    if (!source || source === null) return;

    // There is some risk that we get throttle so burying errors here
    try {
      // Shortening this eventId since we expect so many
      // Deprecated (we hit Amlpitude tier limits) this.sendMetric("advImp", { itineraryId: itineraryId, uniqueName: uniqueName, source: source });
    } catch (ex) {
      Logger.error(`Failed to fire metric for ${uniqueName}`);
    }
  }

  public static previewAdventure(itineraryId, uniqueName) {
    this.sendMetric("itineraryPreview", { itineraryId: itineraryId, uniqueName: uniqueName });
  }

  public static exploreAdventure(itineraryId, uniqueName) {
    this.sendMetric("itinerarySteps", { itineraryId: itineraryId, uniqueName: uniqueName });
  }

  public static viewSherpa(sherpaId, firstName) {
    this.sendMetric("viewSherpa", { sherpaId: sherpaId, firstName: firstName });
  }

  public static feedbackForAdventure(itineraryId, rating) {
    this.sendMetric("feedback", { itineraryId: itineraryId, rating: rating });
  }

  public static share(shareType, pageUri) {
    // Note: Ideally we'd actually get the parameter rather than the entire uri
    this.sendMetric("share", { shareType: shareType, pageUri: pageUri });
    // ga('send', 'event', 'itinerary', 'share', shareType);
  }

  public static login(source) {
    this.sendMetric("login", { source: source });
    // ga('send', 'event', 'account', 'login', source);
  }

  public static userProvisioned(email) {
    this.sendMetric("provisionUser", { email: email });
    // ga('send', 'event', 'account', 'create');
  }

  public static identify(email, name, authId) {
    var identify = new amplitude.Identify().set("email", email).set("name", name).set("authid", authId);
    this.instance.identify(identify);

    // Also fire an event (the effect result of a user being logged in)
    this.sendMetric("authInit", { email: email });

    // this.setClarityCustomField("authid", authId);
    // this.setClarityCustomField("email", email);
  }

  public static setUserType(userType: string) {
    // This gets called after the fact given how the auth and profile loading flow is sequenced.
    Logger.info("Metrics: Setting UserType to: " + userType);
    var identify = new amplitude.Identify().set("user_type", userType);
    this.instance.identify(identify);

    // this.setClarityCustomField("user_type", userType);
  }

  private static setClarityCustomField(key, value) {
    try {
      // @ts-ignore
      if (typeof clarity === "function") {
        // @ts-ignore
        clarity("set", key, value);
        // console.info("ClarityCustomField: " + key + "=" + value);
      }
    } catch (ex) {
      Logger.error("ClarityCustomField Error: " + ex);
    }
  }

  public static logout() {
    this.instance.reset();
    Logger.info("Metrics: User Logout, reset identity.");
  }

  // Note: Most callers should use specific methos below
  public static sendMetric(eventName, params) {
    if (this.instance) {
      this.instance.logEvent(eventName, params);
    } else {
      console.info("Amplitude isn't setup yet. Event=" + eventName);
    }
  }
}
