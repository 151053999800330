<template>
  <div id="expeditions" class="hero">
    <!--<div class="ui basic center">
        <p><a lang="en" hreflang="en" class="ui center orange huge button" href="/content/editor">Create a new Adventure!</a></p>
    </div> *@-->
    <div class="ui container featured-expeditions">
      <CreatorCollabComplete v-if="myItineraries.length > 0" :adventures="myItineraries" />

      <div v-if="isLoading">
        <Loader />
      </div>

      <NoteWithIcon v-if="getViewingUserProfile?.collabPreferences?.becomingCollabEligible && !getViewingUserProfile?.collabPreferences?.isCollabEligible" class="collab-exp__warning" color="green">
        <template #icon><IconEmbedded name="info-simple_4" :size="22" /></template>
        <template #text>
          <h5 class="global-h5">Only 1 Adventure needed to get paid collab opportunities!</h5>
        </template>
      </NoteWithIcon>

      <div style="margin-bottom: 25px">
        <div class="ui basic center aligned segment" style="margin-bottom: 0">
          <button @click="navigateToEditor()" class="ui large orange button"><i class="icon plus"></i>Create a new Adventure</button>
        </div>

        <span v-if="isSuperOrSalesUser && !isImpersonating">
          <button class="ui tiny basic button" @click="superUserShowAllClick()">Superuser - Show em all!</button>
          <div v-if="superUserShowAll">{{ liveAdventureCount }} Live Adventures</div>
          <br />
        </span>

        <h3 style="margin-top: 0px">Your Adventures</h3>
        <span>Prioritize </span>
        <select class="timeRequired" v-model="currentSort" @change="sortChanged($event)">
          <option v-for="item in sortBy" :key="item" :value="item">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="ui four doubling raised link cards adventure-cards">
        <ItineraryTile v-for="itinerary in myItineraries" :itinerary="itinerary" :isCms="true" :allowShortImage="false" :key="itinerary.id" :source="'myitin'" @shareItinerary="shareItinerary" />
      </div>
    </div>

    <!--Needed so the modal renders correctly-->
    <ShareButton
      :sourceContext="'cms'"
      :shareCurrentPageUri="false"
      :entityIdToShare="itineraryIdToShare"
      :entityName="itineraryNameToShare"
      :useIcon="false"
      :parentOwnsHideShow="true"
      :showModal="showSharingModal"
      @close="showSharingModal = false"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import ItineraryTile from "@components/ItineraryTile.vue";
import { RouteHelper } from "@helpers/RouteHelper";
import FeatureFlags from "@logic/FeatureFlags";
import type { FlagNames } from "@logic/FeatureFlags";
import { ItinerarySummaryCms } from "@contracts/itinerary";
import ItineraryHelper from "@helpers/ItineraryHelper";
import ShareButton from "@components/ShareButton.vue";
import CreatorCollabComplete from "./CMS/CreatorCollabComplete.vue";
import { mapActions, mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import CollabExpectationsList from "@views/Creator/CollabExpectationsList.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { useHead } from "@unhead/vue";

const DaysSincePublishedToConsiderRecent = 10;
const SortByPreferenceLocalStorageKey = "MyItinerariesSortBy";

const SortByRecentlyPublished = "Recently Published";
const SortByDraft = "Drafts";
const SortByPublished = "Published";

export default defineComponent({
  name: "MyItineraries",

  components: {
    IconEmbedded,
    NoteWithIcon,
    CollabExpectationsList,
    Loader,
    ItineraryTile,
    CreatorCollabComplete,
    ShareButton,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      isLoading: true,
      myItineraries: [] as Array<ItinerarySummaryCms>,
      sortBy: [SortByRecentlyPublished, SortByDraft, SortByPublished],
      currentSort: SortByRecentlyPublished,
      itineraryIdToShare: null,
      itineraryNameToShare: "",
      showSharingModal: false,
      superUserShowAll: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "isSuperOrSalesUser", "isImpersonating"]),
    isDev(): boolean {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return globalThis.Bootstrap.Config.isDev;
    },
    liveAdventureCount(): number {
      if (!this.myItineraries) return 0;

      let count = this.myItineraries.filter(itinerary => {
        if (itinerary) return itinerary.status === "Live";
        return false;
      }).length;
      return count;
    },
  },

  async mounted() {
    useHead({ title: "My Adventures" });

    // Impersonation check
    let creatorIdParam = RouteHelper.getQueryStringParam("creatorId");
    if (creatorIdParam && creatorIdParam.length > 0) {
      await this.impersonateProfile(creatorIdParam);
    }

    this.restoreSortPreference();
    await this.getMyItineraries();

    // If they haven't created any, send them to the editor
    if (this.myItineraries.length == 0 && (!this.isSuperOrSalesUser || this.isImpersonating)) {
      // Using replace here so the user can easily use the back button
      this.navigateToEditor();
    }
  },

  methods: {
    ...mapActions(useUserProfileStore, { impersonateProfile: "impersonateProfile" }),
    async getMyItineraries() {
      let query = "";
      if (this.isImpersonating) query = `?creatorIdOverride=${this.getViewingUserProfile?.sherpaId}`;
      var showAll = RouteHelper.getQueryStringParam("superUserShowAll");
      if (showAll) this.superUserShowAll = true;
      if (this.superUserShowAll) {
        query += "&superUserShowAll=true";
      }
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/cms/itineraries${query}`);
      this.myItineraries = data;
      this.sortItineraryList(this.currentSort);
      this.isLoading = false;
    },
    shareItinerary(itineraryId: string, itineraryName: string) {
      this.itineraryIdToShare = itineraryId;
      this.itineraryNameToShare = itineraryName;
      this.showSharingModal = true;
    },
    restoreSortPreference() {
      var sortPref = localStorage.getItem(SortByPreferenceLocalStorageKey);
      if (sortPref) {
        this.currentSort = sortPref;
      }
    },
    sortChanged(event) {
      let sortBy = event.target.value;
      this.sortItineraryList(sortBy);
      localStorage.setItem(SortByPreferenceLocalStorageKey, sortBy);
    },
    sortItineraryList(sortBy: string) {
      this.globalLog.info("Sorting by " + sortBy);
      let drafts = this.getDrafts();
      let published = this.getPublished();
      let misc = this.getMiscStatus();

      if (sortBy === SortByDraft) {
        this.myItineraries = drafts.concat(published).concat(misc);
      } else if (sortBy === SortByPublished) {
        this.myItineraries = published.concat(drafts).concat(misc);
      } else {
        // Returns recent in the first item and non-recent in the second
        let splitPublished = this.splitByRecentlyPublished(published);
        // Recent, then drafts, then the rest
        this.myItineraries = splitPublished[0].concat(drafts).concat(splitPublished[1]).concat(misc);
      }

      if (this.isImpersonating && this.getViewingUserProfile?.sherpaId) {
        this.myItineraries = this.myItineraries.filter(i => i.sherpaId == this.getViewingUserProfile?.sherpaId);
      }
    },
    splitByRecentlyPublished(published: Array<ItinerarySummaryCms>): [Array<ItinerarySummaryCms>, Array<ItinerarySummaryCms>] {
      if (published.length == 0) return [[], []];
      // Find the first "old" itinerary so we can slice the array
      var firstNotRecentIndex = published.findIndex(itinerary => {
        let daysSincePublished = (Date.now() - Date.parse(itinerary.publishedDateTime)) / (1000 * 60 * 60 * 24);
        return daysSincePublished > DaysSincePublishedToConsiderRecent;
      });
      // If the index is not negative, slice the array
      if (firstNotRecentIndex >= 0) {
        let recents = published.slice(0, firstNotRecentIndex);
        let notRecents = published.slice(firstNotRecentIndex, published.length);
        return [recents, notRecents];
      } else {
        // It never found an old one, then there's only recently published
        return [published, []];
      }
    },
    getDrafts(): Array<ItinerarySummaryCms> {
      if (!this.myItineraries) return [];
      let drafts = this.myItineraries.filter(itinerary => {
        return ItineraryHelper.isDraft(itinerary.status);
      });
      drafts.sort(function (a, b) {
        return +new Date(b.updatedDateTime) - +new Date(a.updatedDateTime);
      });
      return drafts;
    },
    getPublished(): Array<ItinerarySummaryCms> {
      if (!this.myItineraries) return [];
      let published = this.myItineraries.filter(itinerary => {
        return ItineraryHelper.isLive(itinerary.status);
      });
      published.sort(function (a, b) {
        return +new Date(b.publishedDateTime) - +new Date(a.publishedDateTime);
      });
      return published;
    },
    // Gets not live and not draft (basically Removed)
    getMiscStatus(): Array<ItinerarySummaryCms> {
      if (!this.myItineraries) return [];
      let misc = this.myItineraries.filter(itinerary => {
        return !ItineraryHelper.isLive(itinerary.status) && !ItineraryHelper.isDraft(itinerary.status);
      });
      return misc;
    },
    navigateToEditor(noHistory = false) {
      var destination = {
        name: "CmsEditorV2",
        query: {
          creatorId: this.getViewingUserProfile?.sherpaId,
        },
      };
      if (noHistory) {
        this.$router.replace(destination);
      } else {
        this.$router.push(destination);
      }
    },
    superUserShowAllClick() {
      if (!this.superUserShowAll) {
        this.superUserShowAll = true;
        this.getMyItineraries();
      }
    },
  },
});
</script>
