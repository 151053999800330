<template>
  <label
    :class="{
      'srp-checkbox': true,
      'srp-checkbox--with-halo': props.isWithHalo,
    }"
  >
    <span class="srp-checkbox__checkbox-wrap">
      <slot name="input" />
      <span
        v-if="props.isWithHalo"
        :class="{
          'srp-checkbox__halo-bg': true,
          'srp-checkbox__halo-bg--white': props.haloColor === 'white',
        }"
      ></span>
    </span>
    <span
      :class="{
        'srp-checkbox__text': true,
        'srp-checkbox__text--white-space-nowrap': props.whiteSpace === 'nowrap',
      }"
    >
      <slot name="text" />
    </span>
  </label>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isWithHalo?: boolean;
    haloColor?: "gray" | "white";
    whiteSpace?: "wrap" | "nowrap";
  }>(),
  {
    isWithHalo: true,
    haloColor: "gray",
    whiteSpace: "nowrap",
  }
);
</script>

<style scoped lang="scss">
// Srp checkbox ===============================================================
.srp-checkbox {
  width: auto !important;
  min-height: 32px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  user-select: none;
  cursor: pointer;

  &::after {
    content: "";
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    position: absolute;
    inset: 50% auto auto 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
  }

  &--with-halo {
    padding: 6px 10px 7px;
  }

  &__halo-bg {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.1);
    transition:
      width 0.05s ease-in-out,
      left 0.05s ease-in-out,
      background 0.05s ease-in-out;

    &--white {
      background: rgba(255, 255, 255, 1);
    }
  }

  :deep(input[type="checkbox"]),
  :deep(input[type="radio"]) {
    margin: 0 !important;
  }

  input[type="checkbox"]:checked + &__halo-bg,
  input[type="radio"]:checked + &__halo-bg {
    background: rgba(0, 0, 0, 0.2);
  }

  input[type="checkbox"]:checked + &__halo-bg--white,
  input[type="radio"]:checked + &__halo-bg--white {
    background: rgba(255, 255, 255, 1);
  }

  &:hover &__halo-bg {
    width: calc(100% + 6px);
    left: -3px;
  }

  &__checkbox-wrap {
    height: 15px;
    margin: 2px 6px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep(input) {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  &__text {
    padding-bottom: 1px;
    color: #5b5b5b;
    font: 14px/18px sans-serif;

    &--white-space-nowrap {
      white-space: nowrap;
    }
  }
}
</style>
