<template>
  <div class="collab-messages-thread">
    <!-- Creator info spoiler -->
    <div v-if="isCreatorDetailsVisible && creatorDetails" class="creator-info-spoiler collab-messages-thread__creator-info-spoiler">
      <div class="creator-info-spoiler__header" @click="isSpoilerOpened = !isSpoilerOpened">
        <div class="creator-info-spoiler__avatar-wrap">
          <img class="creator-info-spoiler__avatar" :src="creatorDetails.profileImageUri" :alt="creatorDetails.profileImageUri" />
        </div>

        <div class="global-h4 creator-info-spoiler__title">
          <span class="creator-info-spoiler__name">
            {{ lodashStartCase(creatorDetails.firstName) }}
          </span>
          &nbsp;
          <!-- Title icons -->
          <ul class="title-icons creator-info-spoiler__title-icons">
            <IconEmbedded class="title-icons__single-icon" name="envelope_2" />
            <IconEmbedded class="title-icons__single-icon" name="phone_2-5" />
          </ul>
          <!-- / Title icons -->
          <IconEmbedded class="creator-info-spoiler__caret-icon" :name="isSpoilerOpened ? 'caret-top_2' : 'caret-bottom_2'" />
        </div>
      </div>

      <div
        :class="{
          'creator-info-spoiler__content-wrap': true,
          'creator-info-spoiler__content-wrap--closed': !isSpoilerOpened,
        }"
      >
        <div class="creator-info-spoiler__content">
          <p>
            Messaging is the preferred way to connect, <br />
            but you can fall back to this email if needed.
            <br />
            <CopyTextNew style="font-weight: 600">
              {{ creatorDetails.email }}
            </CopyTextNew>
            <span v-if="creatorDetails?.fullName" style="margin-top: 3px; display: block"><b>Full Name:</b> {{ creatorDetails.fullName }}</span>
          </p>

          <span v-if="creatorDetails.phoneNumber" style="display: block">
            <h6 class="global-h6">Last-minute coordination needed?</h6>
            Reach out via phone:
            <CopyTextNew style="font-weight: 600">
              {{ creatorDetails.phoneNumber }}
            </CopyTextNew>
          </span>
        </div>
      </div>
    </div>
    <!-- / Creator info spoiler -->

    <!-- Messages list -->
    <div class="messages-list collab-messages-thread__messages-list">
      <!--<Loader v-if="isLoadingMessages" class="messages-list__loader" />-->

      <div v-if="!isLoadingMessages && messagesList.length === 0" class="messages-list__no-messages-line">No messages yet</div>

      <ul
        v-if="isLoadingMessages"
        :class="{
          'messages-list__list-itself': true,
          'messages-list__list-itself--smooth-scroll': isListScrollSmooth,
        }"
        ref="scrollableBlock"
      >
        <li
          :class="{
            'messages-list__msg-n-divider': true,
            'messages-list__msg-n-divider--bigger-bottom-margin': !checkIsSenderTypesAreSame(message, messagesList[index + 1]) && checkIsMsgDatesAreSame(message, messagesList[index + 1]),
          }"
          v-for="(message, index) in messagesListMock"
          :key="message.sentOn + message.text + message.senderId"
        >
          <!-- Date divider -->
          <div
            v-if="!checkIsMsgDatesAreSame(messagesListMock[index - 1], message)"
            :class="{
              'date-divider': true,
              'messages-list__date-divider': true,
              'messages-list__date-divider--no-top-margin': index === 0,
            }"
          >
            <div class="date-divider__text date-divider__text--skeleton">
              {{ getMsgDate(message) }}
            </div>
          </div>
          <!-- / Date divider -->
          <MessageBubble
            :class="{
              'messages-list__single-message': true,
              'messages-list__single-message--align-right': checkIsMessageYours(message.senderType),
            }"
            :messagesList="messagesListMock"
            :msgIndex="index"
            :style="{ width: calcMessageBoxWidth(message) }"
            :isSkeleton="true"
            :senderDetails="senderDetailsListMock"
            :messageSenderType="messageSenderType"
          />
        </li>
      </ul>

      <ul
        v-else
        :class="{
          'messages-list__list-itself': true,
          'messages-list__list-itself--smooth-scroll': isListScrollSmooth,
        }"
        ref="scrollableBlock"
      >
        <li
          :class="{
            'messages-list__msg-n-divider': true,
            'messages-list__msg-n-divider--bigger-bottom-margin': !checkIsSenderTypesAreSame(message, messagesList[index + 1]) && checkIsMsgDatesAreSame(message, messagesList[index + 1]),
          }"
          v-for="(message, index) in messagesList"
          :key="message.sentOn + message.text + message.senderId"
        >
          <!-- Date divider -->
          <div
            v-if="!checkIsMsgDatesAreSame(messagesList[index - 1], message)"
            :class="{
              'date-divider': true,
              'messages-list__date-divider': true,
              'messages-list__date-divider--no-top-margin': index === 0,
            }"
          >
            <div class="date-divider__text">{{ getMsgDate(message) }}</div>
          </div>
          <!-- / Date divider -->
          <MessageBubble
            :class="{
              'messages-list__single-message': true,
              'messages-list__single-message--align-right': checkIsMessageYours(message.senderType),
            }"
            :messagesList="messagesList"
            :msgIndex="index"
            :style="{ width: calcMessageBoxWidth(message) }"
            :senderDetails="senderDetailsList"
            :messageSenderType="messageSenderType"
          />
        </li>
      </ul>
    </div>
    <!-- / Messages list -->

    <!-- New message form -->
    <div
      :class="{
        'new-message-form': true,
        'new-message-form--inaccessible': isLoadingMessages || isSending,
        'collab-messages-thread__new-message-form': true,
      }"
    >
      <!-- New messages button section -->
      <div v-if="newMessagesList.length" class="new-msg-btn-section new-message-form__new-msg-btn-section">
        <SrpButton class="new-msg-btn-section__button" tabindex="0" @click="appendNewMessages" color="orange">
          <div class="new-msg-btn-section__button-counter">
            {{ newMessagesList.length }}
          </div>
          {{ newMessagesList.length === 1 ? "new message" : "new messages" }}
          <template #iconRight><IconEmbedded name="arrow-bottom_3-5" /></template>
        </SrpButton>
      </div>
      <!-- / New messages button section -->

      <div
        class="ui form new-message-form__textarea-wrap"
        :style="{
          height: `${textareaHeight}px !important`,
          minHeight: `${textareaHeight}px !important`,
          maxHeight: `${textareaHeight}px !important`,
        }"
      >
        <!-- this hidden textarea is needed for retrieving of the actual height of the text inside of the textarea -->
        <textarea class="new-message-form__textarea-ghost" placeholder="Your message" type="text" ref="msgTextareaGhost" tabindex="-1" v-model="textareaValue"></textarea>
        <textarea
          class="new-message-form__textarea"
          placeholder="Your message"
          type="text"
          ref="msgTextarea"
          v-model="textareaValue"
          :style="{
            height: `${textareaHeight}px !important`,
            minHeight: `${textareaHeight}px !important`,
            maxHeight: `${textareaHeight}px !important`,
          }"
          @keydown="handleShiftPlusEnter"
          :maxlength="textareaSymbolsLimit"
        ></textarea>
      </div>

      <!-- Buttons & error -->
      <div class="buttons-n-error new-message-form__buttons-n-error">
        <div class="buttons-n-error__left-side">
          <div v-if="isError" class="buttons-n-error__error-text">
            {{ errorText ? errorText : "Something went wrong" }}
          </div>

          <!-- Impersonate switch -->
          <div v-if="isImpersonateToggleVisible" class="ui toggle checkbox impersonate-switch buttons-n-error__impersonate-switch">
            <input class="impersonate-switch__switch-itself" type="checkbox" id="impersonateCheckbox" v-model="isImpersonate" />
            <label class="impersonate-switch__label" for="impersonateCheckbox">Impersonate {{ messageSenderType === "CustomerId" ? "customer" : "creator" }}</label>
          </div>
          <!-- / Impersonate switch -->
        </div>

        <div class="buttons-n-error__buttons-list">
          <!-- File upload snippet -->
          <div v-if="isUploading" class="file-upload-snippet buttons-n-error__file-upload-snippet">
            <LinkWithIcon class="file-upload-snippet__delete-button" @click="cancelUploadFunc" color="red">
              <template #icon><IconEmbedded name="remove_2-5" :size="26" /></template>
            </LinkWithIcon>
            <!--<IconEmbedded class="file-upload-snippet__clip-icon" name="paperclip_2" :size="20" />-->
            <div class="file-upload-snippet__name-n-progressbar">
              <div class="file-upload-snippet__title">
                Uploading&nbsp;
                <div class="file-upload-snippet__name">
                  {{ uploadedFileNameShortened }}
                </div>
              </div>
              <div class="file-upload-snippet__progressbar">
                <div class="file-upload-snippet__progress-scale" :style="{ width: `${fileUploadPercentage}%` }"></div>
              </div>
            </div>
          </div>
          <!-- / File upload snippet -->

          <!-- File upload snippet -->
          <div v-else-if="!isUploading && uploadedFileId" class="file-upload-snippet buttons-n-error__file-upload-snippet">
            <LinkWithIcon class="file-upload-snippet__delete-button" @click="() => (uploadedFileName = uploadedFileId = '')" color="light-gray">
              <template #icon><IconEmbedded name="remove_2-5" :size="26" /></template>
            </LinkWithIcon>
            <IconEmbedded class="file-upload-snippet__clip-icon" name="paperclip_2" :size="20" />
            <div class="file-upload-snippet__name-n-progressbar">
              <div class="file-upload-snippet__title">
                <div class="file-upload-snippet__name">
                  {{ uploadedFileNameShortened }}
                </div>
              </div>
            </div>
          </div>
          <!-- / File upload snippet -->

          <LinkWithIcon v-else class="buttons-n-error__attach-file-button" isDottedUnderline tag="input" type="file" @change="uploadFile">
            <template #icon><IconEmbedded name="paperclip_2" :size="21" /></template>
            <span>Attach file</span>
          </LinkWithIcon>

          <SrpButton class="buttons-n-error__send-button" tabindex="0" @click="sendMessage" :isDisabled="isLoadingMessages || isSending || isUploading || (!textareaValue.trim() && !uploadedFileId)">
            <template #icon><IconEmbedded class="buttons-n-error__send-button-icon" :name="isSending ? 'loader_3' : 'send_2-5'" /></template>
            Send
          </SrpButton>
        </div>
      </div>
      <!-- / Buttons & error -->

      <div class="new-message-form__escalate-to-shrpa-section">
        <!-- Escalate to Shrpa link -->
        <div class="escalate-to-shrpa-link new-message-form__escalate-to-shrpa-link" @click="isContactShrpaModalVisible = true">
          Escalate to <img class="escalate-to-shrpa-link__icon" :src="icons.siteIconGray" alt="shrpa" />Shrpa
        </div>
        <!-- / Escalate to Shrpa link -->

        <ContactUser
          :visible="isContactShrpaModalVisible"
          :sendToFirstName="'Shrpa'"
          :sendToEmail="'collabs@shrpa.com'"
          :sendToImageUri="'https://cdn.shrpa.com/images/logo.png'"
          :subject="contactShrpaSubject"
          :messagePrefix="contactShrpaSubject"
          headingText="Escalate to Shrpa"
          @close="isContactShrpaModalVisible = false"
          :zIndex="203"
        />
      </div>
    </div>
    <!-- / New message form -->
  </div>
</template>

<script lang="ts">
import axios from "axios";
import checkIsMsgDatesAreSame from "./checkIsMsgDatesAreSame";
import lodashStartCase from "lodash-es/startCase";
import lodashUniqBy from "lodash-es/uniqBy";
import { computed, defineComponent } from "vue";

import mockData from "./mockData.json";

// Types
import { CollabAssignedCreator, CollabInput } from "@contracts/collab";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import CopyTextNew from "@components/CopyTextNew.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import Loader from "@components/Loader/Loader.vue";
import MessageBubble from "./MessageBubble.vue";

// Icons
import siteIconGray from "@assets/icons/siteicon-gray.svg";

// Stores
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";
import ContactUser from "@components/Modals/ContactUser.vue";

export interface MessageAttachment {
  fileId: string;
  fileName: string;
}

export interface SenderDetails {
  image: string;
  name: string;
  senderId: string;
  senderType: "CustomerId" | "CreatorId" | "Shrpa";
}

export interface CollabThreadMessage {
  attachments?: Array<MessageAttachment>;
  senderId: string;
  senderType: "CustomerId" | "CreatorId" | "Shrpa";
  sentOn: string;
  text?: string;
}

export default defineComponent({
  name: "CollabMessagesThread",

  components: { ContactUser, IconEmbedded, SrpButton, CopyTextNew, LinkWithIcon, Loader, MessageBubble },

  props: {
    messageThreadId: { type: String, default: "" },
    customerId: { type: String, required: true },
    isCreatorDetailsVisible: { type: Boolean, default: false },
    creatorDetails: { type: Object as () => CollabAssignedCreator | null, default: null },
    collabInput: { type: Object as () => CollabInput, required: true },
    // creatorId (when invoked from CollabOpportunity) or customerId (when invoked from CollabSummary.vue)
    messageSenderType: { type: String, required: true, validator: (v: string) => ["CustomerId", "CreatorId"].includes(v) },
  },

  inject: {
    msgForMessagesThread: { default: null },
    setMsgForMessagesThread: { default: () => null },
    testFunc: { default: () => null },
  },

  emits: ["isImpersonateChange"],

  data() {
    return {
      apiBaseUri: import.meta.env.VITE_API_URL as string,
      baseUri: import.meta.env.BASE_URL as string,

      isSpoilerOpened: false,

      messagesList: [] as Array<CollabThreadMessage>,
      newMessagesList: [] as Array<CollabThreadMessage>, // temp buffer which appends to the main list on "new messages" button click
      messagesListMock: [] as Array<CollabThreadMessage>,

      senderDetailsList: [] as Array<SenderDetails>,
      senderDetailsListMock: [] as Array<SenderDetails>,

      isLoadingMessages: false,
      isSending: false,
      isUploading: false,

      textareaValue: "",
      textareaHeight: 43,
      textareaSymbolsLimit: 2000,

      fileUploadPercentage: 0,
      uploadedFileName: "",
      uploadedFileId: "",

      isListScrollSmooth: false, // only needed when list scrolls down to show new messages

      lastLoadDateTime: "",
      messagesListRefreshTimeoutInSeconds: 30, // sec
      messagesListRefreshTimeoutId: null as ReturnType<typeof setTimeout>,

      isError: false,
      errorText: "",

      cancelUploadFunc: Object as () => void,

      isImpersonate: false,

      isContactShrpaModalVisible: false,

      icons: {
        siteIconGray,
      },
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getActingUserProfile", "getViewingUserProfile", "isSuperOrSalesUser"]),
    contactShrpaSubject(): string {
      return `Collab Escalation for Shrpa from ${this.getViewingUserProfile?.firstName} about ${this.collabInput?.name}`;
    },
    isImpersonateToggleVisible(): boolean {
      return this.isSuperOrSalesUser && globalThis.Bootstrap.Config.isDev;
    },
    uploadedFileNameShortened(): string {
      return this.shortenFileName(this.uploadedFileName);
    },
  },

  watch: {
    isImpersonate(): void {
      this.$emit("isImpersonateChange", this.isImpersonate);
    },
    textareaValue(): void {
      this.$nextTick(this.setTextareaHeight);

      this.isError = false;
      this.errorText = "";
    },
    lastLoadDateTime() {
      this.setRefreshTimeout();
    },
  },

  async mounted() {
    // mock data is needed for the skeleton and for testing
    this.messagesListMock = this.sortMessagesList(mockData.messages as Array<CollabThreadMessage>);
    this.senderDetailsListMock = mockData.senderDetails as Array<SenderDetails>;

    this.$nextTick(this.scrollListToBottom);
    this.$nextTick(this.focusTextarea);

    this.loadMessages();
    this.setTextareaHeight();

    this.markAllNewMessagesAsRead();

    if (this.msgForMessagesThread?.msg) {
      this.textareaValue = this.msgForMessagesThread.msg;
      await this.sendMessage(true);
      this.msgForMessagesThread.msg = "";
    }
  },

  beforeUnmount() {
    // ---

    clearTimeout(this.messagesListRefreshTimeoutId);
  },

  methods: {
    lodashStartCase,
    checkIsMsgDatesAreSame,
    checkIsSenderTypesAreSame(msg1: CollabThreadMessage, msg2: CollabThreadMessage): boolean {
      if (!msg1 || !msg2) return false;
      return msg1.senderType === msg2.senderType;
    },
    getMsgDate(message: CollabThreadMessage): string {
      const allMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const msgDate = new Date(message.sentOn);
      const yearString = new Date().getFullYear() === msgDate.getFullYear() ? "" : msgDate.getFullYear();

      return `${allMonths[msgDate.getMonth()]} ${msgDate.getDate()}${yearString ? ` ${yearString}` : ""}`;
    },
    // Checks whether message should be displayed as yours (right aligned, no avatar and with green bg)
    checkIsMessageYours(senderType: string): boolean {
      return senderType === this.messageSenderType;
    },
    shortenFileName(name: string): string {
      let newName = "";

      if (name.length < 16) {
        newName = name;
      } else {
        const extension = name.match(/\.[0-9a-z]+$/i)[0];
        newName = name.slice(0, 13) + ".." + extension;
      }

      return newName;
    },
    async uploadFile(event) {
      this.isError = false;
      this.errorText = "";

      if (event.target.files[0].size / 1024 ** 2 > 10) {
        this.isError = true;
        this.errorText = "File must be less than 10MB";
        return;
      }

      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      const CancelToken = axios.CancelToken;

      this.isUploading = true;
      this.uploadedFileName = event.target.files[0].name;

      try {
        this.isError = false;
        const { data } = await axios.post(`${this.apiBaseUri}/file-upload/${"Collab"}/${this.collabInput.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: progressEvent => {
            this.fileUploadPercentage = parseInt(`${Math.round((progressEvent.loaded / progressEvent.total) * 100)}`);
          },
          cancelToken: new CancelToken(c => (this.cancelUploadFunc = c)),
        });

        this.uploadedFileId = data;
      } catch (error) {
        if (error.response.status === 400) {
          this.errorText = error.response.data;
        } else if (axios.isCancel(error)) {
          console.warn("Upload is canceled");
        } else {
          const errorMessage = error?.message ?? error;
          getGlobalRemoteLogger().error(`Message UploadFile Error: thread=${this.messageThreadId}, collabId=${this.collabInput.id}, error=${errorMessage}, stack=${error?.stack}`, true, true, {
            messageThreadId: this.messageThreadId,
            error: errorMessage,
            stack: error?.stack,
          });
          this.isError = true;
        }
        this.uploadedFileId = "";
      }

      this.isUploading = false;
      this.fileUploadPercentage = 0;
    },
    focusTextarea(): void {
      (this.$refs.msgTextarea as HTMLTextAreaElement)?.focus();
    },
    blurTextarea(): void {
      (this.$refs.msgTextarea as HTMLTextAreaElement)?.blur();
    },
    handleShiftPlusEnter(event: KeyboardEvent): void {
      if (event.key === "Enter") {
        if (event.ctrlKey) {
          this.sendMessage();
        }
      }
    },
    async sendMessage(isForceSend = false): Promise<void> {
      if ((this.isLoadingMessages || this.isSending || this.isUploading || (!this.textareaValue.trim() && !this.uploadedFileId)) && !isForceSend) {
        return;
      }
      clearTimeout(this.messagesListRefreshTimeoutId);
      this.isSending = true;

      // deletes excessive white spaces and line breaks
      const refinedTextareaValue = this.textareaValue
        .trim()
        .replace(/\n\s*\n/g, "\n\n")
        .slice(0, this.textareaSymbolsLimit);
      // .replace(/[ ]{2,}/gm, "");

      const payload = {
        senderId: this.getActingUserProfile.sherpaId,
        senderType: this.messageSenderType,
        text: refinedTextareaValue,
        attachments: this.uploadedFileId ? [{ fileId: this.uploadedFileId, fileName: this.uploadedFileName }] : [],
      };

      let isSentSuccessfully = false;

      try {
        this.isError = false;

        const queryParams = new URLSearchParams({
          customerId: this.customerId,
          collabInputId: this.collabInput.id,
          loadMessagesSentAfter: this.lastLoadDateTime,
        });
        if (this.isImpersonate) queryParams.append("impersonate", "true");

        const { data } = await axios.post(`${this.apiBaseUri}/messaging/${this.messageThreadId}/message?${queryParams.toString()}`, payload);
        this.makeListScrollSmoothTemporarily();
        const defineMsgUniqueness = (msg: CollabThreadMessage): string => msg.sentOn + msg.text + msg.senderId;
        this.messagesList = lodashUniqBy([...this.messagesList, ...this.sortMessagesList(data)], defineMsgUniqueness);

        // Note: Need to use the sentOn date returned, or the message could be seen as "new" on the next GET call (since there's likely some clock skew between client and server)
        // Also, don't use the Date() here or we lose precision relative to the server
        if (data.length > 0)
          // loadMessagesSentAfter could be off from clock skew so it's probably possible we could get nothing returned
          this.lastLoadDateTime = data[0].sentOn;

        this.$nextTick(this.scrollListToBottom);
        isSentSuccessfully = true;
      } catch (error) {
        const errorMessage = error?.message ?? error;
        getGlobalRemoteLogger().error(
          `SendMessage Error: thread=${this.messageThreadId}, senderId=${payload?.senderId}, text=${refinedTextareaValue}, error=${errorMessage}, stack=${error?.stack}`,
          true,
          true,
          {
            messageThreadId: this.messageThreadId,
            senderId: payload?.senderId,
            text: refinedTextareaValue,
            error: errorMessage,
            stack: error?.stack,
          }
        );
        this.isError = true;
        isSentSuccessfully = false;
      }

      if (isSentSuccessfully) {
        this.textareaValue = "";
        this.uploadedFileName = "";
        this.uploadedFileId = "";
      }

      this.isSending = false;
      this.$nextTick(this.focusTextarea);

      await this.markAllNewMessagesAsRead();
    },
    setTextareaHeight(): void {
      let newTextareaHeight = 0;
      const textarea = this.$refs.msgTextarea as HTMLTextAreaElement;
      // this hidden textarea is needed for retrieving of the actual height of the text inside of the textarea
      const textareaGhost = this.$refs.msgTextareaGhost as HTMLTextAreaElement;

      if (textareaGhost?.scrollHeight === textarea.clientHeight && textarea.clientHeight >= 43 && textarea.clientHeight <= 300) {
        return;
      } else {
        newTextareaHeight = textareaGhost?.scrollHeight < 18 ? 43 : textarea?.scrollHeight > 162 ? 162 : textareaGhost?.scrollHeight + 24;
      }

      this.textareaHeight = newTextareaHeight;
    },
    scrollListToBottom(): void {
      const scrollableBlock = this.$refs.scrollableBlock as HTMLElement;
      if (scrollableBlock) {
        scrollableBlock.scrollTop = scrollableBlock?.scrollHeight;
      }
    },
    async markAllNewMessagesAsRead(): Promise<void> {
      const queryParams = new URLSearchParams({
        customerId: this.customerId,
        collabInputId: this.collabInput.id,
        impersonate: String(this.isImpersonate),
        context: this.messageSenderType,
      }).toString();

      await axios.put(`${this.apiBaseUri}/messaging/${this.messageThreadId}/read?${queryParams.toString()}`);
    },
    makeListScrollSmoothTemporarily(): void {
      this.isListScrollSmooth = true;
      setTimeout(() => (this.isListScrollSmooth = false), 500);
    },
    appendNewMessages(): void {
      this.makeListScrollSmoothTemporarily();
      this.messagesList = lodashUniqBy([...this.messagesList, ...this.sortMessagesList(this.newMessagesList)], this.defineMsgUniqueness);

      this.newMessagesList = [];
      this.markAllNewMessagesAsRead();

      this.$nextTick(this.scrollListToBottom);
    },
    defineMsgUniqueness: (msg: CollabThreadMessage): string => msg.sentOn + msg.text + msg.senderId,
    setRefreshTimeout(): void {
      clearTimeout(this.messagesListRefreshTimeoutId);
      this.messagesListRefreshTimeoutId = setTimeout(() => this.loadMessages(true), this.messagesListRefreshTimeoutInSeconds * 1000);
    },
    async loadMessages(isRefresh?: boolean): Promise<void> {
      if (!isRefresh) {
        this.isLoadingMessages = true;
        this.$nextTick(this.blurTextarea);
      }

      const queryParams = new URLSearchParams({
        customerId: this.customerId,
        collabInputId: this.collabInput.id,
      });
      if (isRefresh) queryParams.append("loadMessagesSentAfter", this.lastLoadDateTime);
      if (this.isImpersonate) queryParams.append("impersonate", "true");

      try {
        const { data } = await axios.get(`${this.apiBaseUri}/messaging/${this.messageThreadId}?${queryParams.toString()}`);
        console.log(`Messages list data is fetched, length=${data.messages?.length}`);

        if (!isRefresh) {
          this.isLoadingMessages = false;
          this.$nextTick(this.focusTextarea);
        }
        this.lastLoadDateTime = new Date().toJSON();

        if (isRefresh) {
          this.newMessagesList = [...this.newMessagesList, ...this.sortMessagesList(data.messages)];
        } else {
          this.messagesList = lodashUniqBy([...this.messagesList, ...this.sortMessagesList(data.messages)], this.defineMsgUniqueness);
        }

        if (!isRefresh) {
          // this.messagesList = this.sortMessagesList(mockData.messages as Array<CollabThreadMessage>);
          this.senderDetailsList = data.senderDetails;
          // this.senderDetailsList = mockData.senderDetails as Array<SenderDetails>;
          this.$nextTick(this.scrollListToBottom);
        }
      } catch (error) {
        const errorMessage = error?.message ?? error;
        getGlobalRemoteLogger().error(`LoadMessages Error: thread=${this.messageThreadId}, collabId=${this.collabInput.id}, error=${errorMessage}, stack=${error?.stack}`, true, true, {
          messageThreadId: this.messageThreadId,
          error: errorMessage,
          stack: error?.stack,
        });
        this.setRefreshTimeout();
        return;
      }
    },
    calcMessageBoxWidth(message?: CollabThreadMessage): string {
      const fileNameLength = message.attachments?.[0]?.fileName.length || 0;
      const textLength = message.text?.length || 0;
      const biggestLength = Math.max(textLength, fileNameLength);
      const calculatedWidth = `${textLength * 0.7 <= 90 ? textLength * 0.7 : 90}%`;
      const calculatedWidthWithAvatar = `calc(${calculatedWidth} + 41px)`;
      return biggestLength <= 40 || fileNameLength ? "auto" : this.checkIsMessageYours(message.senderType) ? calculatedWidth : calculatedWidthWithAvatar;
    },
    // Sorts the messages based on the time. The oldest goes first (to keep all of the new ones on the bottom).
    sortMessagesList(collabMessagesMock: Array<CollabThreadMessage>): Array<CollabThreadMessage> {
      const sortFunc = (a: CollabThreadMessage, b: CollabThreadMessage): number => {
        const t = (arg: CollabThreadMessage): number => Date.parse(arg.sentOn);
        return t(a) === t(b) ? 0 : t(a) > t(b) ? 1 : -1;
      };

      return [...collabMessagesMock].sort(sortFunc);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/mixins/animations/skeleton-loading.scss";
@import "@/scss/mixins/animations/gradient-spin-white.scss";

// Creator info spoiler =======================================================
.creator-info-spoiler {
  box-sizing: border-box;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  color: #5b5b5b;
  font:
    14px/22px "Helvetica Neue",
    sans-serif;

  p {
    margin-bottom: 8px;
    color: #5b5b5b;
    font:
      14px/22px "Helvetica Neue",
      sans-serif;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    width: 100%;
    height: 50px;
    min-height: 50px;
    padding-left: 33px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0 auto auto 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 50%, rgba(0, 0, 0, 0) 100%);
      opacity: 0;
      transition: opacity 0.05s ease-in-out;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__avatar-wrap {
    width: 32px;
    height: 32px;
    margin-right: 13px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__title {
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    line-height: 19px;
  }

  &__title-icons {
    color: rgba(0, 0, 0, 0.5);
    font-size: 21px;
    line-height: 21px;
  }
  .title-icons {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    list-style: none;

    &__single-icon {
      margin-right: 6px;
      font-size: 21px;
    }
  }

  &__name {
    margin-right: 4px;
    font-weight: 400;
  }

  &__caret-icon {
    font-size: 20px;
  }

  &__content-wrap {
    max-height: 600px;
    transition: max-height 0.12s ease-in-out;

    &--closed {
      max-height: 0;
      position: relative;
      overflow: hidden;
    }
  }

  &__content {
    padding: 0 20px 18px 80px;
    box-sizing: border-box;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-info-spoiler {
    &__header {
      padding-left: 19px;
    }

    &__content {
      padding-left: 19px;
    }
  }
}

// Messages list ==============================================================
.messages-list {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  position: relative;
  z-index: 0;

  &__loader {
  }

  &__no-messages-line {
    color: rgba(91, 91, 91, 0.5);
    font:
      14px/14px "Helvetica Neue",
      sans-serif;
  }

  &__list-itself {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 25px 23px 25px 32px;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: absolute;
    inset: 0 auto auto 0;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: white;
    }

    &--smooth-scroll {
      scroll-behavior: smooth;
    }
  }

  &::before {
    content: "";
    width: calc(100% - 15px);
    height: 20px;
    position: absolute;
    inset: auto auto 0 0;
    z-index: 2;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }

  &__msg-n-divider {
    width: 100%;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;

    &--bigger-bottom-margin {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__date-divider {
    margin: 25px 0 27px;

    &--no-top-margin {
      margin-top: 0;
    }
  }
  .date-divider {
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      inset: calc(50% - 1px) auto auto 0;
      z-index: -1;
      background: rgba(0, 0, 0, 0.1);
    }

    &__text {
      padding: 0 10px;
      border-radius: 6px;
      display: inline-flex;
      background: white;

      &--skeleton {
        @include skeletonLoading;
        color: transparent;
        background: #e7e7e7 !important;
      }
    }
  }

  &__single-message {
    max-width: 90%;
    min-width: 40px;
    align-self: flex-start;

    &--align-right {
      align-self: flex-end;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .messages-list {
    &__single-message {
      width: auto !important;
      max-width: 100% !important;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .messages-list {
    &__list-itself {
      padding-right: 10px;
      padding-left: 19px;
    }
    &__single-message {
      width: auto !important;
      max-width: 100% !important;
    }
  }
}

// File upload snippet ========================================================
.file-upload-snippet {
  display: flex;
  align-items: center;
  color: #5b5b5b;
  font:
    14px/14px "Helvetica Neue",
    sans-serif;

  &__delete-button {
    width: 26px;
    height: 26px;
    margin-right: 0;
    position: relative;
    top: 1px;
    color: #d65050;
    font-size: 26px;
    cursor: pointer;
  }

  &__clip-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }
  }

  &__name-n-progressbar {
  }

  &__title {
    margin-bottom: 5px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    font-weight: 600;
  }

  &__progressbar {
    width: 230px;
    height: 5px;
    border-radius: 100px;
    position: relative;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    background: #d9d9d9;
  }

  &__progress-scale {
    width: 0;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    background: #118689;
    transition: width 0.12s ease-in-out;
  }
}

// Impersonate switch =========================================================
.impersonate-switch {
  display: flex;
  align-items: center;
  color: #5b5b5b;
  font:
    14px/14px "Helvetica Neue",
    sans-serif;

  &__switch-itself {
  }

  &__label {
    padding-top: 0 !important;
    padding-bottom: 2px;
    display: flex !important;
    align-items: center;
    color: #5b5b5b !important;
    cursor: pointer !important;
    user-select: none;

    &::before,
    &::after {
      top: calc(50% - 0.75rem) !important;
    }
  }
  .impersonate-switch__switch-itself:focus:checked ~ .impersonate-switch__label::before,
  .impersonate-switch__switch-itself:checked ~ .impersonate-switch__label::before {
    background: #636363 !important;
  }
}

// Buttons & error ============================================================
.buttons-n-error {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left-side {
    display: flex;
    flex-direction: column;
  }

  &__error-text {
    margin-bottom: 8px;
    font:
      14px/14px "Helvetica Neue",
      sans-serif;
    color: #d65050;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__impersonate-switch {
  }

  &__buttons-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__attach-file-button {
    margin-right: 15px;
  }

  &__file-upload-snippet {
    margin-right: 15px;
  }

  &__send-button {
  }

  &__send-button-icon {
    &--spinning {
      &::before {
        @include gradientSpinWhite;
      }
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .buttons-n-error {
    flex-direction: column;

    &__left-side {
      width: 100%;
      margin-bottom: 8px;
    }

    &__buttons-list {
      width: 100%;
    }
  }
}

// New messages button section ================================================
.new-msg-btn-section {
  &__button {
  }

  &__button-counter {
    width: 22px;
    height: 22px;
    margin-right: 6px;
    border-radius: 100px;
    position: relative;
    top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5b5b5b;
    font:
      bold 14px/14px "Helvetica Neue",
      sans-serif;
    background: white;
  }
}

// Escalate to Shrpa link =====================================================
.escalate-to-shrpa-link {
  color: rgba(91, 91, 91, 1);
  font-family: sans-serif;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: rgba(0, 0, 0, 0.3);
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: none;
  }

  &__icon {
    margin: 0 2px 0 1px;
    position: relative;
    top: 3px;
    opacity: 0.65;
  }
}

// New message form ===========================================================
.new-message-form {
  box-sizing: border-box;

  &--inaccessible {
    pointer-events: none;
    opacity: 0.5;
  }

  &__new-msg-btn-section {
    margin-bottom: 14px;
  }

  &__textarea-wrap {
    height: 43px !important;
    min-height: 43px !important;
    max-height: 43px !important;
    margin-bottom: 8px;
    position: relative;
  }

  &__textarea-ghost {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    height: 0 !important;
    min-height: 0 !important;
    max-height: 0 !important;
    padding: 0 !important;
    position: absolute;
    inset: 0 auto auto 0;
    pointer-events: none;
    opacity: 0;
  }

  &__textarea {
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
    resize: none !important;
  }

  &__buttons-n-error {
    margin-bottom: 10px;
  }

  &__escalate-to-shrpa-section {
    padding-top: 5px;
    border-top: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
    justify-content: flex-end;
  }

  &__escalate-to-shrpa-link {
  }
}

// Collab messages thread =====================================================
.collab-messages-thread {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__creator-info-spoiler {
    width: 100%;
  }

  &__messages-list {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 22px;
  }

  &__new-message-form {
    width: calc(100% - 32px - 32px);
    margin-bottom: 20px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-messages-thread {
    &__new-message-form {
      width: calc(100% - 19px - 19px);
    }
  }
}
</style>
